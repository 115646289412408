import { render, staticRenderFns } from "./TheTermsOfUseOptInPure.vue?vue&type=template&id=100a0fef&scoped=true"
import script from "./TheTermsOfUseOptInPure.vue?vue&type=script&setup=true&lang=ts"
export * from "./TheTermsOfUseOptInPure.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TheTermsOfUseOptInPure.vue?vue&type=style&index=0&id=100a0fef&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "100a0fef",
  null
  
)

export default component.exports