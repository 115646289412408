import { apolloClient } from './apollo.service';

import { assertNever } from 'src/helpers/assertNever.helper';
import {
  suggestionToInstallationSearch,
  suggestionToPlayerSearch,
  suggestionToProductSearch,
  suggestionToVesselSearch,
  suggestionToZoneSearch,
} from 'src/helpers/search.helper';

import { searchCompletion } from 'src/main/graphql/search/Search.gql';
import { searchAreas } from 'src/main/graphql/search/SearchAreas.gql';
import {
  searchInstallations,
  searchInstallationsByCapacityHolder,
} from 'src/main/graphql/search/SearchInstallations.gql';
import { searchOrigins } from 'src/main/graphql/search/SearchOrigins.gql';
import { searchPlayers } from 'src/main/graphql/search/SearchPlayers.gql';
import { searchProducts } from 'src/main/graphql/search/SearchProducts.gql';
import {
  searchVessels,
  searchVesselsControlledByPlayer,
  searchVesselsOwnedByPlayer,
} from 'src/main/graphql/search/SearchVessels.gql';
import { searchZones } from 'src/main/graphql/search/SearchZones.gql';

import {
  InstallationSearchFragment,
  PlayerSearchFragment,
  ProductSearchFragment,
  SearchAreasQuery,
  SearchAreasQueryVariables,
  SearchCategory,
  SearchCompletionQuery,
  SearchCompletionQueryVariables,
  SearchInstallationsByCapacityHolderQuery,
  SearchInstallationsByCapacityHolderQueryVariables,
  SearchInstallationsQuery,
  SearchInstallationsQueryVariables,
  SearchOptionsInput,
  SearchOptionsInstallationInput,
  SearchOptionsPlayerInput,
  SearchOptionsProductInput,
  SearchOptionsVesselInput,
  SearchOptionsZoneInput,
  SearchOriginsQuery,
  SearchOriginsQueryVariables,
  SearchPlayersQuery,
  SearchPlayersQueryVariables,
  SearchProductsQuery,
  SearchProductsQueryVariables,
  SearchVesselsControlledByPlayerQuery,
  SearchVesselsControlledByPlayerQueryVariables,
  SearchVesselsOwnedByPlayerQuery,
  SearchVesselsOwnedByPlayerQueryVariables,
  SearchVesselsQuery,
  SearchVesselsQueryVariables,
  SearchZonesQuery,
  SearchZonesQueryVariables,
  VesselSearchFragment,
  ZoneSearchFragment,
} from 'types/graphql';
import { ResourceType } from 'types/legacy-globals';
import { PlayerControlledFleet, PlayerInstallations, PlayerOwnedFleet } from 'types/player';
import {
  InstallationSearchResult,
  PlayerSearchResult,
  ProductSearchResult,
  SearchOptions,
  SearchOptionsArea,
  SearchOptionsLocation,
  SearchOptionsZone,
  SearchResultType,
  VesselSearchResult,
  ZoneSearchResult,
} from 'types/search';

const search = async (
  query: string,
  category: SearchCategory[],
  activeProductIds: readonly string[],
  options?: SearchOptionsInput,
) => {
  if (query.length < 2) {
    return undefined;
  }

  return apolloClient.query<SearchCompletionQuery, SearchCompletionQueryVariables>({
    query: searchCompletion,
    variables: {
      category,
      text: query,
      options: {
        ...options,
        product: {
          ids: activeProductIds,
        },
      },
    },
  });
};

const productSearch = async (query: string, productOptions?: SearchOptionsProductInput) => {
  if (query.length < 2) {
    return undefined;
  }

  const results = await apolloClient.query<SearchProductsQuery, SearchProductsQueryVariables>({
    query: searchProducts,
    variables: {
      text: query,
      options: {
        product: productOptions,
      },
    },
  });
  return results.data.completionSearch as ProductSearchFragment[];
};

const vesselSearch = async (
  query: string,
  vesselOptions?: SearchOptionsVesselInput,
  size?: number,
) => {
  if (query.length < 2) {
    return undefined;
  }

  const results = await apolloClient.query<SearchVesselsQuery, SearchVesselsQueryVariables>({
    query: searchVessels,
    variables: {
      text: query,
      size,
      options: {
        vessel: vesselOptions || {},
      },
    },
  });
  return results.data.completionSearch as VesselSearchFragment[];
};

const zoneSearch = async (query: string, zoneOptions: SearchOptionsZoneInput) => {
  if (query.length < 2) {
    return undefined;
  }

  const results = await apolloClient.query<SearchZonesQuery, SearchZonesQueryVariables>({
    query: searchZones,
    variables: {
      text: query,
      options: {
        zone: zoneOptions,
      },
    },
  });
  return results.data.completionSearch as ZoneSearchFragment[];
};

const installationSearch = async (
  query: string,
  installationOptions?: SearchOptionsInstallationInput,
) => {
  if (query.length < 2) {
    return undefined;
  }

  const results = await apolloClient.query<
    SearchInstallationsQuery,
    SearchInstallationsQueryVariables
  >({
    query: searchInstallations,
    variables: {
      text: query,
      options: {
        installation: installationOptions,
      },
    },
  });
  return results.data.completionSearch as InstallationSearchFragment[];
};

const playerSearch = async (query: string, playerOptions?: SearchOptionsPlayerInput) => {
  if (query.length < 2) {
    return undefined;
  }

  const results = await apolloClient.query<SearchPlayersQuery, SearchPlayersQueryVariables>({
    query: searchPlayers,
    variables: {
      text: query,
      options: {
        player: playerOptions || {},
      },
    },
  });
  return results.data.completionSearch as PlayerSearchFragment[];
};

const areaSearch = async (
  query: string,
  installationOptions?: SearchOptionsInstallationInput,
  zoneOptions?: SearchOptionsZoneInput,
) => {
  if (query.length < 2) {
    return undefined;
  }

  const results = await apolloClient.query<SearchAreasQuery, SearchAreasQueryVariables>({
    query: searchAreas,
    variables: {
      text: query,
      options: {
        installation: installationOptions,
        zone: zoneOptions,
      },
    },
  });
  return results.data.completionSearch as Array<ZoneSearchFragment | InstallationSearchFragment>;
};

const originSearch = async (
  query: string,
  installationOptions?: SearchOptionsInstallationInput,
  zoneOptions?: SearchOptionsZoneInput,
) => {
  if (query.length < 2) {
    return undefined;
  }

  return apolloClient.query<SearchOriginsQuery, SearchOriginsQueryVariables>({
    query: searchOrigins,
    variables: {
      text: query,
      options: {
        installation: installationOptions,
        zone: zoneOptions,
      },
    },
  });
};

const getSuggestions = (
  category: SearchCategory[],
  activeProducts: readonly string[],
  options: SearchOptions = {},
) => {
  return async (query: string): Promise<SearchResultType[] | undefined> => {
    const res = await search(query, category, activeProducts, options);
    return res?.data.completionSearch.map(suggestion => {
      if (suggestion.__typename === 'InstallationSearch') {
        return suggestionToInstallationSearch(suggestion);
      }
      if (suggestion.__typename === 'ProductSearch') {
        return suggestionToProductSearch(suggestion);
      }
      if (suggestion.__typename === 'VesselSearch') {
        return suggestionToVesselSearch(suggestion);
      }
      if (suggestion.__typename === 'ZoneSearch') {
        return suggestionToZoneSearch(suggestion);
      }
      if (suggestion.__typename === 'PlayerSearch') {
        return suggestionToPlayerSearch(suggestion);
      }
      throw new Error('undefined');
    });
  };
};

const getSuggestionsProduct = (
  activeProductIds: readonly string[],
  options?: SearchOptionsProductInput,
) => {
  const defaultProductOptions = {
    ids: activeProductIds,
  };

  return async (query: string): Promise<ProductSearchResult[] | undefined> => {
    const products = await productSearch(query, options || defaultProductOptions);
    return products?.map(suggestion => suggestionToProductSearch(suggestion));
  };
};

const getSuggestionsZone = (options: SearchOptionsZone = {}) => {
  return async (query: string): Promise<ZoneSearchResult[] | undefined> => {
    const zones = await zoneSearch(query, options);
    return zones?.map(suggestion => suggestionToZoneSearch(suggestion));
  };
};

const getSuggestionsInstallation = (options?: SearchOptionsInstallationInput) => {
  return async (query: string): Promise<InstallationSearchResult[] | undefined> => {
    const installations = await installationSearch(query, options);
    return installations?.map(suggestion => suggestionToInstallationSearch(suggestion));
  };
};

const getSuggestionsPlayer = (options?: SearchOptionsPlayerInput) => {
  return async (query: string): Promise<PlayerSearchResult[] | undefined> => {
    const players = await playerSearch(query, options);
    return players?.map(suggestion => suggestionToPlayerSearch(suggestion));
  };
};

const getSuggestionsVessel = (options?: SearchOptionsVesselInput, size?: number) => {
  return async (query: string): Promise<VesselSearchResult[] | undefined> => {
    const vessels = await vesselSearch(query, options, size);
    return vessels?.map(suggestion => suggestionToVesselSearch(suggestion));
  };
};

type Location = InstallationSearchResult | ZoneSearchResult | VesselSearchResult;
const getSuggestionsLocation = (options?: SearchOptionsLocation) => {
  return async (query: string): Promise<Location[] | undefined> => {
    const { installation, zone } = options || {};
    const res = await originSearch(query, installation, zone);
    const inactiveVessels = (v: Location) =>
      !(v.resourceType === ResourceType.VESSEL && v.status === 'Inactive');
    const vesselWithoutLastPosition = (v: Location) =>
      !(v.resourceType === ResourceType.VESSEL && !v.hasLastPosition);

    return res?.data.completionSearch
      .map(suggestion => {
        if (suggestion.__typename === 'InstallationSearch') {
          return suggestionToInstallationSearch(suggestion);
        }
        if (suggestion.__typename === 'ZoneSearch') {
          return suggestionToZoneSearch(suggestion);
        }
        if (suggestion.__typename === 'VesselSearch') {
          return suggestionToVesselSearch(suggestion);
        }
        return assertNever(suggestion as never);
      })
      .filter(vessel => inactiveVessels(vessel) && vesselWithoutLastPosition(vessel));
  };
};

const getSuggestionsArea = (options?: SearchOptionsArea) => {
  const { installation, zone } = options || {};
  return async (
    query: string,
  ): Promise<Array<InstallationSearchResult | ZoneSearchResult> | undefined> => {
    const areas = await areaSearch(query, installation, zone);
    return areas?.map(suggestion => {
      if (suggestion.__typename === 'InstallationSearch') {
        return suggestionToInstallationSearch(suggestion);
      }
      if (suggestion.__typename === 'ZoneSearch') {
        return suggestionToZoneSearch(suggestion);
      }
      return assertNever(suggestion as never);
    });
  };
};

const getVesselsOwnedByPlayer = async (playerId: string): Promise<PlayerOwnedFleet> => {
  const { data } = await apolloClient.query<
    SearchVesselsOwnedByPlayerQuery,
    SearchVesselsOwnedByPlayerQueryVariables
  >({
    query: searchVesselsOwnedByPlayer,
    variables: {
      playerId,
    },
  });
  return data.vesselsOwnedByPlayer;
};

const getVesselsControlledByPlayer = async (playerId: string): Promise<PlayerControlledFleet> => {
  const { data } = await apolloClient.query<
    SearchVesselsControlledByPlayerQuery,
    SearchVesselsControlledByPlayerQueryVariables
  >({
    query: searchVesselsControlledByPlayer,
    variables: {
      playerId,
    },
  });
  return data.vesselsControlledByPlayer;
};

const getInstallationsByCapacityHolder = async (
  capacityHolderId: string,
): Promise<PlayerInstallations> => {
  const { data } = await apolloClient.query<
    SearchInstallationsByCapacityHolderQuery,
    SearchInstallationsByCapacityHolderQueryVariables
  >({
    query: searchInstallationsByCapacityHolder,
    variables: {
      capacityHolderId,
    },
  });
  return data.playerHoldingCapacities;
};

export default {
  zoneSearch,
  getSuggestions,
  getSuggestionsProduct,
  getSuggestionsZone,
  getSuggestionsInstallation,
  getSuggestionsPlayer,
  getSuggestionsVessel,
  getSuggestionsLocation,
  getSuggestionsArea,
  getVesselsOwnedByPlayer,
  getVesselsControlledByPlayer,
  getInstallationsByCapacityHolder,
};
