import CookiesService from 'src/services/cookies.service';

import { Platform } from 'types/legacy-globals';

function assertPlatform(platform: string): asserts platform is Platform {
  if (!Object.values(Platform).includes(platform as Platform)) {
    throw new Error(`Unknown platform ${platform}.`);
  }
}

export const getCurrentCommodityFromCookie = (): Platform => {
  const platform = CookiesService.getCookie('platform');
  if (!platform) {
    throw new Error("'platform' cookie is missing.");
  }
  assertPlatform(platform);
  return platform;
};

/**
 * @deprecated use `useInjectPlatformService().platform` instead.
 * Exceptions: store and router files
 */
export const platform = getCurrentCommodityFromCookie();
/**
 * @deprecated use `useInjectPlatformService().isLng` instead.
 * Exceptions: store and router files
 */
export const isLng = platform === Platform.LNG;
/**
 * @deprecated use `useInjectPlatformService().isLpg` instead.
 * Exceptions: store and router files
 */
export const isLpg = platform === Platform.LPG;
/**
 * @deprecated use `useInjectPlatformService().isCommodities` instead.
 * Exceptions: store and router files
 */
export const isCommodities = platform === Platform.COMMODITIES;
/**
 * @deprecated use `useInjectPlatformService().isDry` instead.
 * Exceptions: store and router files
 */
export const isDry = platform === Platform.DRY;
/**
 * @deprecated use `useInjectPlatformService().isMerge` instead.
 * Exceptions: store and router files
 */
export const isMerge = platform === Platform.MERGE;
