import { BaseCheckButtonGroupOption } from 'src/components/BaseCheckboxButtonGroup.types';
import { FilterCallback } from 'src/types';

import { isDefined } from 'src/helpers/isDefined.helper';
import { isMerge } from 'src/helpers/platform.helper';

import { BetaStatus } from 'types/graphql';
import { InstallationMapPayload } from 'types/installation';
import { VesselMapPayload } from 'types/vessel';

export const DEFAULT_VISIBILITY_IF_FILTERS_ARE_ALL_UNSET = !isMerge;

export const createFilterOnAttribute = <T extends Record<string, unknown>>(
  selectedAttributeValues: Set<string>,
  attributeAccessor: (object: T) => string | undefined,
  options: {
    defaultVisibility: boolean;
  },
): FilterCallback<T> => {
  return (object: T): boolean => {
    if (selectedAttributeValues.size === 0) {
      return options.defaultVisibility;
    }

    const attribute = attributeAccessor(object);
    if (!isDefined(attribute)) {
      return false;
    }

    return selectedAttributeValues.has(attribute);
  };
};

export const betaFilter =
  (beta: Set<BetaStatus>) =>
  (vessel: VesselMapPayload | InstallationMapPayload): boolean => {
    if (beta.size === 0) {
      return DEFAULT_VISIBILITY_IF_FILTERS_ARE_ALL_UNSET;
    }
    const isBeta = vessel.beta;
    return (beta.has(BetaStatus.Beta) && isBeta) || (beta.has(BetaStatus.Regular) && !isBeta);
  };

export const getAvailableOptionsFromItems = <T = VesselMapPayload | InstallationMapPayload>(
  items: T[],
  getOptionValue: (currentItem: T) => string,
): readonly BaseCheckButtonGroupOption[] => {
  const optionValues: string[] = [...new Set(items.map(getOptionValue))];
  const baseSelectOptions = optionValues.sort().map(optionValue => ({
    id: optionValue,
    name: optionValue,
    className: optionValue,
  }));

  return Object.freeze<BaseCheckButtonGroupOption[]>(baseSelectOptions);
};
