import { serializeDate, toMoment } from 'src/helpers/date.helper';

import { DateRange } from 'types/dates';
import { PricesFilterState } from 'types/prices';

export const CHART_LINK_COLUMN_INDEX = 1;

// @TODO get last trading day instead of today
export const DEFAULT_FORWARD_CURVE_DATE_RANGE: DateRange = {
  startDate: serializeDate(toMoment()),
  endDate: serializeDate(toMoment()),
};

export const DEFAULT_PREVIOUS_STATE: {
  filters: PricesFilterState | undefined;
  firstSeriesDate: string | undefined;
} = {
  filters: undefined,
  firstSeriesDate: undefined,
};
