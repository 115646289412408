import { AnalyticsView, DateRangePreset } from 'types/analytics';
import { DateRange, SerializedDateRange } from 'types/dates';
import { ProductFragment } from 'types/graphql';
import { InstallationWithResourceType } from 'types/installation';
import {
  Granularity,
  DeadWeightObject,
  ResourceType,
  OperationalFilter,
  Location,
  CapacityObject,
  VesselTypeClassification,
  ObjectBase,
} from 'types/legacy-globals';
import { PricesBaseFiltersMapping, PricesSidebarFilterState } from 'types/prices';
import { RangeNumber } from 'types/range';
import { UnitName, TemporalUnitName } from 'types/unit';
import { ZoneWithResourceType } from 'types/zone';

export enum FlowsDirection {
  EXPORT = 'export',
  IMPORT = 'import',
  NET_EXPORT = 'netExport',
}

export enum FlowsProjection {
  ACTUAL = 'actual',
  PREDICTIVE = 'predictive',
}

// Used to format frontend urls
export enum FlowsSplit {
  TOTAL = 'total',
  COMMODITY = 'commodity',
  GRADE = 'grade',
  CRUDE_QUALITY = 'crudequality',
  CARGO_TYPE = 'cargotype',
  DESTINATION_TRADING_REGION = 'destination--tradingregion',
  DESTINATION_CONTINENT = 'destination--continent',
  DESTINATION_SUBCONTINENT = 'destination--subcontinent',
  DESTINATION_COUNTRY = 'destination--country',
  DESTINATION_INSTALLATION = 'destination--installation',
  DESTINATION_PORT = 'destination--port',
  DESTINATION_PADD = 'destination--padd',
  ORIGIN_TRADING_REGION = 'origin--tradingregion',
  ORIGIN_CONTINENT = 'origin--continent',
  ORIGIN_SUBCONTINENT = 'origin--subcontinent',
  ORIGIN_COUNTRY = 'origin--country',
  ORIGIN_INSTALLATION = 'origin--installation',
  ORIGIN_PORT = 'origin--port',
  ORIGIN_PADD = 'origin--padd',
  VESSEL_TYPE = 'vesseltype',
  LONG_HAUL_VESSEL_TYPE = 'longhaulvesseltype',
  TRADE_STATUS = 'tradestatus',
  SOURCE_ETA = 'sourceeta',
  BUYERS = 'buyer',
  SELLERS = 'seller',
  CHARTERER = 'charterer',
  ROUTE = 'route',
}

// Used to call webserver API
export enum FlowsParamsSplit {
  COMMODITY = 'Products',
  GRADE = 'Grades',
  CRUDE_QUALITY = 'Crude Quality',
  CARGO_TYPE = 'Cargo Type',
  DESTINATION_TRADING_REGION = 'Destination Trading Regions',
  DESTINATION_CONTINENT = 'Destination Continents',
  DESTINATION_SUBCONTINENT = 'Destination Subcontinents',
  DESTINATION_COUNTRY = 'Destination Countries',
  DESTINATION_INSTALLATION = 'Destination Installations',
  DESTINATION_PORT = 'Destination Ports',
  DESTINATION_PADD = 'Destination Padds',
  ORIGIN_TRADING_REGION = 'Origin Trading Regions',
  ORIGIN_CONTINENT = 'Origin Continents',
  ORIGIN_SUBCONTINENT = 'Origin Subcontinents',
  ORIGIN_COUNTRY = 'Origin Countries',
  ORIGIN_INSTALLATION = 'Origin Installations',
  ORIGIN_PORT = 'Origin Ports',
  ORIGIN_PADD = 'Origin Padds',
  VESSEL_TYPE = 'Vessel Type',
  LONG_HAUL_VESSEL_TYPE = 'Long Haul Vessel Type',
  LONG_HAUL_VESSEL_TYPE_OIL = 'Long Haul Vessel Type Oil',
  LONG_HAUL_VESSEL_TYPE_CPP = 'Long Haul Vessel Type Cpp',
  VESSEL_STATE = 'Vessel State',
  VESSEL_TYPE_OIL = 'Vessel Type Oil',
  VESSEL_TYPE_CPP = 'Vessel Type Cpp',
  TRADE_STATUS = 'Trade Status',
  SOURCE_ETA = 'Sources',
  BUYERS = 'Buyer',
  SELLERS = 'Seller',
  CHARTERER = 'Charterers',
  ROUTE = 'Routes',
  ENGINE_TYPE = 'Engine Type',
}

export type FlowsStateHydrated = {
  mainAreas: ReadonlyArray<InstallationWithResourceType | ZoneWithResourceType>;
  secondaryAreas: ReadonlyArray<InstallationWithResourceType | ZoneWithResourceType>;
  products: readonly ProductFragment[];
  flowDirection: FlowsDirection;
  includeIntra: boolean;
  includeIntraRegion: boolean;
  includeBetaVessels: boolean;
  split: FlowsSplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  withProductEstimation: boolean;
  forecast: boolean;
  vesselClassifications: readonly string[];
  vessels: ReadonlyArray<ObjectBase<number>>;
  pricesFilters: PricesSidebarFilterState;
  dateRange: DateRangePreset | DateRange;
  granularity: Granularity;
  projection: FlowsProjection;
  seasonal: boolean;
  view: AnalyticsView;
  unit: UnitName | TemporalUnitName;
  movingAverage: boolean;
  truncateY: boolean;
};

export type FlowsState = {
  mZones: readonly number[];
  mInstallations: readonly number[];
  sZones: readonly number[];
  sInstallations: readonly number[];
  products: readonly string[];
  dir: FlowsDirection;
  intra: boolean;
  intraRegion: boolean;
  beta: boolean;
  split: FlowsSplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  productEstimation: boolean;
  forecast: boolean;
  vesselClassifications: readonly string[];
  vessels: readonly number[] | null;
  dates: DateRangePreset | DateRange;
  granularity: Granularity;
  projection: FlowsProjection;
  seasonal: boolean;
  view: AnalyticsView;
  unit: UnitName | TemporalUnitName;
  movingAverage: boolean;
  truncateY: boolean;
  pricesFilters: PricesBaseFiltersMapping;
};

export type FlowsTooltipProps = {
  unitName: UnitName | TemporalUnitName;
  granularity: Granularity;
};

export type FlowsParams = SerializedDateRange & {
  cumulative: false;
  capacity?: CapacityObject;
  deadWeight?: DeadWeightObject;
  vesselClassifications?: readonly string[];
  filters: {
    product?: readonly number[];
  };
  flowDirection: FlowsDirection;
  fromLocations: ReadonlyArray<{
    id: number;
    resourceType: ResourceType.ZONE | ResourceType.INSTALLATION;
  }>;
  granularity: Granularity;
  interIntra: 'inter' | 'interintra';
  numberOfSplits?: number;
  onlyRealized: boolean;
  splitOn?: FlowsParamsSplit;
  toLocations: ReadonlyArray<{
    id: number;
    resourceType: ResourceType.ZONE | ResourceType.INSTALLATION;
  }>;
  withBetaVessels: boolean;
  withForecasted: boolean;
  withGrades: boolean;
  withIncompleteTrades: boolean;
  withIntraCountry: boolean;
  withFreightView: boolean;
  withProductEstimation: boolean;
  vesselTypeClassification?: VesselTypeClassification;
  vessels?: readonly number[] | null;
};

export type FlowsTradesParams = {
  capacity?: CapacityObject;
  deadWeight?: DeadWeightObject;
  vesselClassifications?: readonly string[];
  exchangeType: FlowsDirection.IMPORT | FlowsDirection.EXPORT;
  filters: { product?: readonly string[] };
  flowDirection: FlowsDirection;
  freightMetrics?: boolean;
  fromLocations: readonly Location[];
  granularity: Granularity;
  interIntra: 'inter' | 'interintra';
  locations?: readonly Location[];
  operationalFilter?: OperationalFilter;
  onlyRealized: boolean;
  period: string;
  players?: readonly number[];
  splitOn?: FlowsParamsSplit;
  splitValues: readonly string[];
  splitValuesToExclude: readonly string[];
  toLocations: readonly Location[];
  vessels?: readonly number[] | null;
  withBetaVessels: boolean;
  withForecasted: boolean;
  withIncompleteTrades: boolean;
  withIntraCountry: boolean;
  withFreightView: boolean;
  withProductEstimation: boolean;
  vesselTypeClassification?: VesselTypeClassification;
};
