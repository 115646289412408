import { FlowsProjection } from 'src/types/flows';

import { AnalyticsView, DateRangePreset, PastFutureDateRangePreset } from 'types/analytics';
import { DateRange } from 'types/dates';
import { OfflineEventsQuery, UnitCapacitiesQuery } from 'types/graphql';
import { InstallationWithResourceType } from 'types/installation';
import { ArrayElement, ResourceType, Granularity } from 'types/legacy-globals';
import { TemporalUnitName } from 'types/unit';
import { ZoneWithResourceType } from 'types/zone';

export type RefineriesEventsDataFilterState = {
  areas: Array<InstallationWithResourceType | ZoneWithResourceType>;
  eventsDateRange: PastFutureDateRangePreset | DateRange;
  eventTypes: readonly string[];
  eventCauses: readonly string[];
  eventStatuses: readonly string[];
  unitTypeSubFamilies: readonly string[];
};

export type RefineriesEventsDataFiltersMapping = {
  installationIds: readonly number[];
  zoneIds: readonly number[];
  eventsDateRange: PastFutureDateRangePreset | DateRange;
  eventTypes: readonly string[];
  eventCauses: readonly string[];
  eventStatuses: readonly string[];
  unitTypeSubFamilies: readonly string[];
};

export type OfflineEvent = ArrayElement<OfflineEventsQuery['offlineEvents']['items']>;
export type UnitCapacity = ArrayElement<UnitCapacitiesQuery['unitCapacities']>;

export type OfflineEventColumn = keyof Omit<
  OfflineEventsQuery['offlineEvents']['items'][number],
  '__typename'
>;

export type UnitCapacityColumn = keyof Omit<
  UnitCapacitiesQuery['unitCapacities'][number],
  '__typename'
>;

export enum OfflineEventTypes {
  PLANNED = 'Planned',
  UNPLANNED = 'Unplanned',
  UNKNOWN = 'Unknown',
}

export enum OfflineEventCauses {
  MECHANICAL = 'Mechanical',
  WEATHER = 'Weather',
  ECONOMIC = 'Economic',
  FINANCIAL = 'Financial',
  ELECTRICAL = 'Electrical',
  UNKNOWN = 'Unknown',
}

export enum InstallationOfflineEventType {
  NO_EVENTS = 'no-events',
  HAS_PLANNED = 'has-planned',
  HAS_UNPLANNED = 'has-unplanned',
}

export enum OfflineEventStatus {
  PAST = 'Past',
  ONGOING = 'Ongoing',
  FUTURE = 'Future',
  CANCELLED = 'Cancelled',
  UNKNOWN = 'Unknown',
}

export enum RefineryCapacitiesMetric {
  EVENTS = 'events',
  CAPACITY = 'capacity',
}

export type ResourceTypeWithOfflineEvents = ResourceType.INSTALLATION | ResourceType.ZONE;
export enum RefineryCapacitiesSplit {
  TOTAL = 'total',
  E_STATUS = 'eStatus',
  E_CAUSE = 'eCause',
  EVENT_TYPE = 'eventType',
}

export type RefineryCapacitiesDisplayOptions = {
  view: AnalyticsView;
  unit: TemporalUnitName;
  movingAverage: boolean;
  truncateY: boolean;
};

export type RefineryCapacitiesSidebarFilterState = {
  areas: ReadonlyArray<InstallationWithResourceType | ZoneWithResourceType>;
  unitTypeSubFamilies: readonly string[];
  splitBy: RefineryCapacitiesSplit;
  metric: RefineryCapacitiesMetric;
};

export type RefineryCapacitiesTopFilterState = {
  dateRange: DateRangePreset | DateRange;
  granularity: Granularity;
  projection: FlowsProjection;
  groupByFamily: boolean;
  includeNewBuilds: boolean;
};

export type RefineryCapacitiesFilterState = RefineryCapacitiesSidebarFilterState &
  RefineryCapacitiesTopFilterState;

type RefineryCapacitiesSidebarFiltersMapping = {
  installationIds: readonly number[];
  zoneIds: readonly number[];
  unitTypeSubFamilies: readonly string[];
  splitBy: RefineryCapacitiesSplit;
  metric: RefineryCapacitiesMetric;
};

export type RefineryCapacitiesTopFiltersMapping = {
  dateRange: DateRangePreset | DateRange;
  granularity: Granularity;
  projection: FlowsProjection;
  groupByFamily: boolean;
  includeNewBuilds: boolean;
};

export type RefineryCapacitiesFiltersMapping = RefineryCapacitiesSidebarFiltersMapping &
  RefineryCapacitiesTopFiltersMapping;

export type RefineryCapacitiesMapping = RefineryCapacitiesFiltersMapping &
  RefineryCapacitiesDisplayOptions;

export type RefineriesCapacitiesTooltipProps = {
  unitName: TemporalUnitName;
  granularity: Granularity;
  metric: RefineryCapacitiesMetric;
};
