export enum UserManagementPermissions {
  ACCESS = 'license:access',
  ACCESS_ADVANCE = 'license:advance',
  ENTITLEMENT_MGMT = 'license:entitlement_mgmt',
  COMMERCIAL_PACKAGE_EDIT = 'entitlement-mgmt:commercial-package:edit',
  DEVELOPMENT_PACKAGE_EDIT = 'entitlement-mgmt:development-package:edit',
  ENTITLEMENT_PACKAGE_EDIT = 'entitlement-mgmt:entitlement-package:edit',
  PERMISSION_DELETE = 'permission:delete',
  PACKAGE_DELETE = 'package:delete',
  FEATURE_DELETE = 'feature:delete',
  USER_PACKAGES_DELETE = 'user_packages:delete',
  USER_DELETE = 'user:delete',
  USER_SYNC = 'user:sync',
}

export enum UserManagementRoutesTab {
  USERS = 'user-management-users',
  USER_DETAILS = 'user-management-users-details',
  PACKAGES = 'user-management-packages',
  PACKAGE_DETAILS = 'user-management-packages-details',
  FEATURES = 'user-management-feature',
  FEATURE_DETAILS = 'user-management-feature-details',
  PERMISSIONS = 'user-management-permissions',
  PERMISSION_DETAILS = 'user-management-permissions-details',
}

export const DEFAULT_PAGE_SIZE = 25;
export const PAGE_SEARCH_SIZE = 25;

export const ORG_IDS = 'orgIds';
export const QUERY_USER_SEARCH = 'qU';
export const PACKAGE_IDS = 'packageIds';
export const LICENSE_IDS = 'licenseIds';

export const enum USER_STATUS {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CONTACT = 'contact',
}

export const enum PACKAGE_GROUP_ID {
  PHYSICAL = 1,
  FINANCIAL = 2,
  ADD_ON = 3,
  LICENSES = 4,
  DEVELOPMENT = 5,
  BETA = 6,
  INSIGHT = 7,
  POWER = 8,
  STRAT = 9,
}

export const COMMODITY_PACKAGES = [PACKAGE_GROUP_ID.PHYSICAL, PACKAGE_GROUP_ID.FINANCIAL];

export const COMMERCIAL_PACKAGE_IDS = [
  PACKAGE_GROUP_ID.PHYSICAL,
  PACKAGE_GROUP_ID.FINANCIAL,
  PACKAGE_GROUP_ID.ADD_ON,
  PACKAGE_GROUP_ID.INSIGHT,
  PACKAGE_GROUP_ID.POWER,
];

export const DEVELOPMENT_PACKAGE_IDS = [
  PACKAGE_GROUP_ID.BETA,
  PACKAGE_GROUP_ID.STRAT,
  PACKAGE_GROUP_ID.DEVELOPMENT,
];

export const BOOLEAN_OPTIONS = [
  {
    id: 'true',
    name: 'Yes',
  },
  {
    id: 'false',
    name: 'No',
  },
];

export const enum PACKAGE_LEVEL_ID {
  ENTRY = 1,
  STANDARD = 2,
  PREMIUM = 3,
}

export const PACKAGE_LEVEL = [
  {
    id: PACKAGE_LEVEL_ID.ENTRY,
    name: 'entry',
  },
  {
    id: PACKAGE_LEVEL_ID.STANDARD,
    name: 'standard',
  },
  {
    id: PACKAGE_LEVEL_ID.PREMIUM,
    name: 'premium',
  },
];

export const USER_ACTIONS = {
  ADD_TO_SEGMENT: 'addUsersToSegment',
  RESET_PASSWORD: 'resetPassword',
  COPY_ACCESS_FROM: 'copyAccessFrom',
  USER_PACKAGE_EDIT: 'userPackageEdit',
  UPDATE_EXPIRES_AT: 'updateExpiresAt',
};

export const enum APP_COMMO {
  LIQUIDS = 8,
  LPG = 2,
  LNG = 1,
  DRY = 7,
}

export enum SEARCH_TERM_QUERY {
  IS = 'is',
  IS_NOT = 'isnot',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notcontains',
  STARTS_WITH = 'startswith',
  NOT_STARTS_WITH = 'notstartswith',
  ENDS_WITH = 'endswith',
  NOT_ENDS_WITH = 'notendswith',
}

export const SEARCH_TERM_QUERY_OPTIONS = [
  {
    id: SEARCH_TERM_QUERY.CONTAINS,
    name: 'Contains',
  },
  {
    id: SEARCH_TERM_QUERY.IS,
    name: 'Is',
  },
  {
    id: SEARCH_TERM_QUERY.STARTS_WITH,
    name: 'Starts with',
  },
  {
    id: SEARCH_TERM_QUERY.ENDS_WITH,
    name: 'Ends with',
  },
];

export const USER_SEARCH_TERM_QUERY_OPTIONS = [
  ...SEARCH_TERM_QUERY_OPTIONS,
  {
    id: SEARCH_TERM_QUERY.NOT_CONTAINS,
    name: "Doesn't contain",
  },
];

export const SEARCH_TERM_QUERY_CUSTOM_OPTIONS = SEARCH_TERM_QUERY_OPTIONS.map(queryOption => {
  let name = queryOption.name;
  if (queryOption.id === SEARCH_TERM_QUERY.IS) {
    name = 'Exact';
  } else if (queryOption.id === SEARCH_TERM_QUERY.IS_NOT) {
    name = 'Not exact';
  }
  return {
    id: queryOption.id,
    name,
  };
});

export enum RESOURCE_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
  SUSPENDED = 'suspended',
  NOT_ASSIGNED = 'not assigned',
  DELETE = 'delete',
}

export const GROUP_ACCOUNT_IDENTIFIER = '(P)';
