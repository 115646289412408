import { FetchResult, Observable, Operation, ServerError } from '@apollo/client';
import { GraphQLErrors, NetworkError } from '@apollo/client/errors';

import { getAuthHeaders } from './apiAuthHeaders.service';

import { AuthorizationError } from 'src/helpers/error.helper';
import { version } from 'src/helpers/version.helper';

export const forwardError = (
  operation: Operation,
  forward: (operation: Operation) => Observable<FetchResult>,
): Observable<FetchResult> => {
  return new Observable(observer => {
    getAuthHeaders()
      .then(authHeaders => {
        operation.setContext(({ headers = {} }) => {
          return {
            headers: {
              ...headers,
              ...authHeaders,
              'x-web-application-version': version,
            },
          };
        });
      })
      .then(() => {
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      });
  });
};

export const handleError = (
  logout: () => void,
  errors: { graphQLErrors?: GraphQLErrors; networkError?: NetworkError },
) => {
  const { graphQLErrors } = errors;
  if (
    (errors.networkError as ServerError)?.statusCode === 401 ||
    graphQLErrors?.some(e => e.extensions?.code === 'UNAUTHENTICATED')
  ) {
    logout();
    throw new AuthorizationError('Unauthorized.');
  }
};
