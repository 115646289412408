import { Market } from '@kpler/web-ui';

import { InstallationFragment, WidgetInstallationFragment } from 'types/graphql';
import { ResourceType, LatitudeLongitudeMaybe, ObjectBase } from 'types/legacy-globals';
import { Player } from 'types/player';
import { QuantityObject } from 'types/quantity';
import { Zone, ZoneWithCountry } from 'types/zone';

export enum InstallationTypeCategory {
  EXPORT = 'export',
  IMPORT = 'import',
  STORAGE = 'storage',
  SHIPYARD = 'shipyard',
}

export enum InstallationActivityType {
  STORAGE_ONLY = 'storage_only',
  IMPORT_CARGO_ONLY = 'import_cargo_only',
  EXPORT_CARGO_ONLY = 'export_cargo_only',
  IMPORT_STORAGE_CARGO = 'import_storage_cargo',
  EXPORT_STORAGE_CARGO = 'export_storage_cargo',
  SHIPYARD = 'shipyard',
}

export type CapacityHolder = {
  capacity: number | null;
  player: Player;
  holdingCapacity?: QuantityObject | null;
};
export type InstallationBase = ObjectBase<number> & {
  type?: string;
  resourceType: ResourceType.INSTALLATION;
};

export type Installation = {
  id: number;
  isStorageSelected: boolean;
  name: string;
  port: {
    id: number;
    name: string;
  };
  resourceType: ResourceType.INSTALLATION;
  status: string;
  type: string; // Use itemIcon.helper.ts to convert to InstallationTypeCategory
  nOpenTender: number;
};

export type InstallationWithResourceType = InstallationFragment & {
  resourceType: ResourceType.INSTALLATION;
};

export type InstallationMapPayload = Installation & {
  status: string;
  beta: boolean;
  position: LatitudeLongitudeMaybe;
  fullname?: string;
  nominalAnnualCapacity?: QuantityObject;
  players: {
    operators?: Player[];
  };
  zone: ZoneWithCountry;
  startupDate?: string;
  shutdownDate?: string;
  hasBerths: boolean;
  isRefinery?: boolean;
  commodityTypes: Market[];
  productInfos: InstallationProduct[];
};

export type InstallationProduct = {
  product: {
    id: number;
    name: string;
    commodityType: Market;
  };
  direction: Direction;
};

export type Direction = 'import' | 'export' | 'import/export';

export type InstallationNestedInsideZone = Installation & {
  country: Zone;
};

// from /installations/:id/details
export type InstallationDetailsPayload = Installation & {
  authorisedShipsUrl?: string;
  exportThisYear: {
    flows: QuantityObject[];
  };
  importThisYear: {
    flows: QuantityObject[];
  };
  isInUnitedStates: boolean;
  lngStorageQuantity?: QuantityObject;
  nominalAnnualCapacity?: QuantityObject;
  players: {
    // Very different types, but this is what the API send
    buyers: Player[] | null;
    operators: Player[] | null;
    owners?: Player[];
    suppliers: Player[] | null;
  } | null;
  position: {
    address?: string;
    continent?: string;
    country?: string;
    geoZone?: string;
    latitude?: number;
    longitude?: number;
    subContinent?: string;
  };
  startupDate?: string;
  shutdownDate?: string;
  zone: ZoneWithCountry & { continent: { id: number; name: string } };
  hasBerths: boolean;
  cargoDensity: number;
  technicalDetails: {
    numberJetties: number | null;
    numberTanks: number | null;
    numberTrains: number | null;
    maxShipClassSizeReceivable?: number;
  };
  process?: unknown;
  cost?: unknown;
  capacityHolders: CapacityHolder[];
  capacityHoldersTotalCapacity: number;
  /**
   * This is always defined for Merge
   * @TODO remove optional when obsolete platforms are removed
   */
  commodityTypes?: Market[];
};

export type InstallationTypesPayload = {
  lng: string[];
  lpg: string[];
  commodities: string[];
  coal: string[];
};

export type InstallationPayload = WidgetInstallationFragment | InstallationMapPayload;
