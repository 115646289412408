import moment, { Moment } from 'moment';

import { axiosApi } from 'src/services/axios.service';

import { fixLoadedStatusHelper } from 'src/common/map/fixLoadedStatus.helper';
import { ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE } from 'src/helpers/date.helper';

import { Position } from 'types/map';

const limit = 5000;

const getPositions = (id: number, after: Moment, before: Moment): Promise<Position[]> => {
  const url = `vessels/${id}/positions`;
  const params = {
    after: after.toISOString(),
    before: before.toISOString(),
    limit,
  };

  // Make use of the new format, available if the X-Version header is >= 1.0
  // Remove once the deprecated version has been removed from webserver
  const headers = { 'X-Version': 1.0 };

  return axiosApi.get<Position[]>(url, { params, headers }).then(positions => {
    return positions.map((position, index, array) => {
      return {
        ...position,
        currentCargo: {
          ...position.currentCargo,
          loaded: fixLoadedStatusHelper(array, index),
        },
      };
    });
  });
};

const getVesselPositions = async (
  id: number,
  after: Moment,
  before: Moment,
): Promise<Position[]> => {
  let positions = await getPositions(id, after, before);
  let allPositions = positions;

  // Overcome the positions limit by making as many queries as needed to retrieve all of them:
  while (positions.length === limit) {
    const latestDate = moment.utc(positions[positions.length - 1].receivedTime);
    // eslint-disable-next-line no-await-in-loop
    positions = await getPositions(id, after, latestDate);
    allPositions = allPositions.concat(positions);
  }
  return allPositions;
};

// Probably should be in positions.helper but camping here for the moment
const getLatency = (currentPosition: Position, nextPosition: Position) => {
  const formatter = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE;
  const currentTime = moment.utc(currentPosition.receivedTime, formatter);
  const nextTime = moment.utc(nextPosition.receivedTime, formatter);
  return currentTime.diff(nextTime);
};

export default {
  getVesselPositions,
  getLatency,
};
