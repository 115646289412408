import { RouteRecordRaw } from '@kpler/web-ui';

const TheUserContainer = () =>
  import(/* webpackChunkName: "settings" */ 'src/main/settings/TheUserContainer.vue');

const TheUserProfile = () =>
  import(/* webpackChunkName: "settings" */ 'src/main/settings/TheUserProfile.vue');

const TheUserDisclaimer = () =>
  import(/* webpackChunkName: "settings" */ 'src/main/settings/legal-pages/TheUserDisclaimer.vue');

const TheTermsOfUse = () =>
  import(/* webpackChunkName: "settings" */ 'src/main/settings/legal-pages/TheTermsOfUse.vue');

const TheComplianceTermsOfUse = () =>
  import(
    /* webpackChunkName: "settings" */ 'src/main/settings/legal-pages/TheComplianceTermsOfUse.vue'
  );

const TheUserPrivacy = () =>
  import(/* webpackChunkName: "settings" */ 'src/main/settings/legal-pages/TheUserPrivacy.vue');

const TheUserNotifications = () =>
  import(
    /* webpackChunkName: "settings" */ 'src/main/settings/TheUserNotifications/TheUserNotifications.vue'
  );

const TheSettingsContainer = () =>
  import(/* webpackChunkName: "settings" */ 'src/main/settings/TheSettingsContainer.vue');

const userRoute: RouteRecordRaw = {
  name: 'user',
  path: 'user',
  component: TheUserContainer,
  redirect: 'user/profile',
  children: [
    {
      name: 'user-profile',
      path: 'profile',
      component: TheUserProfile,
      meta: { title: 'Your profile' },
    },
    {
      name: 'user-disclaimer',
      path: 'disclaimer',
      component: TheUserDisclaimer,
      meta: { title: 'Disclaimer' },
    },
    {
      name: 'terms-of-use',
      path: 'terms',
      component: TheTermsOfUse,
      meta: { title: "Kpler's Terms of Use" },
    },
    {
      name: 'compliance-terms-of-use',
      path: 'compliance-terms',
      component: TheComplianceTermsOfUse,
      meta: { title: "Kpler's Terms of Use" },
    },
    {
      name: 'user-privacy',
      path: 'privacy',
      component: TheUserPrivacy,
      meta: { title: 'Privacy policy' },
    },
    {
      name: 'user-notifications',
      path: 'notifications',
      component: TheUserNotifications,
      meta: { title: 'Notification settings' },
    },
    {
      name: 'user-settings',
      path: 'settings',
      component: TheSettingsContainer,
      meta: { title: 'General settings' },
    },
  ],
};

export default userRoute;
