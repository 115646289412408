import IntercomSDKInterface from 'src/services/IntercomService/IntercomSDKInterface';

import { isDefined } from 'src/helpers/isDefined.helper';

import { User } from 'types/user';

const INTERCOM_APP_ID = process.env.NX_PUBLIC_INTERCOM_APP_ID;

export class IntercomService {
  static readonly INJECT_KEY = 'intercomService';
  static #singleton: IntercomService;

  #intercom: IntercomSDKInterface;

  static create(intercom: IntercomSDKInterface) {
    if (!isDefined(IntercomService.#singleton)) {
      IntercomService.#singleton = new IntercomService(intercom);
    }

    return IntercomService.#singleton;
  }

  private constructor(intercom: IntercomSDKInterface) {
    this.#intercom = intercom;
  }

  get injectKey() {
    return IntercomService.INJECT_KEY;
  }

  public initialize(user: User): void {
    if (!INTERCOM_APP_ID) {
      throw new Error(
        'Failed to initialize Intercom. Missing environment variable NX_PUBLIC_INTERCOM_APP_ID',
      );
    }

    this.#intercom.Intercom({
      app_id: INTERCOM_APP_ID,
      custom_launcher_selector: '#intercom-trigger',
      hide_default_launcher: true,
      alignment: 'left',
      horizontal_padding: 64,
      vertical_padding: 24,
      user_id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });

    this.#intercom.onUnreadCountChange((count: number) => {
      const container = document.querySelector('.intercom-trigger-container');
      const notificationElement = container?.querySelector('#intercom-notifications');
      if (count > 0) {
        container?.classList.add('has-messages');
        if (notificationElement) {
          notificationElement.innerHTML = count.toString();
        }
      } else {
        container?.classList.remove('has-messages');
        if (notificationElement) {
          notificationElement.innerHTML = '';
        }
      }
    });
  }

  public startTour(tourId: string): void {
    this.#intercom.startTour(tourId);
  }
}
