import { notificationsAxiosClient } from './axios.service';

export interface WebNotification {
  title: string;
  message: string;
}

export const fetchNotifications = async (userId: string): Promise<WebNotification[]> => {
  return notificationsAxiosClient.get<WebNotification[]>(`/api/notifications/${userId}`);
};
