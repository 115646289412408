import { ProductFragment } from './graphql';

import { AnalyticsView, DateRangePreset } from 'types/analytics';
import { DateRange, SerializedDateRange } from 'types/dates';
import {
  Granularity,
  ObjectBase,
  PaginationParams,
  DeadWeightObject,
  CapacityObject,
  VesselTypeClassificationSplit,
  VesselTypeClassification,
} from 'types/legacy-globals';
import { PricesBaseFiltersMapping, PricesSidebarFilterState } from 'types/prices';
import { RangeNumber } from 'types/range';
import { UnitName, UnmanagedUnitName } from 'types/unit';
import { Vessel } from 'types/vessel';
import { Zone } from 'types/zone';

export enum BallastCapacityAggMetric {
  COUNT = 'count',
  DEADWEIGHT = 'deadWeight',
  CAPACITY = 'capacity',
}

export enum BallastCapacityEtaType {
  HISTORICAL = 'historical',
  FORWARD = 'forward',
}

export enum BallastCapacitySplit {
  TOTAL = 'total',
  VESSEL_TYPE = 'vesselType',
  COUNTRY = 'country',
  SOURCE = 'source',
  ETA_WINDOW = 'etaWindow',
  VESSEL_AVAILABILITY = 'vesselAvailability',
  ENGINE_TYPE = 'engineType',
}

export enum BallastCapacityEtaWindow {
  NEXT_ONE_WEEK = 'next_one_week',
  NEXT_TWO_WEEKS = 'next_two_weeks',
  NEXT_FOUR_WEEKS = 'next_four_weeks',
  NEXT_THREE_MONTHS = 'next_three_months',
  ALL = 'all',
  CUSTOM = 'custom',
}

export enum BallastCapacityVesselAvailability {
  FIXED = 'Fixed',
  POTENTIALLY_FIXED = 'Potentially Fixed',
  POTENTIALLY_OPEN = 'Potentially Open',
  OPEN = 'Open',
  UNKNOWN = 'Unknown',
}

export type BallastCapacityParamsSplit =
  | Exclude<BallastCapacitySplit, BallastCapacitySplit.TOTAL>
  | VesselTypeClassificationSplit;

export type BallastCapacityParams = SerializedDateRange & {
  locations: ReadonlyArray<{ resourceType: string; id: number }>;
  deadWeight?: DeadWeightObject;
  capacity?: CapacityObject;
  metric: BallastCapacityAggMetric;
  granularity: Granularity;
  splitOn?: BallastCapacityParamsSplit;
  vesselClassifications?: readonly string[];
  engineTypes: readonly string[];
  numberOfSplits?: number;
  products?: readonly number[];
  withFreightView: boolean;
  isHistorical?: boolean;
  nDaysAhead?: number;
  vesselIds?: readonly number[];
  vesselTypeClassification?: VesselTypeClassification;
  vesselAvailability?: readonly BallastCapacityVesselAvailability[];
};

export type BallastCapacityStateHydrated = {
  zones: readonly Zone[];
  aggMetric: BallastCapacityAggMetric;
  etaType: BallastCapacityEtaType;
  etaWindow: BallastCapacityEtaWindow | null;
  nDaysAhead: number | null;
  split: BallastCapacitySplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  vesselClassifications: readonly string[];
  engineTypes: readonly string[];
  products: readonly ProductFragment[];
  vessels: ReadonlyArray<ObjectBase<number>>;
  pricesFilters: PricesSidebarFilterState;
  vesselAvailability: readonly BallastCapacityVesselAvailability[] | null;
  dateRange: DateRangePreset | DateRange;
  granularity: Granularity;
  view: AnalyticsView;
  unit: UnitName;
  truncateY: boolean;
};

export type BallastCapacityState = {
  zones: readonly number[];
  aggMetric: BallastCapacityAggMetric;
  etaType: BallastCapacityEtaType;
  etaWindow: BallastCapacityEtaWindow | null;
  nDaysAhead: number | null;
  granularity: Granularity;
  split: BallastCapacitySplit;
  deadweight: RangeNumber | null;
  capacity: RangeNumber | null;
  vesselClassifications: readonly string[];
  engineTypes: readonly string[] | null;
  products: readonly string[];
  dates: DateRangePreset | DateRange;
  vessels: readonly number[];
  vesselAvailability: readonly BallastCapacityVesselAvailability[] | null;
  pricesFilters: PricesBaseFiltersMapping;
  view: AnalyticsView;
  unit: UnitName;
  truncateY: boolean;
};

export type BallastCapacityVesselsParams = PaginationParams &
  SerializedDateRange & {
    locations: ReadonlyArray<{ resourceType: string; id: number }>;
    deadWeight?: DeadWeightObject;
    capacity?: CapacityObject;
    metric: BallastCapacityAggMetric;
    granularity: Granularity;
    vesselClassifications?: readonly string[];
    engineTypes: readonly string[];
    numberOfSplits: number;
    date: string;
    splitOn?: BallastCapacityParamsSplit;
    splitValues?: readonly string[];
    splitValuesToExclude?: readonly string[];
    products?: readonly number[];
    withFreightView: boolean;
    isHistorical: boolean;
    nDaysAhead?: number;
    vesselIds?: readonly number[];
    vesselTypeClassification?: VesselTypeClassification;
    vesselAvailability?: readonly BallastCapacityVesselAvailability[];
  };

export type BallastCapacityVesselPayload = {
  id: string;
  vessel: Vessel;
  zone: Zone;
  eta: string;
  sourceEta: string;
};

export type BallastCapacityTooltipProps = {
  unitName: UnitName | UnmanagedUnitName;
  granularity: Granularity;
};
