import { isDefined } from '@kpler/web-ui';
import circle from '@turf/circle';
import moment, { Moment } from 'moment';
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex-typescript-interface';

import { mapZoom$ } from 'src/store/subjects/mapZoom.subject';
import { RootState } from 'src/store/types';

import { roundToNearest5Minutes } from './mapItems.helper';

import InventoriesService from 'src/common/inventories/inventories.service';
import PositionsService from 'src/common/map/positions.service';
import { axiosApi } from 'src/services/axios.service';
import RefineriesService from 'src/services/refineries.service';
import ZoneService from 'src/services/zone.service';

import { serializeDate, toMoment } from 'src/helpers/date.helper';
import { isCommodities, isMerge, platform } from 'src/helpers/platform.helper';
import { applyAnchorageFilters } from 'src/main/map/helpers/anchorageFilters.helper';
import {
  DEFAULT_VISIBILITY_IF_FILTERS_ARE_ALL_UNSET,
  betaFilter,
  createFilterOnAttribute,
} from 'src/main/map/helpers/commonFilters.helper';
import {
  mainInstallationFiltersFilter,
  createFilterHideIfMoreThanTwoVesselsAreSelected,
  installationTypesFilter,
  getInstallationStatus,
  selectedInstallationsFilter,
  marketFilter,
} from 'src/main/map/helpers/installationFilters.helper';
import { applyVesselFilters } from 'src/main/map/helpers/vesselFilters.helper';

import { LayerState } from 'types/graphql';
import { InstallationMapPayload } from 'types/installation';
import { InventoriesSnapshotDetailTank, InventoriesSnapshotSplit } from 'types/inventories';
import {
  LatLon,
  LatitudeLongitude,
  RequestStatus,
  Granularity,
  ResourceType,
  LatitudeLongitudeMaybe,
  VesselTypeClassification,
} from 'types/legacy-globals';
import {
  VesselMetricsMapPayload,
  Focus,
  TooltipPositionInfo,
  MapFiltersPayload,
  MapFilters,
  MapSearch,
} from 'types/map';
import { PlayerDetails } from 'types/player';
import { RangeNumber } from 'types/range';
import { VesselMapPayload, VesselTrack, VesselTrackType } from 'types/vessel';
import { Zone } from 'types/zone';

type MapItemsState = {
  vessels: VesselMapPayload[];
  installations: InstallationMapPayload[];
  vesselMetrics: VesselMetricsMapPayload | null;
  anchorages: Zone[];
  tanks: InventoriesSnapshotDetailTank[];

  mapItemsPromise: Promise<void> | null;
  mapItemsStatus: RequestStatus;
  vesselItemsStatus: RequestStatus;
  tanksPromise: Promise<void> | null;
  tanksStatus: RequestStatus;

  focus: Focus;

  tracks: VesselTrack[];
  lastPositionRefresh: Moment | null;
  vesselTrackDateRange: { start: Moment; end: Moment };
};

export type MapItemsModule = MapItemsState & {
  // getters
  readonly filteredVessels: readonly VesselMapPayload[];
  readonly filteredInstallations: readonly InstallationMapPayload[];
  readonly filteredAnchorages: readonly Zone[];
  readonly vesselsById: { [key: number]: VesselMapPayload };
  readonly vesselById: (id: number) => VesselMapPayload | null;
  readonly keptTracks: readonly VesselTrack[];
  readonly installationsById: { [key: number]: InstallationMapPayload };
  readonly installationById: (id: number) => InstallationMapPayload | null;
  readonly positionsById: { [key: number]: TooltipPositionInfo };
  readonly positionById: (id: number) => TooltipPositionInfo | null;
  readonly focusPositions: LatLon[];

  // mutations
  SET_MAP_VESSELS(mapItems: VesselMapPayload[]): void;
  SET_MAP_INSTALLATIONS(mapItems: InstallationMapPayload[]): void;
  SET_MAP_ANCHORAGES(anchorages: Zone[]): void;
  SET_TANKS(tanks: InventoriesSnapshotDetailTank[]): void;
  SET_MAP_VESSEL_METRICS(mapItems: VesselMetricsMapPayload): void;
  SET_MAP_ITEMS_PROMISE(promise: Promise<void>): void;
  SET_MAP_ITEMS_STATUS(status: RequestStatus): void;
  SET_VESSEL_ITEMS_STATUS(status: RequestStatus): void;
  SET_TANK_PROMISE(promise: Promise<void>): void;
  SET_TANKS_STATUS(status: RequestStatus): void;
  RESET_TRACKS(): void;
  SET_TRACKS(track: VesselTrack[]): void;
  SET_VESSEL_TRACK_DATE_RANGE(vesselTrackDateRange: { start: Moment; end: Moment }): void;
  SET_FOCUS(focus: Focus): void;
  SET_LAST_POSITION_REFRESH(date: Moment): void;
  // actions
  fetchMapItems(): Promise<void>;
  fetchVesselMetrics(): Promise<void>;
  fetchTanksData(params: { zones: number[]; droneData: boolean }): Promise<void>;
  refreshVessels(): Promise<void>;
  showVesselPositions(payload: Array<{ id: number; type?: VesselTrackType }>): Promise<void>;
  showVesselPositionsAndSoftZoom(
    payload: Array<{
      id: number;
      type?: VesselTrackType;
    }>,
  ): Promise<void>;
  showVesselPositionsAndForceZoom(
    payload: Array<{
      id: number;
      type?: VesselTrackType;
    }>,
  ): Promise<void>;
  removeTrack(id: number): Promise<void>;
  setActiveTrack(id: number): Promise<void>;
  keepTrack(id: number): Promise<void>;
  unkeepTrack(id: number): Promise<void>;
  unkeepAllTracks(): Promise<void>;
  resetVesselTrackDateRange(): Promise<void>;
  updateVesselTrackDateRange(payload: { start: Moment; end: Moment }): Promise<void>;
  setFocusOnInstallation(installation: { position: LatitudeLongitudeMaybe }): Promise<void>;
  setFocusOnPlayerFleetAndInstallations(player: PlayerDetails): Promise<void>;
  setFocusOnZone(zone: Zone): Promise<void>;
  setFocusOnCircle(pointWithRange: {
    longitude: number;
    latitude: number;
    range: number;
  }): Promise<void>;
  setFocusOnPositions(positions: LatLon[]): Promise<void>;
  softZoom(): Promise<void>;
  forceZoom(): Promise<void>;
  setMapInstallations(installations: InstallationMapPayload[]): Promise<void>;
};

const DEFAULT_VESSEL_TRACK_DATE_RANGE = Object.freeze({
  start: moment.utc().subtract(1, 'week').startOf('day'),
  end: moment.utc().endOf('day'),
});

const moduleState: MapItemsState = {
  vessels: [],
  installations: [],
  vesselMetrics: null,
  anchorages: [],
  tanks: [],

  mapItemsPromise: null,
  mapItemsStatus: RequestStatus.IDLE,
  vesselItemsStatus: RequestStatus.IDLE,
  tanksPromise: null,
  tanksStatus: RequestStatus.IDLE,

  focus: {
    vessels: [],
    installations: [],
    positions: [],
  },

  tracks: [],

  lastPositionRefresh: null,

  vesselTrackDateRange: DEFAULT_VESSEL_TRACK_DATE_RANGE,
};

const moduleGetters: GetterTree<MapItemsModule, RootState> = {
  filteredVessels: (state, getters, rootState, rootGetters): readonly VesselMapPayload[] => {
    const allVessels: VesselMapPayload[] = state.vessels;

    const searchValues: MapSearch = rootState.map.search;
    const filterValues: MapFilters = rootState.map.filters;

    const mapFiltersPayload: MapFiltersPayload | null = rootState.map.cargoFilterItems;

    const shouldDisableFiltersBecauseItsPrefetch = !isDefined(allVessels.at(0)?.engineMetrics); // not all advanced filters are available in the prefetch endpoint /mobile/vessels
    const areAdvancedFiltersEnabled: boolean =
      !shouldDisableFiltersBecauseItsPrefetch &&
      rootState.map.display.vesselLayer === LayerState.Default;
    const vesselClassification: VesselTypeClassification = rootGetters.classificationPlatform;
    const shouldShowOpenVessels = rootGetters.userHasAccessToOpenVessels;

    const filteredVessels = applyVesselFilters(allVessels, filterValues, searchValues, {
      mapFiltersPayload,
      vesselClassification,
      shouldShowOpenVessels,
      areAdvancedFiltersEnabled,
      isWidget: false,
    });

    return Object.freeze(filteredVessels);
  },
  filteredInstallations: (state, getters, rootState, rootGetters) => {
    const searchFiltersFunctions = [
      mainInstallationFiltersFilter(rootState.map.cargoFilterItems),
      createFilterHideIfMoreThanTwoVesselsAreSelected(rootState.map.search.vessels),
    ];

    const marketCheck = isMerge ? [marketFilter(rootState.map.filters.markets)] : [];

    const staticFilterChecks = [
      ...marketCheck,
      installationTypesFilter(rootState.map.filters.installationTypes),
      createFilterOnAttribute<InstallationMapPayload>(
        rootState.map.filters.installationStatus,
        getInstallationStatus,
        { defaultVisibility: DEFAULT_VISIBILITY_IF_FILTERS_ARE_ALL_UNSET },
      ),
      betaFilter(rootState.map.filters.betaInstallationStatus),
    ];

    const selectionFilter = selectedInstallationsFilter(
      rootGetters.installationIdsInSearchLocation,
    );
    const filteredInstallations = state.installations.filter(installation => {
      return (
        // Always show installation chosen by the user.
        selectionFilter(installation) ||
        (searchFiltersFunctions.every(check => check(installation)) &&
          (rootState.map.display.installationLayer !== LayerState.Default ||
            staticFilterChecks.every(check => check(installation))))
      );
    });

    // Prevent Vue attaching listeners to this huge list
    return Object.freeze(filteredInstallations);
  },

  filteredAnchorages: (state, getters, rootState) => {
    const allAnchorages: Zone[] = state.anchorages;
    const searchValues: MapSearch = rootState.map.search;
    const filterValues: MapFilters = rootState.map.filters;
    const mapFiltersPayload: MapFiltersPayload | null = rootState.map.cargoFilterItems;
    const areAdvancedFiltersEnabled: boolean =
      rootState.map.display.installationLayer === LayerState.Default;

    const filteredAnchorages = applyAnchorageFilters(allAnchorages, filterValues, searchValues, {
      isMerge,
      areAdvancedFiltersEnabled,
      mapFiltersPayload,
    });

    return Object.freeze(filteredAnchorages);
  },
  vesselsById: state => {
    return Object.fromEntries(state.vessels.map(vessel => [vessel.id, vessel]));
  },
  vesselById: (_, getters) => id => {
    return getters.vesselsById[id] ?? null;
  },
  keptTracks: state => {
    return state.tracks.filter(t => t.isKept);
  },
  installationsById: state => {
    return Object.fromEntries(
      state.installations.map(installation => [installation.id, installation]),
    );
  },
  installationById: (state, getters) => id => {
    return getters.installationsById[id] ?? null;
  },
  positionsById: state => {
    return Object.fromEntries(
      state.tracks.flatMap(track => {
        const lastIndex = track.positions.length - 1;
        return track.positions.map((position, index, array) => {
          return [
            position.id,
            {
              ...position,
              latency:
                index < lastIndex ? PositionsService.getLatency(position, array[index + 1]) : null,
              vesselId: track.vesselId,
            },
          ];
        });
      }),
    );
  },
  positionById: (state, getters) => id => {
    return getters.positionsById[id] ?? null;
  },
  focusPositions: (state, getters) => {
    const { positions } = state.focus;

    const installationPositions = state.focus.installations
      .map(id => getters.installationById(id)?.position ?? null)
      .filter(
        (x): x is NonNullable<LatitudeLongitude> =>
          x !== null && x.latitude !== undefined && x.longitude !== undefined,
      )
      .map(x => ({
        lat: x.latitude,
        lon: x.longitude,
      }));

    const vesselPositions = state.focus.vessels
      .map(id => getters.vesselById(id)?.lastPosition ?? null)
      .filter((x): x is NonNullable<VesselMapPayload['lastPosition']> => x !== null)
      .map(x => ({
        lat: x.geo.lat,
        lon: x.geo.lon,
      }));

    return positions.concat(installationPositions).concat(vesselPositions);
  },
};

const moduleMutations: MutationTree<MapItemsModule> = {
  SET_MAP_VESSELS: (state, vessels) => {
    const { compare } = new Intl.Collator('en', { sensitivity: 'base' });
    state.vessels = Object.freeze(
      vessels.sort((a, b) => compare(a.name, b.name)),
    ) as VesselMapPayload[];
  },
  SET_MAP_INSTALLATIONS: (state, installations) => {
    const { compare } = new Intl.Collator('en', { sensitivity: 'base' });
    state.installations = Object.freeze(
      installations.sort((a, b) => compare(a.name, b.name)),
    ) as InstallationMapPayload[];
  },
  SET_MAP_ANCHORAGES: (state, anchorages) => {
    state.anchorages = anchorages;
  },
  SET_TANKS: (state, tanks) => {
    state.tanks = tanks;
  },
  SET_MAP_VESSEL_METRICS: (state, vesselMetrics) => {
    state.vesselMetrics = vesselMetrics;
  },
  SET_MAP_ITEMS_PROMISE: (state, promise) => {
    state.mapItemsPromise = promise;
  },
  SET_MAP_ITEMS_STATUS: (state, status) => {
    state.mapItemsStatus = status;
  },
  SET_VESSEL_ITEMS_STATUS: (state, status) => {
    state.vesselItemsStatus = status;
  },
  SET_TANK_PROMISE: (state, promise) => {
    state.tanksPromise = promise;
  },
  SET_TANKS_STATUS: (state, status) => {
    state.tanksStatus = status;
  },
  RESET_TRACKS: state => {
    state.tracks = state.tracks.filter(t => t.isKept).map(t => ({ ...t, isActive: false }));
  },
  SET_TRACKS: (state, tracks) => {
    state.tracks = tracks;
  },
  SET_VESSEL_TRACK_DATE_RANGE: (state, vesselTrackDateRange) => {
    state.vesselTrackDateRange = vesselTrackDateRange;
  },
  SET_FOCUS: (state, focus) => {
    state.focus = focus;
  },
  SET_LAST_POSITION_REFRESH: (state, date) => {
    state.lastPositionRefresh = date;
  },
};

const moduleActions: ActionTree<MapItemsModule, RootState> = {
  fetchMapItems({ state, commit, rootGetters, dispatch }) {
    if (state.mapItemsPromise !== null) {
      return state.mapItemsPromise;
    }
    let installationPromise: Promise<void>;
    if (rootGetters.userHasPermission('installation:read')) {
      installationPromise = axiosApi
        .get<InstallationMapPayload[]>('/installations', { params: { light: true } })
        .then(data => {
          commit('SET_MAP_INSTALLATIONS', data);
          if (isCommodities || isMerge) {
            RefineriesService.getRefineriesInstallations().then(refineries => {
              const refineriesIds = refineries.map(x => Number(x.id));
              const installations = state.installations.map(installation => ({
                ...installation,
                isRefinery: refineriesIds.includes(installation.id),
              }));
              commit('SET_MAP_INSTALLATIONS', installations);
            });
          }
        })
        .catch(() => {
          console.error('Failed to get installations.');
        });
    } else {
      installationPromise = Promise.resolve();
    }

    const anchoragePromise = ZoneService.getAnchorages()
      .then(data => {
        commit('SET_MAP_ANCHORAGES', data);
      })
      .catch(() => {
        console.error('Failed to get anchorages.');
      });

    const vesselMetricsPromise = dispatch('fetchVesselMetrics');
    const promise = Promise.all([installationPromise, anchoragePromise, vesselMetricsPromise])
      .then(() => {
        commit('SET_MAP_ITEMS_STATUS', RequestStatus.IDLE);
      })
      .catch(() => {
        commit('SET_MAP_ITEMS_STATUS', RequestStatus.ERROR);
        throw new Error('Failed to fetch map items');
      });

    commit('SET_MAP_ITEMS_PROMISE', promise);
    return promise;
  },
  fetchVesselMetrics({ commit }) {
    return axiosApi
      .get<VesselMetricsMapPayload>('/vessels/metrics')
      .then(data => commit('SET_MAP_VESSEL_METRICS', data))
      .catch(() => {
        console.error('Failed to get vessel metrics.');
        throw new Error('Failed to get vessel metrics.');
      });
  },
  fetchTanksData({ commit }, params) {
    commit('SET_TANKS_STATUS', RequestStatus.LOADING);
    const tanksPromise = InventoriesService.getSnapshot({
      platform,
      cargoTrackingEnhancement: false,
      endDate: serializeDate(toMoment()),
      filters: [],
      granularity: Granularity.DAYS,
      locationIds: params.zones,
      locationResourceType: ResourceType.ZONE,
      splitCriteria: InventoriesSnapshotSplit.TANK,
      droneData: params.droneData,
    })
      .then(data => {
        commit('SET_TANKS', data.details as InventoriesSnapshotDetailTank[]);
        commit('SET_TANKS_STATUS', RequestStatus.IDLE);
      })
      .catch(() => {
        console.error('Failed to get tanks.');
        commit('SET_TANKS_STATUS', RequestStatus.ERROR);
      });

    commit('SET_TANK_PROMISE', tanksPromise);
    return tanksPromise;
  },
  async refreshVessels({ state, commit, getters }) {
    const getVessels = (): Promise<VesselMapPayload[]> =>
      axiosApi
        .get('vessels', {
          params: { last: state.lastPositionRefresh?.format() },
        })
        .then(payload => {
          commit('SET_LAST_POSITION_REFRESH', roundToNearest5Minutes(moment.utc()));
          return payload;
        });

    const getLightVessels = (): Promise<VesselMapPayload[]> => axiosApi.get('/mobile/vessels');

    const handleResponse = (vessels: VesselMapPayload[]) => {
      const { vesselsById } = getters;
      const newVesselsById: { [key: number]: VesselMapPayload } = Object.fromEntries(
        vessels.map(vessel => [vessel.id, vessel]),
      );
      const allVessels = Object.values({ ...vesselsById, ...newVesselsById });
      commit('SET_MAP_VESSELS', allVessels);
      commit('SET_VESSEL_ITEMS_STATUS', RequestStatus.IDLE);
    };

    if (state.lastPositionRefresh === null) {
      commit('SET_VESSEL_ITEMS_STATUS', RequestStatus.LOADING);
      // PERFORMANCE_FIX: Prefetch position only to display vessel quickly on the first render of the map
      // To delete once the performance are better on front and back
      if (isMerge) {
        getLightVessels().then(handleResponse).then(getVessels).then(handleResponse);
      } else {
        getVessels().then(handleResponse);
      }
    } else {
      getVessels().then(handleResponse);
    }
  },
  showVesselPositions({ state, commit, getters }, payloads) {
    // @TODO Do something to prevent race conditions
    // Can be switchMap with RxJS, or Axios cancel, or even something else
    const focus: Focus = {
      positions: [],
      installations: [],
      vessels: [],
    };
    let { tracks } = state;
    const { start: after, end: before } = state.vesselTrackDateRange;
    return Promise.all([
      state.mapItemsPromise,
      ...payloads.map(payload => {
        const { id, type } = payload;
        return PositionsService.getVesselPositions(id, after, before).then(positions => {
          const latlon = positions.map(x => x.geo);
          focus.positions.push(...latlon);
          const vesselPositionDate = getters.vesselById(id)?.lastPosition?.receivedTime;
          const date = vesselPositionDate ? moment.utc(vesselPositionDate) : undefined;
          const vesselPositionInDateRange = date?.isBetween(after, before) ?? false;
          const noTrackInDateRange = focus.positions.length === 0;
          const shouldUseVesselPosition = vesselPositionInDateRange || noTrackInDateRange;
          if (shouldUseVesselPosition) {
            focus.vessels.push(id);
          }

          const trackFromState = tracks.find(t => t.vesselId === id);
          const newTrack: VesselTrack = {
            vesselId: trackFromState?.vesselId ?? id,
            positions,
            useVesselPosition: shouldUseVesselPosition,
            type: trackFromState?.type ?? type,
            isActive: trackFromState?.isActive ?? true,
            isKept: trackFromState?.isKept ?? false,
          };
          tracks = tracks.filter(t => t.vesselId !== id).concat(newTrack);
        });
      }),
    ]).then(() => {
      commit('SET_TRACKS', tracks);
      commit('SET_FOCUS', focus);
    });
  },
  async setActiveTrack({ state, commit }, id) {
    commit('RESET_TRACKS');
    const tracks = state.tracks.map(track => {
      if (track.vesselId === id) {
        return { ...track, isActive: true };
      }
      return track;
    });
    commit('SET_TRACKS', tracks);
  },
  async removeTrack({ state, commit }, id) {
    const tracks = state.tracks.filter(t => t.vesselId !== id);
    commit('SET_TRACKS', tracks);
  },
  async keepTrack({ state, commit }, id) {
    const tracks = state.tracks.map(track => {
      if (track.vesselId === id) {
        return { ...track, isKept: true };
      }
      return track;
    });
    commit('SET_TRACKS', tracks);
  },
  async unkeepTrack({ state, commit }, id) {
    const isActive = state.tracks.find(t => t.vesselId === id && t.isActive);
    const tracks = isActive
      ? state.tracks.map(t => (t.vesselId === id ? { ...t, isKept: false } : t))
      : state.tracks.filter(t => t.vesselId !== id);
    commit('SET_TRACKS', tracks);
  },
  async unkeepAllTracks({ state, commit }) {
    const tracks = state.tracks
      .map(t => (t.isActive ? { ...t, isKept: false } : t))
      .filter(t => !t.isKept);
    commit('SET_TRACKS', tracks);
  },
  async resetVesselTrackDateRange({ commit }) {
    commit('SET_VESSEL_TRACK_DATE_RANGE', DEFAULT_VESSEL_TRACK_DATE_RANGE);
  },
  async updateVesselTrackDateRange({ state, commit, dispatch }, payload) {
    const start = payload.start.startOf('day');
    const end = payload.end.endOf('day');
    commit('SET_VESSEL_TRACK_DATE_RANGE', { start, end });
    return dispatch(
      'showVesselPositions',
      state.tracks.map(t => ({ id: t.vesselId })),
    );
  },
  async showVesselPositionsAndSoftZoom({ dispatch }, payload) {
    await dispatch('showVesselPositions', payload);
    return dispatch('softZoom');
  },
  async showVesselPositionsAndForceZoom({ dispatch }, payload) {
    await dispatch('showVesselPositions', payload);
    return dispatch('forceZoom');
  },
  async setFocusOnPlayerFleetAndInstallations({ state, commit, dispatch }, player) {
    await dispatch('fetchMapItems');
    const installationIds = player.capacityHold.export.capacities
      .concat(player.capacityHold.import.capacities)
      .map(x => x.installation.id);
    const vesselIds = player.controlledFleet.vessels
      .concat(player.ownedFleet.vessels)
      .map(x => x.vessel.id);
    const focus = {
      ...state.focus,
      positions: [],
      installations: installationIds,
      vessels: vesselIds,
    };
    commit('SET_FOCUS', focus);
    return dispatch('softZoom');
  },
  setFocusOnInstallation({ state, commit, dispatch }, installation) {
    const focus: Focus = {
      ...state.focus,
      positions: [],
      installations: [],
      vessels: [],
    };

    const { latitude, longitude } = installation.position;

    if (latitude !== undefined && longitude !== undefined) {
      const position: LatLon = {
        lat: latitude,
        lon: longitude,
      };

      focus.positions = [position];
    }

    commit('SET_FOCUS', focus);
    return dispatch('softZoom');
  },
  async setFocusOnZone({ state, commit, dispatch }, zone) {
    // Focus on installations inside the zone.
    const installationIds: number[] = (await ZoneService.getInstallationIds(zone.id)).map(
      x => x.id,
    );

    // Focus on the shape of the zone if any or create a shape from range / center coordinates if any.
    let positions: LatLon[] = [];
    if (zone.shape) {
      positions = zone.shape.coordinates.flat(2).map(x => ({ lon: x[0], lat: x[1] }));
    } else if (zone.geo && zone.range) {
      const center = [zone.geo.lon, zone.geo.lat];
      const radiusInKm = zone.range / 1000;
      positions = circle(center, radiusInKm)
        .geometry.coordinates.flat()
        .map((x: RangeNumber) => ({ lon: x[0], lat: x[1] }));
    }

    const focus = {
      ...state.focus,
      positions,
      installations: installationIds,
      vessels: [],
    };

    commit('SET_FOCUS', focus);
    return dispatch('softZoom');
  },
  setFocusOnCircle({ state, commit, dispatch }, pointWithRange) {
    const center = [pointWithRange.longitude, pointWithRange.latitude];
    const radiusInKm = pointWithRange.range / 1000;
    const positions = circle(center, radiusInKm)
      .geometry.coordinates.flat()
      .map((x: RangeNumber) => ({ lon: x[0], lat: x[1] }));

    const focus = {
      ...state.focus,
      positions,
      installations: [],
      vessels: [],
    };

    commit('SET_FOCUS', focus);
    return dispatch('softZoom');
  },
  setFocusOnPositions({ state, commit, dispatch }, positions) {
    const focus = {
      ...state.focus,
      positions,
      installations: [],
      vessels: [],
    };

    commit('SET_FOCUS', focus);
    return dispatch('forceZoom');
  },
  async softZoom({ rootState, getters }) {
    if (rootState.settings.map.autoZoom) {
      mapZoom$.next(getters.focusPositions);
    }
  },
  async forceZoom({ getters }) {
    mapZoom$.next(getters.focusPositions);
  },
  async setMapInstallations({ commit }, installations) {
    commit('SET_MAP_INSTALLATIONS', installations);
  },
};

const mapModule: Module<MapItemsModule, RootState> = {
  getters: moduleGetters,
  state: moduleState,
  mutations: moduleMutations,
  actions: moduleActions,
};

export default mapModule;
