import moment, { Moment } from 'moment';

import { assertNever } from 'src/helpers/assertNever.helper';
import {
  DateTimeDisplay,
  formatToAnnual,
  formatToDaily,
  formatToMonthly,
  formatToQuarterly,
  serializeDate,
  deserializeDate,
} from 'src/helpers/date.helper';

import { DateRangePreset, PastFutureDateRangePreset } from 'types/analytics';
import {
  DateRange,
  DeserializedDateRange,
  SerializedDateRange,
  DateRangeScalar,
} from 'types/dates';
import { FormatDateFn, Granularity, ObjectBase } from 'types/legacy-globals';

export type DateRangePresetOption<
  T extends DateRangePreset | PastFutureDateRangePreset =
    | DateRangePreset
    | PastFutureDateRangePreset,
> = {
  id: T;
  label: string;
  shortLabel: string;
};

const DATE_FORMAT = DateTimeDisplay.EXPORT_WEEKLY;

export const PRESET_LABELS = Object.freeze<Array<DateRangePresetOption<DateRangePreset>>>([
  { id: DateRangePreset.ONE_MONTH, label: 'Last month', shortLabel: 'Last 1m' },
  { id: DateRangePreset.THREE_MONTHS, label: 'Last 3 months', shortLabel: 'Last 3m' },
  { id: DateRangePreset.SIX_MONTHS, label: 'Last 6 months', shortLabel: 'Last 6m' },
  { id: DateRangePreset.TWELVE_MONTHS, label: 'Last year', shortLabel: 'Last 1y' },
  { id: DateRangePreset.EIGHTEEN_MONTHS, label: 'Last 18 months', shortLabel: 'Last 18m' },
  { id: DateRangePreset.TWENTY_FOUR_MONTHS, label: 'Last 2 years', shortLabel: 'Last 2y' },
  { id: DateRangePreset.ALL_DATA, label: 'All data', shortLabel: 'All data' },
]);

export const CURRENT_PRESET_LABELS = Object.freeze<Array<DateRangePresetOption<DateRangePreset>>>([
  { id: DateRangePreset.ONE_MONTH, label: '1 month', shortLabel: '1m' },
  { id: DateRangePreset.THREE_MONTHS, label: '3 months', shortLabel: '3m' },
  { id: DateRangePreset.SIX_MONTHS, label: '6 months', shortLabel: '6m' },
  { id: DateRangePreset.TWELVE_MONTHS, label: '1 year', shortLabel: '1y' },
  { id: DateRangePreset.EIGHTEEN_MONTHS, label: '18 months', shortLabel: '18m' },
  { id: DateRangePreset.TWENTY_FOUR_MONTHS, label: '2 years', shortLabel: '2y' },
  { id: DateRangePreset.ALL_DATA, label: 'All data', shortLabel: 'All data' },
]);

export const FUTURE_PRESET_LABELS = Object.freeze<Array<DateRangePresetOption<DateRangePreset>>>([
  { id: DateRangePreset.ONE_MONTH, label: 'Next month', shortLabel: 'Next 1m' },
  { id: DateRangePreset.THREE_MONTHS, label: 'Next 3 months', shortLabel: 'Next 3m' },
  { id: DateRangePreset.SIX_MONTHS, label: 'Next 6 months', shortLabel: 'Next 6m' },
  { id: DateRangePreset.ALL_DATA, label: 'All data', shortLabel: 'All data' },
]);

export const PAST_FUTURE_PRESET_LABELS = Object.freeze<
  Array<DateRangePresetOption<PastFutureDateRangePreset>>
>([
  {
    id: PastFutureDateRangePreset.LAST_TWELVE_MONTHS,
    label: 'Last year',
    shortLabel: 'Last 1y',
  },
  {
    id: PastFutureDateRangePreset.LAST_THREE_MONTHS,
    label: 'Last 3 months',
    shortLabel: 'Last 3m',
  },
  { id: PastFutureDateRangePreset.LAST_MONTH, label: 'Last month', shortLabel: 'Last 1m' },
  { id: PastFutureDateRangePreset.NEXT_MONTH, label: 'Next month', shortLabel: 'Next 1m' },
  {
    id: PastFutureDateRangePreset.NEXT_THREE_MONTHS,
    label: 'Next 3 months',
    shortLabel: 'Next 3m',
  },
  {
    id: PastFutureDateRangePreset.NEXT_TWELVE_MONTHS,
    label: 'Next year',
    shortLabel: 'Next 1y',
  },
  { id: PastFutureDateRangePreset.ALL_DATA, label: 'All data', shortLabel: 'All data' },
]);

export const convertPresetToStartDate = (preset: DateRangePreset, minDate: Moment): Moment => {
  if (preset === DateRangePreset.ONE_MONTH) {
    return moment.utc().subtract(1, 'month');
  }
  if (preset === DateRangePreset.THREE_MONTHS) {
    return moment.utc().subtract(3, 'month');
  }
  if (preset === DateRangePreset.SIX_MONTHS) {
    return moment.utc().subtract(6, 'month');
  }
  if (preset === DateRangePreset.TWELVE_MONTHS) {
    return moment.utc().subtract(12, 'month');
  }
  if (preset === DateRangePreset.EIGHTEEN_MONTHS) {
    return moment.utc().subtract(18, 'month');
  }
  if (preset === DateRangePreset.TWENTY_FOUR_MONTHS) {
    return moment.utc().subtract(24, 'month');
  }
  if (preset === DateRangePreset.ALL_DATA) {
    return minDate;
  }
  return assertNever(preset);
};

export const convertPresetToDateRange = (
  preset: DateRangePreset,
  minDate: Moment,
  maxDate?: Moment,
): DateRange => {
  const startDate = convertPresetToStartDate(preset, minDate).format(DATE_FORMAT);
  const mEndDate = maxDate === undefined ? moment.utc() : moment.min(maxDate, moment.utc());
  const endDate = mEndDate.format(DATE_FORMAT);

  return { startDate, endDate };
};

const convertPresetToFutureEndDate = (preset: DateRangePreset, maxDate: Moment): Moment => {
  if (
    preset === DateRangePreset.ONE_MONTH ||
    preset === DateRangePreset.THREE_MONTHS ||
    preset === DateRangePreset.SIX_MONTHS ||
    preset === DateRangePreset.TWELVE_MONTHS ||
    preset === DateRangePreset.EIGHTEEN_MONTHS ||
    preset === DateRangePreset.TWENTY_FOUR_MONTHS
  ) {
    return moment.utc().add(8, 'week');
  }
  if (preset === DateRangePreset.ALL_DATA) {
    return maxDate;
  }
  return assertNever(preset);
};

export const convertPredictivePresetToDateRange = (
  preset: DateRangePreset,
  minDate: Moment,
  maxDate: Moment,
  showFuture: boolean,
): DateRange => {
  if (!showFuture) {
    return convertPresetToDateRange(preset, minDate, maxDate);
  }

  const startDate = convertPresetToStartDate(preset, minDate).format(DATE_FORMAT);
  const endDate = convertPresetToFutureEndDate(preset, maxDate).format(DATE_FORMAT);

  return { startDate, endDate };
};

const convertPresetToFutureEndDateByMonth = (preset: DateRangePreset, maxDate: Moment): Moment => {
  if (preset === DateRangePreset.ONE_MONTH) {
    return moment.utc().add(1, 'month');
  }
  if (preset === DateRangePreset.THREE_MONTHS) {
    return moment.utc().add(3, 'month');
  }
  if (preset === DateRangePreset.SIX_MONTHS) {
    return moment.utc().add(6, 'month');
  }
  if (preset === DateRangePreset.TWELVE_MONTHS) {
    return moment.utc().add(12, 'month');
  }
  if (preset === DateRangePreset.EIGHTEEN_MONTHS) {
    return moment.utc().add(18, 'month');
  }
  if (preset === DateRangePreset.TWENTY_FOUR_MONTHS) {
    return moment.utc().add(24, 'month');
  }
  if (preset === DateRangePreset.ALL_DATA) {
    return maxDate;
  }
  return assertNever(preset);
};

export const convertDateRangeToMonthStart = (dateRange: DateRange): DateRange => {
  const { startDate, endDate } = dateRange;
  const startDateMonthStart = moment(startDate).startOf('month').format(DATE_FORMAT);
  const endDateMonthStart = moment(endDate).startOf('month').format(DATE_FORMAT);
  return {
    startDate: startDateMonthStart,
    endDate: endDateMonthStart,
  };
};

export const convertPresetToFutureDateRange = (
  preset: DateRangePreset,
  maxDate: Moment,
): DateRange => {
  const startDate = moment.utc().format(DATE_FORMAT);
  const endDate = convertPresetToFutureEndDateByMonth(preset, maxDate).format(DATE_FORMAT);

  return { startDate, endDate };
};

const dateRangeFormatMap: { [key in Granularity]: FormatDateFn } = {
  [Granularity.DAYS]: formatToDaily,
  [Granularity.WEEKS]: formatToDaily,
  [Granularity.EIAS]: formatToDaily,
  [Granularity.MID_WEEKS]: formatToDaily,
  [Granularity.MONTHS]: formatToMonthly,
  [Granularity.QUARTERS]: formatToQuarterly,
  [Granularity.YEARS]: formatToAnnual,
};

export const formatDateRange = (
  dateRange: DateRange | DateRangePreset | PastFutureDateRangePreset,
  presets: ReadonlyArray<{ id: DateRangePreset | PastFutureDateRangePreset; label: string }>,
  isSeasonal: boolean,
  granularity?: Granularity,
): string => {
  if (isSeasonal) {
    return 'seasonal';
  }
  if (typeof dateRange === 'string') {
    const labelObj = presets.find(x => x.id === dateRange);
    if (labelObj === undefined) {
      throw new Error(`Invalid preset: ${dateRange}.`);
    }
    return labelObj.label;
  }
  const formatDate = dateRangeFormatMap[granularity || Granularity.DAYS];
  return `${formatDate(dateRange.startDate)} — ${formatDate(dateRange.endDate)}`;
};

export const getDurationAsDaysFromPreset = (preset: DateRangePreset): number => {
  switch (preset) {
    case DateRangePreset.ONE_MONTH:
      return moment.duration(1, 'month').asDays();
    case DateRangePreset.THREE_MONTHS:
      return moment.duration(3, 'month').asDays();
    case DateRangePreset.SIX_MONTHS:
      return moment.duration(6, 'month').asDays();
    case DateRangePreset.EIGHTEEN_MONTHS:
      return moment.duration(18, 'month').asDays();
    case DateRangePreset.TWELVE_MONTHS:
      return moment.duration(12, 'month').asDays();
    case DateRangePreset.TWENTY_FOUR_MONTHS:
      return moment.duration(24, 'month').asDays();
    default:
      return 0;
  }
};

export const convertPastFuturePresetToDateRange = (
  preset: PastFutureDateRangePreset,
  minDate: Moment,
  maxDate: Moment,
): DateRange => {
  const now = moment.utc();

  const { startDate, endDate } = (() => {
    switch (preset) {
      case PastFutureDateRangePreset.LAST_MONTH:
        return { startDate: now.clone().subtract(1, 'month'), endDate: now.clone() };
      case PastFutureDateRangePreset.NEXT_MONTH:
        return { startDate: now.clone(), endDate: now.clone().add(1, 'month') };
      case PastFutureDateRangePreset.LAST_THREE_MONTHS:
        return { startDate: now.clone().subtract(3, 'month'), endDate: now.clone() };
      case PastFutureDateRangePreset.NEXT_THREE_MONTHS:
        return { startDate: now.clone(), endDate: now.clone().add(3, 'month') };
      case PastFutureDateRangePreset.LAST_TWELVE_MONTHS:
        return { startDate: now.clone().subtract(12, 'month'), endDate: now.clone() };
      case PastFutureDateRangePreset.NEXT_TWELVE_MONTHS:
        return { startDate: now.clone(), endDate: now.clone().add(12, 'month') };
      case PastFutureDateRangePreset.ALL_DATA:
        return { startDate: minDate, endDate: maxDate };
      default:
        return assertNever(preset);
    }
  })();

  const formattedStartDate = startDate.format(DATE_FORMAT);
  const formattedEndDate = endDate.format(DATE_FORMAT);

  return { startDate: formattedStartDate, endDate: formattedEndDate };
};

export const convertPresetToSplitPastAndFuture = (
  preset: DateRangePreset,
  minDate: Moment,
  maxDate: Moment,
): DeserializedDateRange => {
  if (preset === DateRangePreset.ONE_MONTH) {
    return {
      startDate: moment.utc().subtract(15, 'day'),
      endDate: moment.utc().add(15, 'day'),
    };
  }
  if (preset === DateRangePreset.THREE_MONTHS) {
    return {
      startDate: moment.utc().subtract(45, 'day'),
      endDate: moment.utc().add(45, 'day'),
    };
  }
  if (preset === DateRangePreset.SIX_MONTHS) {
    return {
      startDate: moment.utc().subtract(3, 'month'),
      endDate: moment.utc().add(3, 'month'),
    };
  }
  if (preset === DateRangePreset.TWELVE_MONTHS) {
    return {
      startDate: moment.utc().subtract(6, 'month'),
      endDate: moment.utc().add(6, 'month'),
    };
  }
  if (preset === DateRangePreset.EIGHTEEN_MONTHS) {
    return {
      startDate: moment.utc().subtract(9, 'month'),
      endDate: moment.utc().add(9, 'month'),
    };
  }
  if (preset === DateRangePreset.TWENTY_FOUR_MONTHS) {
    return {
      startDate: moment.utc().subtract(12, 'month'),
      endDate: moment.utc().add(12, 'month'),
    };
  }
  if (preset === DateRangePreset.ALL_DATA) {
    return {
      startDate: minDate,
      endDate: maxDate,
    };
  }
  return assertNever(preset);
};

export const convertFleetDevelopmentPresetToDateRange = (
  preset: DateRangePreset,
  minDate: Moment,
  maxDate: Moment,
  showFuture: boolean,
): DateRange => {
  if (!showFuture) {
    return convertPresetToDateRange(preset, minDate);
  }

  return dateRangeToScalar(convertPresetToSplitPastAndFuture(preset, minDate, maxDate));
};

export const convertPastFuturePresetsToDateRange = (
  pastPreset: DateRangePreset,
  futurePreset: DateRangePreset,
  minDate: Moment,
  maxDate: Moment,
): DateRange => {
  const startDate = convertPresetToStartDate(pastPreset, minDate).format(DATE_FORMAT);
  const endDate = convertPresetToFutureEndDateByMonth(futurePreset, maxDate).format(DATE_FORMAT);
  return { startDate, endDate };
};

export const convertPredictivePresetToRefineriesDateRange = (
  preset: DateRangePreset,
  minDate: Moment,
  maxDate: Moment,
  showFuture: boolean,
): DateRange => {
  if (!showFuture) {
    return convertPresetToDateRange(preset, minDate);
  }

  const startDate = convertPresetToStartDate(preset, minDate).format(DATE_FORMAT);
  const endDate =
    preset === DateRangePreset.ALL_DATA
      ? maxDate.format(DATE_FORMAT)
      : moment.utc().add(1, 'year').format(DATE_FORMAT);

  return { startDate, endDate };
};

const TRUNCATE_SEASONAL_AFTER = 2;

const formatGroupedYears = (groupedYears: number[][]) => {
  return groupedYears
    .map(years => {
      if (years.length === 1) {
        return String(years[0]);
      }
      return `${years[0]}-${years[years.length - 1]}`;
    })
    .join(', ');
};

export const formatSeasonalYears = (
  selected: readonly string[],
  options: ReadonlyArray<ObjectBase<string>>,
): {
  mainText: string;
  otherText?: string;
  otherContent?: string;
} => {
  const items = selected.length === 0 ? options.map(o => o.id) : [...selected];
  const sortedItems = items.sort().map(x => Number(x));
  const groups = [];
  let group: number[] = [];

  sortedItems.forEach(year => {
    if (group.length === 0 || group[group.length - 1] + 1 === year) {
      group.push(year);
    } else {
      groups.push(group);
      group = [year];
    }
  });
  groups.push(group);

  const otherGroup = groups.slice(TRUNCATE_SEASONAL_AFTER);
  const otherCount = otherGroup.flat().length;

  const hasOthers = otherCount > 1;
  const truncateMainAt = hasOthers ? TRUNCATE_SEASONAL_AFTER : TRUNCATE_SEASONAL_AFTER + otherCount;
  const mainGroup = groups.slice(0, truncateMainAt);
  const mainText = formatGroupedYears(mainGroup);

  const others = hasOthers
    ? {
        otherText: `${otherCount} others`,
        otherContent: formatGroupedYears(otherGroup),
      }
    : {};

  return {
    mainText,
    ...others,
  };
};
export function dateRangeToScalar(dateRange: DeserializedDateRange): SerializedDateRange;
export function dateRangeToScalar<T extends string>(dateRange: T): T;
export function dateRangeToScalar<T extends string>(
  dateRange: T | DeserializedDateRange,
): DateRangeScalar;
export function dateRangeToScalar<T extends string>(dateRange: T | DeserializedDateRange) {
  if (typeof dateRange === 'string') {
    return dateRange;
  }
  return {
    startDate: serializeDate(dateRange.startDate),
    endDate: serializeDate(dateRange.endDate),
  };
}

export const deserializeDateRange = (dateRange: DateRange): DeserializedDateRange => ({
  startDate: deserializeDate(dateRange.startDate),
  endDate: deserializeDate(dateRange.endDate),
});
