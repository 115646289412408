import { NavigationGuardNextRaw, RouteLocationNormalized, RouteRecordRaw } from '@kpler/web-ui';

import ResearchNewsService from 'src/common/researchNews';
import { DecodedToken } from 'src/domains/insight/types';
import store from 'src/store';

import useUpgradeModal from './components/upgradePackages/useUpgradeModal';
import INSIGHT_ACCESS_PERMISSIONS from './constants/insight.permissions';
import {
  INSIGHT_DATA_ADDON_DETAILS_ROUTE,
  INSIGHT_HOMEPAGE_ROUTE,
  INSIGHT_NEWS_DETAILS_ROUTE,
  INSIGHT_NEWS_ROUTE,
  INSIGHT_REPORTS_ROUTE,
  INSIGHT_REPORT_DETAILS_ROUTE,
  INSIGHT_RESEARCH_UPDATES_ROUTE,
  INSIGHT_RESEARCH_UPDATE_DETAILS_ROUTE,
  INSIGHT_SEARCH_ROUTE,
  INSIGHT_WEBINARS_ROUTE,
  INSIGHT_WEBINAR_DETAILS_ROUTE,
  INSIGHT_AUTHOR_DETAILS_ROUTE,
  INSIGHT_DATA_ROUTE,
  INSIGHT_DRAFT_PREVIEWS_ROUTE,
} from './insight.constants';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

const { toggleModal } = useUpgradeModal();

const TheInsightHomepageContainer = () =>
  store.getters.userHasOnePermission(INSIGHT_ACCESS_PERMISSIONS)
    ? import(
        /* webpackChunkName: "insight" */ 'src/domains/insight/TheInsightHomepageContainer.vue'
      )
    : import(
        /* webpackChunkName: "insight" */ 'src/domains/insight/TheInsightHomepageContainerPreview.vue'
      );
const TheInsightNewsPage = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/TheInsightNewsPage.vue');
const TheInsightResearchUpdatesPage = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/TheInsightResearchUpdatesPage.vue');
const TheInsightReportsPage = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/TheInsightReportsPage.vue');
const TheInsightWebinarsPage = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/TheInsightWebinarsPage.vue');
const TheInsightDataPage = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/TheInsightDataPage.vue');
const InsightLayout = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/InsightLayout.vue');
const ShortContentDetailsContainer = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/ShortContentDetailsContainer.vue');
const WebinarDetailsContainer = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/WebinarDetailsContainer.vue');
const ReportDetailsContainer = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/ReportDetailsContainer.vue');
const AuthorDetailsContainer = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/AuthorDetailsContainer.vue');
const TheInsightHeader = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/TheInsightHeader.vue');
const TheInsightSearchPage = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/TheInsightSearchPage.vue');
const DataAddonDetailsContainer = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/DataAddonDetailsContainer.vue');
const TheInsightDraftsPage = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/TheInsightDraftsPage.vue');

let decodedToken: DecodedToken;
type Permissions = Pick<
  DecodedToken,
  | 'hasNews'
  | 'hasResearchUpdates'
  | 'hasReports'
  | 'hasWebinars'
  | 'hasDataAddOns'
  | 'hasDraftAccess'
>;

const hasAccess = (permissionName: keyof Permissions): boolean => {
  return decodedToken[permissionName];
};

const beforeEnterFromToken =
  (permissionNames: Array<keyof Permissions>) =>
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNextRaw,
  ) => {
    const nextWithRefresh = forceRefreshGuard(to, next);
    decodedToken = await ResearchNewsService.getPermissionsFromToken();
    if (!permissionNames.some(hasAccess)) {
      toggleModal();
      return nextWithRefresh({ name: INSIGHT_HOMEPAGE_ROUTE });
    }
    return nextWithRefresh();
  };

const insightRoutes: RouteRecordRaw = {
  name: 'insight',
  path: 'insight',
  component: InsightLayout,
  redirect: INSIGHT_HOMEPAGE_ROUTE,
  children: [
    {
      path: '/',
      name: INSIGHT_HOMEPAGE_ROUTE,
      components: {
        default: TheInsightHomepageContainer,
        header: TheInsightHeader,
      },
      meta: {
        title: 'Insight',
      },
    },
    {
      name: INSIGHT_NEWS_ROUTE,
      path: 'news',
      components: {
        default: TheInsightNewsPage,
        header: TheInsightHeader,
      },
      props: { default: { title: 'News' } },
      meta: {
        title: 'Insight News',
      },
      beforeEnter: beforeEnterFromToken(['hasNews']),
    },
    {
      name: INSIGHT_NEWS_DETAILS_ROUTE,
      path: 'news/:slug',
      components: {
        default: ShortContentDetailsContainer,
        header: TheInsightHeader,
      },
      props: { default: { context: 'news' } },
      meta: {
        title: 'Insight News',
      },
      beforeEnter: beforeEnterFromToken(['hasNews']),
    },
    {
      name: INSIGHT_RESEARCH_UPDATES_ROUTE,
      path: 'research/research-updates',
      components: {
        default: TheInsightResearchUpdatesPage,
        header: TheInsightHeader,
      },
      props: { default: { title: 'Research updates' } },
      meta: {
        title: 'Insight Research updates',
      },
      beforeEnter: beforeEnterFromToken(['hasResearchUpdates']),
    },
    {
      name: INSIGHT_RESEARCH_UPDATE_DETAILS_ROUTE,
      path: 'research/research-updates/:slug',
      components: {
        default: ShortContentDetailsContainer,
        header: TheInsightHeader,
      },
      props: { default: { context: 'researchUpdates' } },
      meta: {
        title: 'Insight Research updates',
      },
      beforeEnter: beforeEnterFromToken(['hasResearchUpdates']),
    },
    {
      name: INSIGHT_REPORTS_ROUTE,
      path: 'research/reports',
      components: {
        default: TheInsightReportsPage,
        header: TheInsightHeader,
      },
      meta: {
        title: 'Insight Reports',
      },
      beforeEnter: beforeEnterFromToken(['hasReports']),
    },
    {
      name: INSIGHT_REPORT_DETAILS_ROUTE,
      path: 'research/reports/:slug',
      components: {
        default: ReportDetailsContainer,
        header: TheInsightHeader,
      },
      meta: {
        title: 'Insight Report',
      },
      beforeEnter: beforeEnterFromToken(['hasReports']),
    },
    {
      name: INSIGHT_WEBINARS_ROUTE,
      path: 'research/webinars',
      components: {
        default: TheInsightWebinarsPage,
        header: TheInsightHeader,
      },
      meta: {
        title: 'Insight Webinars',
      },
      beforeEnter: beforeEnterFromToken(['hasWebinars']),
    },
    {
      name: INSIGHT_DATA_ROUTE,
      path: 'data',
      components: {
        default: TheInsightDataPage,
        header: TheInsightHeader,
      },
      meta: {
        title: 'Insight Data',
      },
      beforeEnter: beforeEnterFromToken(['hasDataAddOns']),
    },
    {
      name: INSIGHT_WEBINAR_DETAILS_ROUTE,
      path: 'research/webinars/:slug',
      components: {
        default: WebinarDetailsContainer,
        header: TheInsightHeader,
      },
      meta: {
        title: 'Insight Webinar',
      },
      beforeEnter: beforeEnterFromToken(['hasWebinars']),
    },
    {
      name: INSIGHT_DATA_ADDON_DETAILS_ROUTE,
      path: 'data/addon/:slug',
      components: {
        default: DataAddonDetailsContainer,
        header: TheInsightHeader,
      },
      meta: {
        title: 'Insight Data Addon',
      },
      beforeEnter: beforeEnterFromToken(['hasDataAddOns']),
    },
    {
      name: INSIGHT_SEARCH_ROUTE,
      path: 'search',
      components: {
        default: TheInsightSearchPage,
        header: TheInsightHeader,
      },
      meta: {
        title: 'Insight Search',
      },
      beforeEnter: beforeEnterFromToken([
        'hasNews',
        'hasResearchUpdates',
        'hasReports',
        'hasWebinars',
      ]),
    },
    {
      name: INSIGHT_AUTHOR_DETAILS_ROUTE,
      path: 'author/:slug',
      components: {
        default: AuthorDetailsContainer,
        header: TheInsightHeader,
      },
      meta: {
        title: 'Insight Author',
      },
      beforeEnter: beforeEnterFromToken([
        'hasNews',
        'hasResearchUpdates',
        'hasReports',
        'hasWebinars',
        'hasDataAddOns',
      ]),
    },
    {
      name: INSIGHT_DRAFT_PREVIEWS_ROUTE,
      path: 'drafts',
      components: {
        default: TheInsightDraftsPage,
        header: TheInsightHeader,
      },
      meta: {
        title: 'Insight Drafts Preview',
      },
      beforeEnter: beforeEnterFromToken(['hasDraftAccess']),
    },
    // the next routes go there
  ],
};

export default insightRoutes;
