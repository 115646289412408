import { RouteRecordRaw } from '@kpler/web-ui';

import store from 'src/store';

const userHasAccessToPrices = (): boolean => {
  return (
    store.getters.userHasPermission('commodity_prices:read') ||
    (store.getters.userHasAccessToFreightFeature &&
      store.getters.userHasPermission('freight_prices:read'))
  );
};

const ThePrices = () =>
  userHasAccessToPrices()
    ? import(/* webpackChunkName: "prices" */ 'src/main/analytics/prices/ThePrices.vue')
    : import(/* webpackChunkName: "prices" */ 'src/main/analytics/prices/ThePricesPreview.vue');

const ThePricesRightPanel = () =>
  import(/* webpackChunkName: "prices" */ 'src/main/analytics/prices/ThePricesRightPanel.vue');

const pricesRoutes: RouteRecordRaw = {
  name: 'prices',
  path: 'prices',
  component: ThePrices,
  meta: { title: 'Prices' },
  children: [
    {
      name: 'prices-details',
      path: ':seriesId/details/:date',
      component: ThePricesRightPanel,
    },
  ],
};

export default pricesRoutes;
