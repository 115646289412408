export enum AnalyticsView {
  CHART_BAR = 'chart-bar',
  CHART_AREA = 'chart-area',
  CHART_LINE = 'chart-line',
  TABLE = 'table',
}

export enum AnalyticsDataType {
  FLOWS = 'flows',
  FLEET_METRICS = 'fleetMetrics',
  INVENTORIES = 'inventories',
  CONGESTION = 'congestion',
  FREIGHT_METRICS = 'freightMetrics',
  FLEET_DEVELOPMENT = 'fleetDevelopment',
  BALLAST_CAPACITY = 'ballastCapacity',
  FLEET_UTILIZATION = 'fleetUtilization',
  REFINERY_CAPACITIES = 'refineryCapacities',
}

export enum DateRangePreset {
  ONE_MONTH = '1m',
  THREE_MONTHS = '3m',
  SIX_MONTHS = '6m',
  TWELVE_MONTHS = '12m',
  EIGHTEEN_MONTHS = '18m',
  TWENTY_FOUR_MONTHS = '24m',
  ALL_DATA = 'all',
}

export enum PastFutureDateRangePreset {
  NEXT_MONTH = 'next1m',
  LAST_MONTH = 'last1m',
  LAST_THREE_MONTHS = 'last3m',
  NEXT_THREE_MONTHS = 'next3m',
  LAST_TWELVE_MONTHS = 'last12m',
  NEXT_TWELVE_MONTHS = 'next12m',
  ALL_DATA = 'all',
}
