import moment from 'moment';

import { formatFromStaticData, formatToStaticData } from 'src/adapters/zone.adapter';

import { axiosApi, axiosStaticV2 } from 'src/services/axios.service';

import { ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE } from 'src/helpers/date.helper';

import { LocationConstraints } from 'types/constraint';
import { InstallationDetailsPayload } from 'types/installation';
import { ResourceType } from 'types/legacy-globals';
import {
  CreateApiResponse,
  EditApiResponse,
  RecentlyAddedGeojson,
  DeleteApiResponse,
} from 'types/staticData';
import { ZoneEdit, ZoneFromStaticData, Zone, ZoneAlias, ZoneLinkedItems } from 'types/zone';

const getZone = (id: string | number): Promise<Zone> => axiosApi.get<Zone>(`/zones/${id}`);

const getZones = () => axiosApi.get<Zone[]>('/zones');

const getAnchorages = () => {
  return axiosApi.get<Zone[]>('zones', { params: { types: ['anchorage'] } });
};

const getZonesWithShapes = () =>
  axiosApi.get<Zone[]>('zones', {
    params: {
      types: [
        'country',
        'country_checkpoint',
        'region',
        'canal',
        'custom',
        'gulf',
        'gulf_checkpoint',
        'ocean',
        'sea',
        'bay',
        'strait',
        'subcontinent',
        'checkpoint',
      ],
      hasShape: true,
    },
  });

const getStorageZones = () =>
  axiosApi.get<Zone[]>('/zones').then(data => data.filter(x => x.isStorageSelected));

const getZoneFromStaticData = (id: string | string | number): Promise<ZoneEdit> =>
  axiosStaticV2
    .get<{ data: ZoneFromStaticData }>(`zones/${id}`)
    .then(zone => formatFromStaticData(zone.data));

const getZoneConstraints = (id: string | number): Promise<LocationConstraints> =>
  axiosStaticV2
    .get<{ data: LocationConstraints }>(`zones/${id}/constraints`)
    .then(data => data.data);

const getLinkedItems = (zoneId: string | number): Promise<ZoneLinkedItems> =>
  axiosStaticV2
    .get<{ data: ZoneLinkedItems }>(`zones/${zoneId}/linkeditems`)
    .then(data => data.data);

const editZone = (id: string, zone: ZoneEdit): Promise<EditApiResponse> =>
  axiosStaticV2.put(`zones/${id}`, formatToStaticData(zone));

const createZone = (zone: ZoneEdit): Promise<CreateApiResponse> =>
  axiosStaticV2.post('zones', formatToStaticData(zone));

const deleteZone = (id: string | number): Promise<DeleteApiResponse> =>
  axiosStaticV2.delete(`zones/${id}`);

const getPortGeojson = (): Promise<RecentlyAddedGeojson> => {
  const startDate = moment.utc().subtract(6, 'h').format(ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE);
  return axiosStaticV2
    .get<{ data: RecentlyAddedGeojson }>('zones/geojson', { params: { startDate, types: 'port' } })
    .then(data => data.data);
};

const getAnchorageGeojson = (): Promise<RecentlyAddedGeojson> => {
  const startDate = moment.utc().subtract(6, 'h').format(ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE);
  return axiosStaticV2
    .get<{ data: RecentlyAddedGeojson }>('zones/geojson', {
      params: { startDate, types: 'anchorage' },
    })
    .then(data => data.data);
};

const getStorageZoneGeojson = (): Promise<RecentlyAddedGeojson> => {
  return axiosStaticV2
    .get<{ data: RecentlyAddedGeojson }>('zones/geojson', { params: { types: 'storage' } })
    .then(data => data.data);
};

type ZoneAliasCreate = Omit<ZoneAlias, 'id'>;

const createZoneAlias = (
  resourceId: string,
  zoneAliases: ZoneAliasCreate[],
  resourceType: ResourceType.ZONE | ResourceType.INSTALLATION,
): Promise<CreateApiResponse> =>
  axiosStaticV2.post<CreateApiResponse>(`${resourceType}s/${resourceId}/aliases`, zoneAliases);

const updateZoneAlias = (zoneAlias: ZoneAlias): Promise<EditApiResponse> =>
  axiosStaticV2.patch<EditApiResponse>(`zone-aliases/${zoneAlias.id}`, {
    isFormerName: zoneAlias.isFormerName,
  });

const deleteZoneAliases = (aliasIds: string[] | number[]): Promise<DeleteApiResponse> =>
  axiosStaticV2.delete<DeleteApiResponse>(`zone-aliases`, { params: { ids: aliasIds.join(',') } });

const getInstallations = (zoneId: string | number) =>
  axiosApi.get<InstallationDetailsPayload[]>(`zones/${zoneId}/installations`);

const getInstallationIds = (zoneId: string | number) =>
  axiosApi.get<Array<{ id: number }>>(`zones/${zoneId}/installations`);

export default {
  getZone,
  getZones,
  getAnchorages,
  getZonesWithShapes,
  getInstallations,
  getInstallationIds,
  getStorageZones,
  getZoneFromStaticData,
  getZoneConstraints,
  getLinkedItems,
  editZone,
  getPortGeojson,
  getAnchorageGeojson,
  getStorageZoneGeojson,
  createZone,
  createZoneAlias,
  deleteZone,
  updateZoneAlias,
  deleteZoneAliases,
};
