import { CascaderOption } from '@kpler/web-ui';

import {
  ChartOptions,
  FilterValues,
  MarginsSeriesColumns,
  MarginsSeriesDataset,
  PopoutKeyLabel,
  Products,
  RefineriesVisualisationZIndex,
  RefineriesVisualizationDataType,
  RefineriesVisualizationName,
  RefineriesVisualizationSeries,
  RefineryStatisticsMetric,
  RUNS_QUALITIES,
  SecondaryUnitFeedInputMetric,
  SplitsPresets,
  WeightedAverageSeriesConfig,
  WeightedAverageSeriesName,
  ProductPresets,
} from 'src/domains/refineries/types';

import { getRowAs } from 'src/components/table/table.helper';

import { Granularity, TableConfig } from 'types/legacy-globals';
import { TemporalUnitName, UnitName, UnmanagedUnitName } from 'types/unit';

type TableRow = {
  title: string;
  key: RefineryStatisticsMetric;
  color: string;
};
export const ORDERED_TABLE_ROWS = Object.freeze<TableRow[]>([
  {
    title: 'New Build Refineries',
    key: RefineryStatisticsMetric.NEW_BUILD_REFINERIES,
    color: '#3997E3',
  },
  {
    title: 'Operational refineries',
    key: RefineryStatisticsMetric.OPERATION_REFINERIES,
    color: '#50c391',
  },
  {
    title: 'Closed refineries',
    key: RefineryStatisticsMetric.SHUTTERED_REFINERIES,
    color: '#ffc24e',
  },
  { title: 'Average NCI', key: RefineryStatisticsMetric.AVERAGE_NCI, color: 'white' },
  { title: 'Active units', key: RefineryStatisticsMetric.ACTICE_UNITS, color: 'white' },
  { title: 'Events on period', key: RefineryStatisticsMetric.OFFLINE_EVENTS, color: 'white' },
  { title: 'Average age', key: RefineryStatisticsMetric.AVERAGE_AGE, color: 'white' },
]);

export const REFINERY_MIN_DATE = '2010-01-01';

const getRow = getRowAs<Record<string, string>>;

export const OVERVIEW_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'refineryName',
    label: 'Refinery name',
    accessor: x => getRow(x).refineryName,
    headerClass: 'column',
    cellClass: 'column',
  },
  {
    key: 'owner',
    label: 'Owner',
    accessor: x => getRow(x).owner,
    headerClass: 'column',
    cellClass: 'column',
  },
  {
    key: 'age',
    label: 'Age (years)',
    accessor: x => getRow(x).age,
    headerClass: 'max-width-column',
    cellClass: 'max-width-column',
  },
  {
    key: 'nci',
    label: 'NCI',
    accessor: x => getRow(x).nci,
    headerClass: 'max-width-column',
    cellClass: 'max-width-column',
  },
  {
    key: 'type',
    label: 'Type',
    accessor: x => getRow(x).type,
    headerClass: 'max-width-column',
    cellClass: 'max-width-column',
  },
  {
    key: 'status',
    label: 'Status',
    accessor: x => getRow(x).status,
    headerClass: 'max-width-column',
    cellClass: 'max-width-column',
  },
  {
    key: 'country',
    label: 'Country',
    accessor: x => getRow(x).country,
    headerClass: 'column',
    cellClass: 'column',
  },
  {
    key: 'state',
    label: 'State',
    accessor: x => getRow(x).state,
    headerClass: 'column',
    cellClass: 'column',
  },
  {
    key: 'startup',
    label: 'Startup',
    accessor: x => getRow(x).startup,
    headerClass: 'max-width-column',
    cellClass: 'max-width-column',
  },
  {
    key: 'shutdown',
    label: 'Shutdown',
    accessor: x => getRow(x).shutdown,
    headerClass: 'max-width-column',
    cellClass: 'max-width-column',
  },
  {
    key: 'eventsAvgDuration',
    label: 'Events average duration',
    accessor: x => getRow(x).eventsAvgDuration,
    headerClass: 'max-width-column',
    cellClass: 'max-width-column',
  },
  {
    key: 'planedOverPeriod',
    label: 'Planned (%)',
    accessor: x => getRow(x).planedOverPeriod,
    headerClass: 'max-width-column',
    cellClass: 'max-width-column',
  },
  {
    key: 'unplannedOverPeriod',
    label: 'Unplanned (%)',
    accessor: x => getRow(x).planedOverPeriod,
    headerClass: 'max-width-column',
    cellClass: 'max-width-column',
  },
];

export const SERIES_VISUALIZATION_CONFIG: RefineriesVisualizationSeries = {
  refineriesName: RefineriesVisualizationName.REFINERIES,
  rangesName: RefineriesVisualizationName.RANGES,
  singleZIndex: RefineriesVisualisationZIndex.SINGLE,
  zeroZIndex: RefineriesVisualisationZIndex.ZERO,
  averagesDataType: RefineriesVisualizationDataType.AVERAGES,
  rangesDataType: RefineriesVisualizationDataType.RANGES,
  type: 'arearange',
  marker: {
    fillColor: 'white',
    lineWidth: 2,
    enabled: false,
    lineColor: '#56BC71',
  },
  lineWidth: 0,
  linkedTo: ':previous',
  color: '#515D68',
  fillOpacity: 0.3,
};

export const REFINERY_METRICS_CONFIG = [
  {
    refineryName: '',
    owner: '',
    age: '',
    nci: '',
    type: '',
    status: '',
    startup: '',
    shutdown: '',
    eventsAvgDuration: '',
    planedOverPeriod: '',
    unplannedOverPeriod: '',
  },
];

export const WEIGHTED_AVERAGE_SERIES_CONFIG: WeightedAverageSeriesConfig = {
  apiName: WeightedAverageSeriesName.API,
  sulfurName: WeightedAverageSeriesName.SULFUR,
  zoneAxis: 'x',
  yAxis: 1,
};

export const WEIGHTED_AVERAGE_CHART_OPTIONS: ChartOptions = {
  chart: {
    width: 1000,
  },
  legend: {
    enabled: false,
    verticalAlign: 'top',
  },
  yAxis: {
    // Primary yAxis
    title: {
      align: 'high',
      rotation: 0,
      skew3d: true,
      offset: 0,
    },
    opposite: true,
    gridLineWidth: 0,
    lineWidth: 1,
    apiLineColor: '#0A315A',
    sulfurLinecolor: '#96DBFF',
    axisColor: '#dddddd',
  },
};

export const UTILIZATION_RATE_CHART_CONFIG = {
  name: 'Utilization rate',
  zoneAxis: 'x',
};
export const CURRENT_SPLITS_LABELS = Object.freeze([
  { id: SplitsPresets.TOTAL, label: 'Total' },
  { id: SplitsPresets.CRUDE_QUALITIES, label: 'Crude Quality' },
  { id: SplitsPresets.GRADES, label: 'Crude Grades' },
  { id: SplitsPresets.REFINERY_TYPE, label: 'Refinery Type' },
  { id: SplitsPresets.REFINERY, label: 'Refinery' },
  { id: SplitsPresets.COUNTRY, label: 'Country' },
  { id: SplitsPresets.SUBCONTINENT, label: 'Subcontinent' },
  { id: SplitsPresets.CONTINENT, label: 'Continent' },
  { id: SplitsPresets.TRADING_REGIONS, label: 'Trading Regions' },
  { id: SplitsPresets.CRUDE_PRODUCTION_REGIONS, label: 'Crude Production Regions' },
]);

export const REFINED_PRODUCTS_CURRENT_SPLITS_LABELS = Object.freeze([
  { id: SplitsPresets.TOTAL, label: 'Total' },
  { id: SplitsPresets.PRODUCT, label: 'Product' },
  { id: SplitsPresets.GRADES, label: 'Grades' },
  { id: SplitsPresets.REFINERY_TYPE, label: 'Refinery Type' },
  { id: SplitsPresets.REFINERY, label: 'Refinery' },
  { id: SplitsPresets.COUNTRY, label: 'Country' },
  { id: SplitsPresets.SUBCONTINENT, label: 'Subcontinent' },
  { id: SplitsPresets.CONTINENT, label: 'Continent' },
  { id: SplitsPresets.TRADING_REGIONS, label: 'Trading Regions' },
]);

export const GRANULARITY_OPTIONS = Object.freeze([
  { id: Granularity.DAYS, name: 'D' },
  { id: Granularity.EIAS, name: 'EIA' },
  { id: Granularity.WEEKS, name: 'W' },
  { id: Granularity.MONTHS, name: 'M' },
  { id: Granularity.YEARS, name: 'Y' },
]);
export const UNIT_OPTIONS = [
  { id: TemporalUnitName.KBD, name: 'kbd' },
  { id: UnitName.KB, name: 'kb' },
  { id: UnitName.MMBBL, name: 'Mbbl' },
  { id: UnitName.CM, name: 'm3' },
];
export const UNIT_OPTIONS_REFINED_PRODUCTS = [
  { id: TemporalUnitName.KBD, name: 'kbd' },
  { id: UnitName.KB, name: 'kb' },
  { id: UnitName.MMBBL, name: 'Mbbl' },
  { id: UnitName.CM, name: 'm3' },
  { id: UnmanagedUnitName.PERCENTAGE, name: UnmanagedUnitName.PERCENTAGE },
  { id: UnitName.KTONS, name: 'kt' },
  { id: UnitName.MTONS, name: 'Mt' },
];
export const CURRENT_PRODUCTS_LABELS = Object.freeze([
  { id: Products.LPG, label: 'LPG', groupId: 'Top of the Barrel' },
  { id: Products.NAPHTHA, label: 'Naphtha', groupId: 'Top of the Barrel' },
  { id: Products.GASOLINE, label: 'Gasoline', groupId: 'Top of the Barrel' },
  { id: Products.JET, label: 'Kero', groupId: 'Middle of the Barrel' },
  {
    id: Products.DIESEL_HEATING_FUEL_OIL,
    label: 'Diesel/ Heating Fuel Oil',
    groupId: 'Middle of the Barrel',
  },
  { id: Products.VGO_RANGE, label: 'VGO', groupId: 'Bottom of the Barrel' },
  { id: Products.SLURRY, label: 'Slurry', groupId: 'Bottom of the Barrel' },
  { id: Products.RESIDUAL_FUEL, label: 'Residual Fuel', groupId: 'Bottom of the Barrel' },
  { id: Products.PETCOKE, label: 'Petcoke', groupId: 'Bottom of the Barrel' },
  { id: Products.LUBE_OIL, label: 'Petcoke', groupId: 'Bottom of the Barrel' },
  { id: Products.ASPHALT_BITUMEN, label: 'Asphalt & Bitumen', groupId: 'Bottom of the Barrel' },
]);

export const UNIT_FEED_INPUT_ORDERED_ROWS = [
  {
    title: 'Catalytic reforming',
    key: SecondaryUnitFeedInputMetric.CATALYTIC_REFORMING,
  },
  {
    title: 'Catalytic cracking',
    key: SecondaryUnitFeedInputMetric.CATALYTIC_CRACKING,
  },
  {
    title: 'Cat. hydrocracking',
    key: SecondaryUnitFeedInputMetric.CAT_HYDROCRACKING,
  },
  {
    title: 'Coking',
    key: SecondaryUnitFeedInputMetric.COKING,
  },
];

export const UNIT_FEED_INPUT_API_ORDERED_ROWS = [
  {
    title: 'Catalytic reforming',
    key: SecondaryUnitFeedInputMetric.CATALYTIC_REFORMING,
  },
  {
    title: 'Catalytic cracking',
    key: SecondaryUnitFeedInputMetric.CATALYTIC_CRACKING,
  },
  {
    title: 'Cat. hydrocracking',
    key: SecondaryUnitFeedInputMetric.CAT_HYDROCRACKING,
  },
  {
    title: 'Coking',
    key: SecondaryUnitFeedInputMetric.COKING,
  },
];

export const SECONDARY_UNIT_FEED_INPUT_GRANULARITY_OPTIONS = Object.freeze([
  { id: Granularity.DAYS, name: 'D' },
  { id: Granularity.EIAS, name: 'EIA' },
  { id: Granularity.WEEKS, name: 'W' },
  { id: Granularity.MONTHS, name: 'M' },
  { id: Granularity.YEARS, name: 'Y' },
]);

export const SECONDARY_UNIT_FEED_INPUT_SPLITS_LABELS = Object.freeze([
  { id: SplitsPresets.TOTAL, label: 'Total' },
  { id: SplitsPresets.REFINERY_TYPE, label: 'Refinery type' },
  { id: SplitsPresets.REFINERY, label: 'Refinery' },
  { id: SplitsPresets.COUNTRY, label: 'Country' },
  { id: SplitsPresets.SUBCONTINENT, label: 'Subcontinent' },
  { id: SplitsPresets.CONTINENT, label: 'Continent' },
  { id: SplitsPresets.TRADING_REGIONS, label: 'Trading regions' },
]);

export const POPOUT_PRODUCTS_KEY_LABEL: PopoutKeyLabel = [
  {
    key: Products.TOP_OF_THE_BARREL,
    label: 'Top of the barrel',
  },
  { key: Products.LPG, label: 'LPG' },
  {
    key: Products.NAPHTHA,
    label: 'Naphtha',
  },
  {
    key: Products.GASOLINE,
    label: 'Gasoline',
  },
  {
    key: Products.MIDDLE_OF_THE_BARREL,
    label: 'Middle of the barrel',
  },
  {
    key: Products.JET,
    label: 'Jet',
  },
  {
    key: Products.DIESEL_HEATING_FUEL_OIL,
    label: 'Diesel/heating fuel oil',
  },
  { key: Products.LOW_SULFUR_DIESEL, label: 'Low sulfur diesel' },
  { key: Products.HIGH_SULFUR_DIESEL, label: 'High sulfur diesel' },
  { key: Products.BOTTOM_OF_THE_BARREL, label: 'Bottom of the barrel' },
  { key: Products.VGO_RANGE, label: 'VGO' },
  { key: Products.SLURRY, label: 'Slurry' },
  { key: Products.RESIDUAL_FUEL, label: 'Residual fuel' },
  { key: Products.LOW_SULFUR_FUEL_OIL, label: 'Low sulfur fuel oil' },
  { key: Products.HIGH_SULFUR_FUEL_OIL, label: 'High sulfur fuel oil' },
  { key: Products.PETCOKE, label: 'Petcoke' },
  { key: Products.LUBE_OIL, label: 'Lube oil' },
  { key: Products.ASPHALT_BITUMEN, label: 'Asphalt & bitumen' },
  { key: Products.OLEFINS, label: 'Olefins' },
];

export const POPOUT_PRODUCT_VALUES: FilterValues<Products> = {
  [Products.TOP_OF_THE_BARREL]: { isParent: true, selected: false, parent: undefined },
  [Products.LPG]: { isParent: false, selected: false, parent: Products.TOP_OF_THE_BARREL },
  [Products.NAPHTHA]: { isParent: false, selected: false, parent: Products.TOP_OF_THE_BARREL },
  [Products.GASOLINE]: { isParent: false, selected: false, parent: Products.TOP_OF_THE_BARREL },
  [Products.MIDDLE_OF_THE_BARREL]: { isParent: true, selected: false, parent: undefined },
  [Products.JET]: { isParent: false, selected: false, parent: Products.MIDDLE_OF_THE_BARREL },
  [Products.DIESEL_HEATING_FUEL_OIL]: {
    isParent: false,
    selected: false,
    parent: Products.MIDDLE_OF_THE_BARREL,
  },
  [Products.LOW_SULFUR_DIESEL]: {
    isParent: false,
    selected: false,
    isInnerChild: true,
    parent: Products.DIESEL_HEATING_FUEL_OIL,
  },
  [Products.HIGH_SULFUR_DIESEL]: {
    isParent: false,
    isInnerChild: true,
    selected: false,
    parent: Products.DIESEL_HEATING_FUEL_OIL,
  },
  [Products.BOTTOM_OF_THE_BARREL]: { isParent: true, selected: false, parent: undefined },
  [Products.VGO_RANGE]: { isParent: false, selected: false, parent: Products.BOTTOM_OF_THE_BARREL },
  [Products.SLURRY]: { isParent: false, selected: false, parent: Products.BOTTOM_OF_THE_BARREL },
  [Products.RESIDUAL_FUEL]: {
    isParent: false,
    selected: false,
    parent: Products.BOTTOM_OF_THE_BARREL,
  },
  [Products.LOW_SULFUR_FUEL_OIL]: {
    isParent: false,
    selected: false,
    isInnerChild: true,
    parent: Products.RESIDUAL_FUEL,
  },
  [Products.HIGH_SULFUR_FUEL_OIL]: {
    isParent: false,
    selected: false,
    isInnerChild: true,
    parent: Products.RESIDUAL_FUEL,
  },

  [Products.PETCOKE]: { isParent: false, selected: false, parent: Products.BOTTOM_OF_THE_BARREL },
  [Products.LUBE_OIL]: { isParent: false, selected: false, parent: Products.BOTTOM_OF_THE_BARREL },
  [Products.ASPHALT_BITUMEN]: {
    isParent: false,
    selected: false,
    parent: Products.BOTTOM_OF_THE_BARREL,
  },
  [Products.OLEFINS]: { isParent: true, selected: false, parent: undefined },
};
export const TIMESERIES_UNITS: {
  catalyticReforming: string;
  catalyticCracking: string;
  catHydrocracking: string;
  coking: string;
} = {
  [SecondaryUnitFeedInputMetric.CATALYTIC_REFORMING]: 'Reformer',
  [SecondaryUnitFeedInputMetric.CATALYTIC_CRACKING]: 'FCC',
  [SecondaryUnitFeedInputMetric.CAT_HYDROCRACKING]: 'Distillate hydrocracker',
  [SecondaryUnitFeedInputMetric.COKING]: 'Coker',
};

export const MARGINS_SERIES: {
  plantCountry: string;
  state: string;
  status: string;
  refineryName: string;
  nci: string;
  type: string;
  owner: string;
  count: string;
} = {
  [MarginsSeriesColumns.COUNT]: 'Count',
  [MarginsSeriesColumns.COUNTRY]: 'Country',
  [MarginsSeriesColumns.NCI]: 'NCI',
  [MarginsSeriesColumns.STATE]: 'State',
  [MarginsSeriesColumns.TYPE]: 'Type',
  [MarginsSeriesColumns.STATUS]: 'Status',
  [MarginsSeriesColumns.OWNER]: 'Owner',
  [MarginsSeriesColumns.NAME]: 'Refinery name',
};

export const MARGINS_REGIONAL_VALUE_LABELS = Object.freeze([
  { id: SplitsPresets.COUNTRY, label: 'Country' },
  { id: SplitsPresets.SUBCONTINENT, label: 'Subcontinent' },
  { id: SplitsPresets.CONTINENT, label: 'Continent' },
  { id: SplitsPresets.TRADING_REGIONS, label: 'Trading regions' },
]);

export const MARGINS_SPLIT_VALUE_LABELS = Object.freeze([
  { id: SplitsPresets.TOTAL, label: 'Total' },
  { id: SplitsPresets.REFINERY_TYPE, label: 'Refinery type' },
  ...MARGINS_REGIONAL_VALUE_LABELS,
]);

export const MARGINS_LABELS = Object.freeze([
  ...MARGINS_SPLIT_VALUE_LABELS,
  { id: SplitsPresets.REFINERY, label: 'Refinery' },
]);

export const AGGREGATE_DATA_TYPE = 'aggregate';
export const FULL_DATA_TYPE = 'full';

export const MARGINS_CHART_OPTIONS: ChartOptions = {
  xAxis: {
    tickmarkPlacement: 'between',
    labels: {
      rotation: 0,
      overflow: 'allow',
    },
  },
  tooltip: {
    shared: true,
    valueDecimals: 2,
  },
  legend: {
    enabled: false,
    verticalAlign: 'top',
    align: 'center',
  },
  yAxis: {
    // Primary yAxis
    title: {
      text: '$/bbl',
      align: 'high',
      rotation: 0,
      skew3d: true,
      offset: 0,
    },
    gridLineWidth: 0,
    lineWidth: 1,
    axisColor: '#dddddd',
  },
};
export const REFINED_PRODUCTS_CASCADER_OPTIONS: CascaderOption[] = [
  {
    id: Products.TOP_OF_THE_BARREL,
    label: Products.TOP_OF_THE_BARREL,
    disabled: false,
  },
  {
    id: Products.LPG,
    label: Products.LPG,
    disabled: false,
    parentId: Products.TOP_OF_THE_BARREL,
  },
  {
    id: Products.NAPHTHA,
    label: Products.NAPHTHA,
    disabled: false,
    parentId: Products.TOP_OF_THE_BARREL,
  },
  {
    id: Products.GASOLINE,
    label: Products.GASOLINE,
    disabled: false,
    parentId: Products.TOP_OF_THE_BARREL,
  },
  {
    id: Products.MIDDLE_OF_THE_BARREL,
    label: Products.MIDDLE_OF_THE_BARREL,
    disabled: false,
  },
  {
    id: Products.JET,
    label: Products.JET,
    disabled: false,
    parentId: Products.MIDDLE_OF_THE_BARREL,
  },
  {
    id: Products.DIESEL_HEATING_FUEL_OIL,
    label: Products.DIESEL_HEATING_FUEL_OIL,
    disabled: false,
    parentId: Products.MIDDLE_OF_THE_BARREL,
  },
  {
    id: Products.LOW_SULFUR_DIESEL,
    label: Products.LOW_SULFUR_DIESEL,
    disabled: false,
    parentId: Products.DIESEL_HEATING_FUEL_OIL,
  },
  {
    id: Products.HIGH_SULFUR_DIESEL,
    label: Products.HIGH_SULFUR_DIESEL,
    disabled: false,
    parentId: Products.DIESEL_HEATING_FUEL_OIL,
  },
  {
    id: Products.BOTTOM_OF_THE_BARREL,
    label: Products.BOTTOM_OF_THE_BARREL,
    disabled: false,
  },
  {
    id: Products.VGO_RANGE,
    label: Products.VGO_RANGE,
    disabled: false,
    parentId: Products.BOTTOM_OF_THE_BARREL,
  },
  {
    id: Products.SLURRY,
    label: Products.SLURRY,
    disabled: false,
    parentId: Products.BOTTOM_OF_THE_BARREL,
  },
  {
    id: Products.RESIDUAL_FUEL,
    label: Products.RESIDUAL_FUEL,
    disabled: false,
    parentId: Products.BOTTOM_OF_THE_BARREL,
  },
  {
    id: Products.LOW_SULFUR_FUEL_OIL,
    label: Products.LOW_SULFUR_FUEL_OIL,
    disabled: false,
    parentId: Products.RESIDUAL_FUEL,
  },
  {
    id: Products.HIGH_SULFUR_FUEL_OIL,
    label: Products.HIGH_SULFUR_FUEL_OIL,
    disabled: false,
    parentId: Products.RESIDUAL_FUEL,
  },
  {
    id: Products.PETCOKE,
    label: Products.PETCOKE,
    disabled: false,
    parentId: Products.BOTTOM_OF_THE_BARREL,
  },
  {
    id: Products.LUBE_OIL,
    label: Products.LUBE_OIL,
    disabled: false,
    parentId: Products.BOTTOM_OF_THE_BARREL,
  },
  {
    id: Products.ASPHALT_BITUMEN,
    label: Products.ASPHALT_BITUMEN,
    disabled: false,
    parentId: Products.BOTTOM_OF_THE_BARREL,
  },
  {
    id: Products.OLEFINS,
    label: Products.OLEFINS,
    disabled: false,
  },
];
export const RUNS_PRODUCTS_CASCADER_OPTIONS: CascaderOption[] = [
  {
    id: RUNS_QUALITIES.CONDENSATE,
    label: RUNS_QUALITIES.CONDENSATE,
    disabled: false,
  },
  {
    id: RUNS_QUALITIES.LIGHT,
    label: RUNS_QUALITIES.LIGHT,
    disabled: false,
  },
  {
    id: RUNS_QUALITIES.LIGHT_SWEET,
    label: RUNS_QUALITIES.LIGHT_SWEET,
    disabled: false,
    parentId: RUNS_QUALITIES.LIGHT,
  },
  {
    id: RUNS_QUALITIES.LIGHT_SOUR,
    label: RUNS_QUALITIES.LIGHT_SOUR,
    disabled: false,
    parentId: RUNS_QUALITIES.LIGHT,
  },
  {
    id: RUNS_QUALITIES.MEDIUM,
    label: RUNS_QUALITIES.MEDIUM,
    disabled: false,
  },
  {
    id: RUNS_QUALITIES.MEDIUM_SWEET,
    label: RUNS_QUALITIES.MEDIUM_SWEET,
    disabled: false,
    parentId: RUNS_QUALITIES.MEDIUM,
  },
  {
    id: RUNS_QUALITIES.MEDIUM_SOUR,
    label: RUNS_QUALITIES.MEDIUM_SOUR,
    disabled: false,
    parentId: RUNS_QUALITIES.MEDIUM,
  },
  {
    id: RUNS_QUALITIES.HEAVY,
    label: RUNS_QUALITIES.HEAVY,
    disabled: false,
  },
  {
    id: RUNS_QUALITIES.HEAVY_SWEET,
    label: RUNS_QUALITIES.HEAVY_SWEET,
    disabled: false,
    parentId: RUNS_QUALITIES.HEAVY,
  },
  {
    id: RUNS_QUALITIES.HEAVY_SOUR,
    label: RUNS_QUALITIES.HEAVY_SOUR,
    disabled: false,
    parentId: RUNS_QUALITIES.HEAVY,
  },
  {
    id: RUNS_QUALITIES.SWEET,
    label: RUNS_QUALITIES.SWEET,
    disabled: false,
  },
  {
    id: RUNS_QUALITIES.SOUR,
    label: RUNS_QUALITIES.SOUR,
    disabled: false,
  },
  {
    id: RUNS_QUALITIES.UNSPECIFIED,
    label: RUNS_QUALITIES.UNSPECIFIED,
    disabled: false,
  },
];
export const INITIAL_MARGINS_SERIES_DATASET: MarginsSeriesDataset = {
  series: [
    {
      day: 'N/A',
      min: 0,
      max: 0,
      average: 0,
    },
  ],
  dataset: [
    {
      plantCountry: '',
      state: '',
      status: '',
      refineryName: '',
      nci: 0,
      type: '',
      owner: '',
      count: 0,
      splitValue: '',
      simpleRefineries: 0,
      complexRefineries: 0,
      mediumRefineries: 0,
      otherRefineries: 0,
      grossMargins: [
        {
          day: '',
          average: 0,
        },
      ],
    },
  ],
};

export const FEEDSTOCK_SPLITS_LABEL = Object.freeze([
  ...SECONDARY_UNIT_FEED_INPUT_SPLITS_LABELS,
  { id: SplitsPresets.PRODUCT, label: 'Product' },
]);
export const FEEDSTOCK_PRODUCTS_LABEL = Object.freeze([
  { id: ProductPresets.HSFO, label: 'HSFO' },
  { id: ProductPresets.SRFO, label: 'SRFO' },
  { id: ProductPresets.VGO, label: 'VGO' },
]);
