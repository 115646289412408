import { AuthServiceInterface } from 'src/core/auth/AuthService.interface';

import { assertDefined } from 'src/helpers/assertDefined.helper';

export const authService: { current: AuthServiceInterface | null } = {
  current: null,
};

export const getAuthService = (): AuthServiceInterface => {
  assertDefined(authService.current, 'AuthService is not defined');
  return authService.current;
};
