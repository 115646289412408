/* eslint-disable max-classes-per-file */
import { Moment } from 'moment';

import { AxiosErrorWithMessage } from 'src/services/axios.service';

export class RoutingError extends Error {
  name = 'RoutingError';
}

export class DataError extends Error {
  name = 'DataError';
}

export class NotANumberError extends Error {
  name = 'NotANumberError';

  constructor(value: string) {
    super(`${value} isn't a number.`);
  }
}

export class AuthorizationError extends Error {
  name = 'AuthorizationError';
}

export class ForbiddenError extends Error {
  name = 'ForbiddenError';
}

export class AnalystEditError extends Error {
  name = 'AnalystEditError';

  constructor(err: AxiosErrorWithMessage) {
    let message = err.response?.data || 'No message';
    if (typeof message === 'object') {
      message = JSON.stringify(message);
    }
    super(message);
    this.name = err.response?.statusText || this.name;
  }
}

export class FlaskV2Error extends Error {
  constructor(err: Error) {
    const errors = JSON.parse(err.message).errors;
    let message = 'No message, please contact AT team :)';
    if (errors.length > 0) {
      message = errors.map((e: any) => e.message).join('\n');
    }
    super(message);
    this.name = err.name;
  }
}

export class InvalidMomentDateError extends Error {
  name = 'InvalidMomentDateError';

  constructor(public date: Moment) {
    super('This moment date is not valid');
  }
}

export class MomentParseError extends Error {
  name = 'MomentParseError';

  constructor(
    public date: string,
    public format: string,
  ) {
    super(`Can't parse date "${date}" for format "${format}"`);
  }
}

export class NotImplementedError extends Error {
  name = 'NotImplementedError';

  constructor(context?: string) {
    super(context ? 'Not implemented.' : `Not implemented: ${context}.`);
  }
}

export class UnexpectedValueError extends Error {
  name = 'UnexpectedValueError';

  constructor(value: unknown) {
    super(`Unexpected value: ${value}.`);
  }
}
