import { isDefined } from '@kpler/web-ui';
import { Expression } from 'mapbox-gl';

import {
  FUEL_TYPE_COLORS,
  POWER_INSTALLATIONS_COLORS_CONFIG,
  PowerInstallationLegend,
  STATUS_COLORS,
  POWER_PERMISSIONS,
  RENEWABLE_FUEL_TYPE_COLORS,
} from 'src/domains/power/constants';
import store from 'src/store';

import {
  BiddingZonePriceRangeGradient,
  FUEL,
  Fuel,
  OutageStatus,
  OutageStatusColor,
  PowerTerminalFuelTypeColor,
  RENEWABLE_FUEL,
  RenewableFuel,
} from './enums';

import { assertDefined } from 'src/helpers/assertDefined.helper';
import { serializeDate, toMoment } from 'src/helpers/date.helper';

import { SerializedDateRange } from 'types/dates';

export const hasPermission = () => store.getters.userHasPermission(POWER_PERMISSIONS.OUTAGES);
export const hasForecastsExplorerPermission = () =>
  store.getters.userHasPermission(POWER_PERMISSIONS.FORECASTS_EXPLORER);

/**
 * Create a composite mapbox expression zoom+data
 * @see https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#composition
 */
export const buildPowerTerminalRadiusExpression = (): Expression => {
  const ZERO_CAPACITY_CONDITION = ['==', ['get', 'capacity'], 0];
  const MIN_ZOOM = 0;
  const MIN_RADIUS: Expression = [
    'case',
    ZERO_CAPACITY_CONDITION,
    2.5,
    ['max', 5, ['*', 0.001, ['get', 'capacity']]],
  ];
  const MAX_ZOOM = 10;
  const MAX_RADIUS: Expression = [
    'case',
    ZERO_CAPACITY_CONDITION,
    2.5,
    ['max', 4, ['*', 0.01, ['get', 'capacity']]],
  ];
  return ['interpolate', ['linear'], ['zoom'], MIN_ZOOM, MIN_RADIUS, MAX_ZOOM, MAX_RADIUS];
};

export const buildPowerColorsMapboxExpression = (
  powerLegend: PowerInstallationLegend,
): Expression => {
  const { defaultColor, colors, property } = POWER_INSTALLATIONS_COLORS_CONFIG[powerLegend];
  const flattenColors = Array.from(colors).flat();

  return ['match', ['get', property], ...flattenColors, defaultColor];
};

export const buildPowerBiddingZoneColorExpression = (
  min: number | undefined,
  max: number | undefined,
): Expression | undefined => {
  if (!isDefined(min) || !isDefined(max)) {
    return undefined;
  }

  const PRICE_NOT_NULL_CONDITION = ['!=', ['get', 'price'], null];
  const PRICE_POSITIVE_CONDITION = ['>', ['get', 'price'], 0];

  const COLOR_INTERPOLATE_EXPRESSION = [
    'interpolate',
    ['linear'],
    ['get', 'price'],
    min,
    BiddingZonePriceRangeGradient.GREEN,
    min + (max - min) * 0.33,
    BiddingZonePriceRangeGradient.YELLOW,
    min + (max - min) * 0.66,
    BiddingZonePriceRangeGradient.ORANGE,
    max,
    BiddingZonePriceRangeGradient.RED,
  ];

  if (min <= 0) {
    return [
      'case',
      PRICE_NOT_NULL_CONDITION,
      [
        'case',
        PRICE_POSITIVE_CONDITION,
        COLOR_INTERPOLATE_EXPRESSION,
        BiddingZonePriceRangeGradient.BLUE,
      ],
      '#fff',
    ];
  }

  return ['case', PRICE_NOT_NULL_CONDITION, COLOR_INTERPOLATE_EXPRESSION, '#fff'];
};

export const buildPowerBiddingZoneOpacityExpression = (): Expression => {
  return ['case', ['!=', ['get', 'price'], null], 0.8, 0];
};

export const getPowerTerminalFuelTypeColor = (id: Fuel): PowerTerminalFuelTypeColor => {
  const color = FUEL_TYPE_COLORS.get(id);
  assertDefined(color);
  return color;
};

export const getPowerTerminalRenewablesFuelTypeColor = (
  id: RenewableFuel,
): PowerTerminalFuelTypeColor => {
  const color = RENEWABLE_FUEL_TYPE_COLORS.get(id);
  assertDefined(color);
  return color;
};

export const getOutageColor = (id: OutageStatus): OutageStatusColor => {
  const color = STATUS_COLORS.get(id);
  assertDefined(color);
  return color;
};

export const getSeasonalDateRange = (): SerializedDateRange => {
  return {
    startDate: serializeDate(toMoment().subtract(5, 'year').startOf('year')),
    endDate: serializeDate(toMoment().endOf('year').add(1, 'day')),
  };
};

export const convertRenewableToFuel = (renewableFuels: readonly RenewableFuel[]): Fuel[] => {
  return renewableFuels.flatMap(fuel => {
    switch (fuel) {
      case RENEWABLE_FUEL.WIND:
        return [FUEL.WIND_ONSHORE, FUEL.WIND_OFFSHORE];
      case RENEWABLE_FUEL.SOLAR:
        return [FUEL.SOLAR];
      default:
        return [];
    }
  });
};

export const convertFuelToRenewable = (fuels: readonly Fuel[]): RenewableFuel[] => {
  return [
    ...new Set(
      fuels.flatMap(fuel => {
        switch (fuel) {
          case FUEL.SOLAR:
            return [RENEWABLE_FUEL.SOLAR];
          case FUEL.WIND_ONSHORE:
          case FUEL.WIND_OFFSHORE:
            return [RENEWABLE_FUEL.WIND];
          default:
            return [];
        }
      }),
    ),
  ];
};
