import { isDefined } from 'src/helpers/isDefined.helper';

import { Platform } from 'types/legacy-globals';

type PlatformGetter = () => Platform;

export class PlatformService {
  static readonly INJECT_KEY = 'platformService';
  static #singleton: PlatformService;

  #platformGetter: PlatformGetter;

  static create(platformGetter: PlatformGetter) {
    if (!isDefined(PlatformService.#singleton)) {
      PlatformService.#singleton = new PlatformService(platformGetter);
    }

    return PlatformService.#singleton;
  }

  private constructor(platformGetter: PlatformGetter) {
    this.#platformGetter = platformGetter;
  }

  get injectKey() {
    return PlatformService.INJECT_KEY;
  }

  get platform() {
    return this.#platformGetter();
  }

  get isLng() {
    return this.platform === Platform.LNG;
  }

  get isLpg() {
    return this.platform === Platform.LPG;
  }

  get isCommodities() {
    return this.platform === Platform.COMMODITIES;
  }

  get isDry() {
    return this.platform === Platform.DRY;
  }

  get isMerge() {
    return this.platform === Platform.MERGE;
  }
}
