import { RouteRecordRaw } from '@kpler/web-ui';

import { hasPermission, hasForecastsExplorerPermission } from './helpers';

import { isMerge } from 'src/helpers/platform.helper';
import { createKeepQueryParamsHook } from 'src/helpers/router.helper';

const ThePowerContainer = () => import(/* webpackChunkName: "power" */ './ThePowerContainer.vue');

const OutagesContainer = () =>
  hasPermission()
    ? import(/* webpackChunkName: "power" */ './OutagesContainer.vue')
    : import(/* webpackChunkName: "power" */ './OutagesContainerPreview.vue');

const OverviewTab = () =>
  hasPermission()
    ? import(/* webpackChunkName: "power" */ './OverviewTab/OverviewTab.vue')
    : import(/* webpackChunkName: "power" */ './OverviewTab/OverviewTabPreview.vue');

const OutagesTab = () =>
  hasPermission()
    ? import(/* webpackChunkName: "power" */ './OutagesTab/OutagesTab.vue')
    : import(/* webpackChunkName: "power" */ './OutagesTab/OutagesTabPreview.vue');

const OutagesDrawer = () =>
  import(/* webpackChunkName: "power" */ './OutagesTab/OutagesDrawer.vue');
const RampingRate = () => import(/* webpackChunkName: "power" */ './OutagesTab/RampingRate.vue');
const OutageVersions = () =>
  import(/* webpackChunkName: "power" */ './OutagesTab/OutageVersions.vue');

const Analytics = () =>
  hasPermission()
    ? import(/* webpackChunkName: "power" */ './Analytics/Analytics.vue')
    : import(/* webpackChunkName: "power" */ './Analytics/AnalyticsPreview.vue');

const Forecasts = () =>
  hasForecastsExplorerPermission()
    ? import(/* webpackChunkName: "power" */ './Forecasts/Forecasts.vue')
    : import(/* webpackChunkName: "power" */ './Forecasts/ForecastsPreview.vue');

const routes: RouteRecordRaw = {
  name: 'power',
  path: 'power',
  component: ThePowerContainer,
  meta: { title: 'Power' },
  redirect: '/power/overview',
  children: [
    {
      path: '',
      component: OutagesContainer,
      children: [
        {
          name: 'power-overview',
          path: 'overview',
          component: OverviewTab,
          beforeEnter: createKeepQueryParamsHook('/power'),
        },
        {
          name: 'power-outages',
          path: 'outages',
          component: OutagesTab,
          beforeEnter: createKeepQueryParamsHook('/power'),
          children: [
            {
              name: 'message',
              path: 'message/:id/:assetCode/:providerId',
              component: OutagesDrawer,
              beforeEnter: createKeepQueryParamsHook('/power'),
              children: [
                {
                  name: 'ramping-rate',
                  path: 'ramping-rate',
                  component: RampingRate,
                  beforeEnter: createKeepQueryParamsHook('/power'),
                },
                {
                  name: 'versions',
                  path: 'versions',
                  component: OutageVersions,
                  beforeEnter: createKeepQueryParamsHook('/power'),
                },
              ],
            },
          ],
        },
        ...(isMerge
          ? [
              {
                name: 'power-analytics-tab',
                path: 'analytics',
                component: Analytics,
                beforeEnter: createKeepQueryParamsHook('/power'),
              },
            ]
          : []),
        {
          name: 'power-forecasts-explorer',
          path: 'forecasts-explorer',
          component: Forecasts,
          beforeEnter: createKeepQueryParamsHook('/power'),
        },
      ],
    },
    {
      name: 'power-analytics',
      path: 'analytics',
      component: Analytics,
      beforeEnter: createKeepQueryParamsHook('/power'),
    },
  ],
};

export default routes;
