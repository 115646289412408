import { NotANumberError } from 'src/helpers/error.helper';

export const castToNumber = (str: string): number => {
  const parsed = Number(str);
  if (Number.isNaN(parsed)) {
    throw new NotANumberError(str);
  }
  return parsed;
};

export class NotAnIntegerError extends Error {
  name = 'NotAnIntegerError';
  constructor(given: number) {
    super(`Must be an integer number. Given: ${given}`);
  }
}

export const generateIntegerSequence = (firstInteger: number, lastInteger: number): number[] => {
  if (!Number.isInteger(firstInteger)) {
    throw new NotAnIntegerError(firstInteger);
  }
  if (!Number.isInteger(lastInteger)) {
    throw new NotAnIntegerError(lastInteger);
  }
  if (firstInteger > lastInteger) {
    throw new RangeError(
      `firstInteger must be lower than lastInteger. Given: ${firstInteger}, ${lastInteger}`,
    );
  }

  return Array.from({ length: lastInteger - firstInteger + 1 }, (_, i) => firstInteger + i);
};
export const roundAtDecimals = (value: number, decimal: number) => {
  const tenPowerDecimal = 10 ** decimal;
  return Math.round(value * tenPowerDecimal) / tenPowerDecimal;
};

export const roundAtHundreds = (value: number) => roundAtDecimals(value, 2);
