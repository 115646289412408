import { AnalyticsDataType } from 'types/analytics';
import { FreightMetricsMetric } from 'types/freightMetrics';
import { Platform } from 'types/legacy-globals';
import { TemporalUnitName, UnitName } from 'types/unit';

/** @deprecated obsolete after the merge release */
export const unitsByCommodity = {
  [AnalyticsDataType.FLOWS]: {
    [Platform.LNG]: [UnitName.CM, UnitName.KTONS, UnitName.MTONS],
    [Platform.LPG]: [
      UnitName.KTONS,
      UnitName.MTONS,
      UnitName.CM,
      UnitName.KB,
      TemporalUnitName.KBD,
    ],
    [Platform.COMMODITIES]: [
      UnitName.MMBBL,
      UnitName.KB,
      TemporalUnitName.KBD,
      UnitName.CM,
      UnitName.KTONS,
      UnitName.MTONS,
    ],
    [Platform.DRY]: [UnitName.TONS, UnitName.KTONS, UnitName.MTONS],
    [Platform.MERGE]: [
      UnitName.MMBBL,
      UnitName.KB,
      TemporalUnitName.KBD,
      UnitName.CM,
      UnitName.KTONS,
      UnitName.MTONS,
    ],
  },

  [AnalyticsDataType.FLEET_METRICS]: {
    [Platform.LNG]: [UnitName.CM, UnitName.MTONS, UnitName.KTONS],
    [Platform.LPG]: [UnitName.KTONS, UnitName.CM, UnitName.KB, UnitName.MMBBL],
    [Platform.COMMODITIES]: [
      UnitName.KB,
      UnitName.CM,
      UnitName.KTONS,
      UnitName.MTONS,
      UnitName.MMBBL,
    ],
    [Platform.DRY]: [UnitName.TONS, UnitName.KTONS, UnitName.MTONS],
    [Platform.MERGE]: [UnitName.KB, UnitName.CM, UnitName.KTONS, UnitName.MTONS, UnitName.MMBBL],
  },

  [AnalyticsDataType.INVENTORIES]: {
    [Platform.LNG]: [UnitName.NCM, UnitName.SMCF, UnitName.GWH, UnitName.CM],
    [Platform.LPG]: [UnitName.MMBBL],
    [Platform.COMMODITIES]: [UnitName.MMBBL, UnitName.KB, UnitName.CM],
    [Platform.DRY]: [UnitName.MMBBL],
    [Platform.MERGE]: [UnitName.MMBBL, UnitName.KB, UnitName.CM],
  },

  [AnalyticsDataType.CONGESTION]: {
    [Platform.LNG]: [UnitName.CM],
    [Platform.LPG]: [UnitName.CM],
    [Platform.COMMODITIES]: [UnitName.KTONS, UnitName.MTONS],
    [Platform.DRY]: [UnitName.KTONS, UnitName.MTONS],
    [Platform.MERGE]: [UnitName.KTONS, UnitName.MTONS, UnitName.CM],
  },

  [AnalyticsDataType.BALLAST_CAPACITY]: {
    [Platform.LNG]: [UnitName.CM],
    [Platform.LPG]: [UnitName.CM],
    [Platform.COMMODITIES]: [UnitName.KTONS, UnitName.MTONS],
    [Platform.DRY]: [UnitName.KTONS, UnitName.MTONS],
    [Platform.MERGE]: [UnitName.KTONS, UnitName.MTONS, UnitName.CM],
  },

  [AnalyticsDataType.FLEET_UTILIZATION]: {
    [Platform.LNG]: [UnitName.CM],
    [Platform.LPG]: [UnitName.CM],
    [Platform.COMMODITIES]: [UnitName.KTONS, UnitName.MTONS],
    [Platform.DRY]: [UnitName.KTONS, UnitName.MTONS],
    [Platform.MERGE]: [UnitName.KTONS, UnitName.MTONS, UnitName.CM],
  },

  [AnalyticsDataType.FLEET_DEVELOPMENT]: {
    [Platform.LNG]: [UnitName.CM],
    [Platform.LPG]: [UnitName.CM],
    [Platform.COMMODITIES]: [UnitName.KTONS, UnitName.MTONS],
    [Platform.DRY]: [UnitName.KTONS, UnitName.MTONS],
    [Platform.MERGE]: [UnitName.KTONS, UnitName.MTONS, UnitName.CM],
  },

  [AnalyticsDataType.REFINERY_CAPACITIES]: {
    [Platform.LNG]: [TemporalUnitName.KBD],
    [Platform.LPG]: [TemporalUnitName.KBD],
    [Platform.COMMODITIES]: [TemporalUnitName.KBD],
    [Platform.DRY]: [TemporalUnitName.KBD],
    [Platform.MERGE]: [TemporalUnitName.KBD],
  },
};

export const defaultMapUnit: { [key in Platform]: UnitName } = {
  [Platform.DRY]: UnitName.KTONS,
  [Platform.COMMODITIES]: UnitName.BARREL,
  [Platform.LNG]: UnitName.CM,
  [Platform.LPG]: UnitName.KTONS,
  [Platform.MERGE]: UnitName.BARREL,
};

export const freightMetricsUnitsByMetric = {
  [FreightMetricsMetric.TON_MILES]: [UnitName.MTON_MILE],
  [FreightMetricsMetric.TON_DAYS]: [UnitName.KTON_DAY, UnitName.MTON_DAY],
  [FreightMetricsMetric.AVG_SPEED]: [UnitName.KNOT],
  [FreightMetricsMetric.AVG_DISTANCE]: [UnitName.NAUTICAL_MILE],
};
