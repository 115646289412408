import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import { getAuthService } from 'src/app/providers/singletons/authService';

import { AuthHeadersOptions, getAuthHeaders } from './apiAuthHeaders.service';
import { axiosRetryState, getRequestKey, MAX_RETRIES } from './axios-retry-state';

type AxiosErrorResponseWithMessage = AxiosResponse & {
  message?: string;
};
export type AxiosErrorWithMessage = AxiosError & {
  response: AxiosErrorResponseWithMessage;
};

const VERSION_HEADERS = Object.freeze({ 'x-web-application-version': window.version });

export const ARRAY_REPEAT_SERIALIZER = (params: unknown) =>
  qs.stringify(params, { arrayFormat: 'repeat' });

export const axiosApi = axios.create({
  baseURL: '/api',
  headers: VERSION_HEADERS,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});

export const unauthenticatedAxiosApi = axios.create({
  baseURL: '/api',
  headers: VERSION_HEADERS,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});

export const axiosRestApi = axios.create({
  baseURL: '/graphql/api',
  headers: VERSION_HEADERS,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});

export const axiosStaticV2 = axios.create({
  baseURL: '/staticdata/v2',
  headers: VERSION_HEADERS,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});

export const axiosInsight = axios.create({
  baseURL: process.env.NX_PUBLIC_INSIGHT_API ?? '/insight-server',
  headers: VERSION_HEADERS,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});

export const axiosIdp = axios.create({
  baseURL: process.env.NX_PUBLIC_IDP_MANAGER_URL ?? '/',
  headers: VERSION_HEADERS,
  paramsSerializer: ARRAY_REPEAT_SERIALIZER,
});

export const getData = async <T>(promise: Promise<{ data: T }>): Promise<T> => {
  const response = await promise;
  return response.data;
};

export const createAuthInterceptor =
  (options: AuthHeadersOptions = { includeUseAccessTokenHeader: true }) =>
  async (config: AxiosRequestConfig) => {
    const authHeaders = await getAuthHeaders(options);
    /* eslint-disable no-param-reassign */
    config.headers = {
      ...config.headers,
      ...authHeaders,
    };
    /* eslint-enable no-param-reassign */
    return config;
  };

export const createHandleRetryOnUnauthenticatedError = (instance: AxiosInstance) => {
  return async (error: AxiosErrorWithMessage) => {
    const requestKey = getRequestKey(error.config);
    const currentRetries = axiosRetryState.current.get(requestKey) ?? 0;

    if (error.response?.status !== 401 || window.location.pathname === '/oauth/callback') {
      throw error;
    }

    if (currentRetries >= MAX_RETRIES) {
      return getAuthService().logout(window.location.pathname);
    }

    axiosRetryState.current.set(requestKey, currentRetries + 1);
    await instance.request(error.config);
    throw error;
  };
};

export const createCleanupRetryCountOnResponse = () => {
  return (response: AxiosResponse) => {
    const requestKey = getRequestKey(response.config);
    axiosRetryState.current.set(requestKey, 0);
    return response;
  };
};
