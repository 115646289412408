import moment, { Moment } from 'moment';

import { assertValidMomentDate } from './assertValidMomentDate.helper';
import { InvalidMomentDateError, MomentParseError, NotImplementedError } from './error.helper';
import { generateIntegerSequence } from './number.helper';

import { assertNever } from 'src/helpers/assertNever.helper';

import { ConvertedTimeSeries } from 'types/chart';
import { FormatDateFn, Granularity } from 'types/legacy-globals';
import { TimeSeries } from 'types/series';

export const ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE = 'YYYY-MM-DD[T]HH:mm:ss';

export type MixedDate = Moment | string | undefined | null;

export class RequireMomentDateError extends Error {
  constructor() {
    super('Given date must be a Moment instance');
  }
}

export function assertIsMoment(date?: Moment): asserts date is Moment {
  if (!moment.isMoment(date)) {
    throw new RequireMomentDateError();
  }
}

export const isMomentOrNullish = (d: MixedDate): d is Moment | null | undefined =>
  moment.isMoment(d) || d === null || d === undefined;

export const isStringOrNullish = (d: MixedDate): d is string | null | undefined =>
  typeof d === 'string' || d === null || d === undefined;

export const toMoment = (
  date?: string,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): Moment => (date === undefined ? moment.utc() : moment.utc(date, dateParseFormat));

export const toOptionalMoment = (
  date: string | undefined,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
) => (date === undefined ? undefined : moment.utc(date, dateParseFormat));

export const timeFromNow = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => {
  const momentDate = moment.isMoment(date) ? date : moment.utc(date, dateParseFormat);
  return momentDate.fromNow();
};

export enum DateTimeDisplay {
  HOURLY = 'D MMM YYYY HH:mm',
  HOURLY_NO_YEAR = 'D MMM HH:mm',

  DAILY = 'D MMM YYYY',
  WEEKLY = 'D MMM YYYY ([W]WW)',
  MONTHLY = 'MMM YYYY',
  QUARTERLY = '[Q]Q YYYY',
  ANNUAL = 'YYYY',

  DAILY_WITH_TIME = 'DD/MM/YYYY HH:mm',

  SHORT_HOURLY = 'HH:mm',
  SHORT_DAILY = 'D MMM',
  SHORT_WEEKLY = 'GGGG-[W]WW',
  SHORT_MONTHLY = 'MMM',
  SHORT_QUARTERLY = '[Q]Q',

  SHORTEST_DAILY = 'D',
  SHORTEST_WEEKLY = '[W]WW',
  SHORTEST_MONTHLY = 'MM',
  PREFIXED_SHORTEST_MONTHLY = '[M]MM',

  LONG_DAILY = 'D MMMM YYYY',
  LONG_DAILY_NO_YEAR = 'D MMMM',
  LONG_WEEKLY = '[Week beginning] D MMM YYYY',
  LONG_MONTHLY = 'MMMM YYYY',
  LONG_QUARTERLY = '[Quarter] Q YYYY',
  LONG_ANNUAL = 'YYYY',

  EXPORT_HOURLY = 'YYYY-MM-DD HH:mm',
  EXPORT_HOURLY_FILE_SYSTEM = 'YYYY-MM-DD HH[h]mm', // For exports filenames
  EXPORT_DAILY = 'YYYY-MMM-DD', // Charters only
  EXPORT_WEEKLY = 'YYYY-MM-DD', // Time series only
  EXPORT_MONTHLY = 'YYYY-MM',
  EXPORT_QUARTERLY = 'YYYY-[Q]Q',
  EXPORT_ANNUAL = 'YYYY',

  SHORTEST_EIA = '[W/E] D MMM',
  SHORT_EIA = '[W/E] D MMM YYYY',
  EIA = '[Week ending] D MMM YYYY',

  SEASONAL_EIA = '[EIA W]WW',
  WEEKLY_YEAR = 'GGGG',
  COMPLETE_SEASONAL_EIA = 'GGGG-[EIA W]WW',
  SEASONAL_MONTHLY = 'MMMM',
  CUSHING_DAILY = 'DD-MMM-YYYY',
}

const formatDate = (
  date: Moment | string,
  dateDisplayFormat: DateTimeDisplay,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => {
  let momentDate: Moment;
  if (moment.isMoment(date)) {
    assertValidMomentDate(date);
    momentDate = date;
  } else {
    // @TODO feature: refactor/dates: should handle seasonal another way
    // see https:// kpler.slack.com/archives/C06DDGY5T/p1660751440264319
    if (dateDisplayFormat === DateTimeDisplay.SEASONAL_EIA && date.includes('EIA W')) {
      return date;
    }
    momentDate = moment.utc(date, dateParseFormat);
    try {
      assertValidMomentDate(momentDate);
    } catch (err) {
      if (err instanceof InvalidMomentDateError) {
        throw new MomentParseError(date, dateParseFormat);
      }
    }
  }

  return momentDate.format(dateDisplayFormat);
};
export const formatToHourlyNoYear = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.HOURLY_NO_YEAR, dateParseFormat);

export const formatToHourly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.HOURLY, dateParseFormat);

export const formatToDaily = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.DAILY, dateParseFormat);

export const formatToDailyWithTime = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.DAILY_WITH_TIME, dateParseFormat);

export const formatToShortestDaily = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SHORTEST_DAILY, dateParseFormat);

export const formatToShortHourly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SHORT_HOURLY, dateParseFormat);

export const formatToShortDaily = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SHORT_DAILY, dateParseFormat);

export const formatToLongDaily = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.LONG_DAILY, dateParseFormat);

export const formatToLongDailyNoYear = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.LONG_DAILY_NO_YEAR, dateParseFormat);

export const formatToExportDaily = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.EXPORT_DAILY, dateParseFormat);

export const formatToExportWeekly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.EXPORT_WEEKLY, dateParseFormat);

export const formatToWeekly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.WEEKLY, dateParseFormat);

export const formatToShortWeekly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SHORT_WEEKLY, dateParseFormat);

export const formatToShortestWeekly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SHORTEST_WEEKLY, dateParseFormat);

export const formatToLongWeekly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.LONG_WEEKLY, dateParseFormat);

export const formatToEia = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.EIA, dateParseFormat);

export const formatToShortEia = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SHORT_EIA, dateParseFormat);

export const formatToShortestEia = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SHORTEST_EIA, dateParseFormat);

export const formatToSeasonalEia = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SEASONAL_EIA, dateParseFormat);

export const formatToMonthly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.MONTHLY, dateParseFormat);

export const formatToShortMonthly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SHORT_MONTHLY, dateParseFormat);

export const formatToLongMonthly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.LONG_MONTHLY, dateParseFormat);

export const formatToSeasonalMonthly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SEASONAL_MONTHLY, dateParseFormat);

export const formatToQuarterly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.QUARTERLY, dateParseFormat);

export const formatToShortQuarterly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.SHORT_QUARTERLY, dateParseFormat);

export const formatToLongQuarterly = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.LONG_QUARTERLY, dateParseFormat);

export const formatToAnnual = (
  date: string | Moment,
  dateParseFormat = ISO_DATE_TIME_FORMAT_WITHOUT_TIMEZONE,
): string => formatDate(date, DateTimeDisplay.ANNUAL, dateParseFormat);

export class UnexpectedFutureDateError extends Error {
  name = 'UnexpectedFutureDateError';
  constructor(date: Moment) {
    super(`The date must be in the past. Given: ${date.toLocaleString()}`);
  }
}

export const formatTimeago = (pastDate: Moment, now: Moment = toMoment()): string => {
  if (pastDate.isAfter(now)) {
    throw new UnexpectedFutureDateError(pastDate);
  }
  return pastDate.from(now);
};

export const serializeDate = (date: Moment): string => formatToExportWeekly(date);

export const deserializeDate = (
  date: string,
  format: DateTimeDisplay = DateTimeDisplay.EXPORT_WEEKLY,
): Moment => toMoment(date, format);

export const getDateParseFormatFromGranularity = (granularity: Granularity): DateTimeDisplay => {
  switch (granularity) {
    case Granularity.DAYS:
    case Granularity.EIAS:
    case Granularity.MID_WEEKS:
      return DateTimeDisplay.EXPORT_WEEKLY;
    case Granularity.WEEKS:
      return DateTimeDisplay.SHORT_WEEKLY;
    case Granularity.MONTHS:
      return DateTimeDisplay.EXPORT_MONTHLY;
    case Granularity.QUARTERS:
      return DateTimeDisplay.EXPORT_QUARTERLY;
    case Granularity.YEARS:
      return DateTimeDisplay.ANNUAL;

    default:
      return assertNever(granularity);
  }
};

export const formatApiDateForTable = (date: string, granularity: Granularity): string => {
  const parseFormat = getDateParseFormatFromGranularity(granularity);

  switch (granularity) {
    case Granularity.DAYS:
      return formatToDaily(date, parseFormat);
    case Granularity.WEEKS:
      return formatToWeekly(date, parseFormat);
    case Granularity.EIAS:
    case Granularity.MID_WEEKS:
      return formatToWeekly(date, parseFormat);
    case Granularity.MONTHS:
      return formatToMonthly(date, parseFormat);
    case Granularity.QUARTERS:
      return formatToQuarterly(date, parseFormat);
    case Granularity.YEARS:
      return formatToAnnual(date, parseFormat);

    default:
      return assertNever(granularity);
  }
};

export const getYearFormatFromGranularity = (granularity: Granularity): DateTimeDisplay => {
  switch (granularity) {
    case Granularity.DAYS:
    case Granularity.MONTHS:
    case Granularity.QUARTERS:
    case Granularity.YEARS:
      return DateTimeDisplay.ANNUAL;

    case Granularity.EIAS:
    case Granularity.WEEKS:
    case Granularity.MID_WEEKS:
      return DateTimeDisplay.WEEKLY_YEAR;

    default:
      return assertNever(granularity);
  }
};

const longApiDateFormatMap: { [key in Granularity]: FormatDateFn } = {
  [Granularity.DAYS]: formatToLongDaily,
  [Granularity.WEEKS]: formatToLongWeekly,
  [Granularity.EIAS]: formatToEia,
  [Granularity.MID_WEEKS]: formatToEia,
  [Granularity.MONTHS]: formatToLongMonthly,
  [Granularity.QUARTERS]: formatToLongQuarterly,
  [Granularity.YEARS]: formatToAnnual,
};

export const formatLongApiDate = (date: string, granularity: Granularity): string => {
  const parseFormat = getDateParseFormatFromGranularity(granularity);
  const formatFunction = longApiDateFormatMap[granularity];
  return formatFunction(date, parseFormat);
};

export const getSeasonalDateParseFormatFromGranularity = (
  granularity: Granularity,
): DateTimeDisplay => {
  switch (granularity) {
    case Granularity.EIAS:
      return DateTimeDisplay.SEASONAL_EIA;

    case Granularity.WEEKS:
    case Granularity.MID_WEEKS:
      return DateTimeDisplay.SHORTEST_WEEKLY;

    case Granularity.MONTHS:
      return DateTimeDisplay.PREFIXED_SHORTEST_MONTHLY;

    case Granularity.QUARTERS:
    case Granularity.YEARS:
    case Granularity.DAYS:
      throw new NotImplementedError(granularity);

    default:
      return assertNever(granularity);
  }
};

export const formatSeasonalDate = (date: string, granularity: Granularity): string => {
  const parseFormat = getSeasonalDateParseFormatFromGranularity(granularity);

  switch (granularity) {
    case Granularity.EIAS:
      return formatToSeasonalEia(date, parseFormat);

    case Granularity.WEEKS:
    case Granularity.MID_WEEKS:
      return date;

    case Granularity.MONTHS:
      return formatToSeasonalMonthly(date, parseFormat);

    case Granularity.QUARTERS:
    case Granularity.YEARS:
    case Granularity.DAYS:
      throw new NotImplementedError(granularity);

    default:
      return assertNever(granularity);
  }
};

export const getDetailsDateFromSeasonalDate = (
  granularity: Granularity,
  date: string,
  year: string,
): string => {
  let months: string;
  let mDate: Moment;

  switch (granularity) {
    case Granularity.EIAS:
      mDate = moment.utc(`${year}-${date}`, DateTimeDisplay.COMPLETE_SEASONAL_EIA);
      return serializeDate(mDate.isoWeekday(5));

    case Granularity.WEEKS:
      // format is the same (`[W]WW`)
      return `${year}-${date}`;

    case Granularity.MONTHS:
      months = moment
        .utc(date, DateTimeDisplay.PREFIXED_SHORTEST_MONTHLY)
        .format(DateTimeDisplay.SHORTEST_MONTHLY);
      return `${year}-${months}`;

    case Granularity.MID_WEEKS:
      mDate = moment.utc(`${year}-${date}`, 'GGGG-WW');
      return serializeDate(mDate.isoWeekday(2));

    case Granularity.QUARTERS:
    case Granularity.YEARS:
    case Granularity.DAYS:
      throw new NotImplementedError(granularity);

    default:
      return assertNever(granularity);
  }
};

export const getSeasonalDateFromDetailsDate = (granularity: Granularity, date: string): string => {
  const parseFormat = getDateParseFormatFromGranularity(granularity);

  switch (granularity) {
    case Granularity.EIAS:
      return moment.utc(date, parseFormat).format(DateTimeDisplay.SEASONAL_EIA);

    case Granularity.WEEKS:
    case Granularity.MID_WEEKS:
      return moment.utc(date, parseFormat).format(DateTimeDisplay.SHORTEST_WEEKLY);

    case Granularity.MONTHS:
      return moment.utc(date, parseFormat).format(DateTimeDisplay.PREFIXED_SHORTEST_MONTHLY);

    case Granularity.QUARTERS:
    case Granularity.YEARS:
    case Granularity.DAYS:
      throw new NotImplementedError(granularity);

    default:
      return assertNever(granularity);
  }
};

const previousMap: { [key in Granularity]: string } = {
  [Granularity.DAYS]: 'D-1',
  [Granularity.WEEKS]: 'W-1',
  [Granularity.EIAS]: 'W-1',
  [Granularity.MID_WEEKS]: 'W-1',
  [Granularity.MONTHS]: 'M-1',
  [Granularity.QUARTERS]: 'Q-1',
  [Granularity.YEARS]: 'Y-1',
};
export const getPreviousDateLabel = (granularity: Granularity): string => previousMap[granularity];

const relativeMap: { [key in Granularity]: string } = {
  [Granularity.DAYS]: 'DtD',
  [Granularity.WEEKS]: 'WtW',
  [Granularity.EIAS]: 'WtW',
  [Granularity.MID_WEEKS]: 'WtW',
  [Granularity.MONTHS]: 'MtM',
  [Granularity.QUARTERS]: 'QtQ',
  [Granularity.YEARS]: 'YtY',
};
export const getRelativeDateLabel = (granularity: Granularity): string => relativeMap[granularity];

const singularMap: { [key in Granularity]: 'day' | 'week' | 'month' | 'quarter' | 'year' } = {
  [Granularity.DAYS]: 'day',
  [Granularity.WEEKS]: 'week',
  [Granularity.EIAS]: 'week',
  [Granularity.MID_WEEKS]: 'week',
  [Granularity.MONTHS]: 'month',
  [Granularity.QUARTERS]: 'quarter',
  [Granularity.YEARS]: 'year',
};

export const convertDateStringToDateObject = (
  dateString: string,
  granularity: Granularity,
): Date => {
  const parseFormat = getDateParseFormatFromGranularity(granularity);
  const momentObject = moment.utc(dateString, parseFormat);
  return momentObject.toDate();
};

/**
 * Display purpose only!
 * ----------------------
 * Used to show text like `3-month moving average`
 * Shouldn't be used with moment.js ⛔
 */
export const getSingularGranularity = (
  granularity: Granularity,
): 'day' | 'week' | 'month' | 'quarter' | 'year' => singularMap[granularity];

export const getFirstDayOfPeriodIncludingDate = (
  date: string,
  granularity: Granularity,
): string => {
  switch (granularity) {
    case null:
    case undefined:
    case Granularity.DAYS:
      return serializeDate(moment.utc(date).startOf('day'));

    case Granularity.WEEKS:
      return serializeDate(moment.utc(date).startOf('isoWeek'));

    case Granularity.MONTHS:
      return serializeDate(moment.utc(date).startOf('month'));

    case Granularity.QUARTERS:
      return serializeDate(moment.utc(date).startOf('quarter'));

    case Granularity.YEARS:
      return serializeDate(moment.utc(date).startOf('year'));

    case Granularity.EIAS:
    case Granularity.MID_WEEKS:
      throw new NotImplementedError(granularity);

    default:
      return assertNever(granularity);
  }
};

export const getLastDayOfPeriodIncludingDate = (date: string, granularity: Granularity): string => {
  switch (granularity) {
    case null:
    case undefined:
    case Granularity.DAYS:
      return serializeDate(moment.utc(date).endOf('day'));

    case Granularity.WEEKS:
      return serializeDate(moment.utc(date).endOf('isoWeek'));

    case Granularity.MONTHS:
      return serializeDate(moment.utc(date).endOf('month'));

    case Granularity.QUARTERS:
      return serializeDate(moment.utc(date).endOf('quarter'));

    case Granularity.YEARS:
      return serializeDate(moment.utc(date).endOf('year'));

    case Granularity.EIAS:
    case Granularity.MID_WEEKS:
      throw new NotImplementedError(granularity);

    default:
      return assertNever(granularity);
  }
};

export const getFirstDayPeriod = (period: string, granularity: Granularity): string => {
  const parseFormat = getDateParseFormatFromGranularity(granularity);
  return serializeDate(moment.utc(period, parseFormat));
};

export const getLastDayPeriod = (period: string, granularity: Granularity): string => {
  const parseFormat = getDateParseFormatFromGranularity(granularity);

  switch (granularity) {
    case null:
    case undefined:
    case Granularity.DAYS:
      return serializeDate(toMoment(period, parseFormat).endOf('day'));

    case Granularity.WEEKS:
      return serializeDate(toMoment(period, parseFormat).endOf('isoWeek'));

    case Granularity.MONTHS:
      return serializeDate(toMoment(period, parseFormat).endOf('month'));

    case Granularity.QUARTERS:
      return serializeDate(toMoment(period, parseFormat).endOf('quarter'));

    case Granularity.YEARS:
      return serializeDate(toMoment(period, parseFormat).endOf('month'));

    case Granularity.EIAS:
    case Granularity.MID_WEEKS:
      throw new NotImplementedError(granularity);

    default:
      return assertNever(granularity);
  }
};

export const formatDurationInDays = (input: number): string => {
  const d = moment.duration(input, 'seconds');

  const days = Math.trunc(d.asDays());
  const hours = d.hours();
  const minutes = d.minutes();

  if (days === 0) {
    return `${hours}h ${minutes}m`;
  }
  return `${days}d ${hours}h`;
};

export function getDurationInDays(start: string, end: string) {
  const difference = toMoment(end).diff(toMoment(start), 'second');
  return formatDurationInDays(difference);
}

export const limitExportToTwoMonths = <T, U extends TimeSeries<T> | ConvertedTimeSeries<T>>(
  granularity: Granularity,
): ((data: U, idx: number, serie: readonly U[]) => boolean) => {
  const limit = moment.utc().subtract(62, 'day');

  return (data, idx, serie) => {
    return (
      moment
        .utc(data.date, getDateParseFormatFromGranularity(granularity))
        .isSameOrAfter(limit, 'days') || idx === serie.length - 1
    );
  };
};

export const DATE_REGEX = {
  'DD/MM/YYYY HH:mm':
    /^(0[1-9]|[12]\d|3[01])\/(0\d|1[012])\/([12]\d\d\d)\s([0-1][0-9]|2[0-3]):[0-5][0-9]$/,
  'DD-MM-YYYY HH:mm':
    /^(0[1-9]|[12]\d|3[01])-(0\d|1[012])-([12]\d\d\d)\s([0-1][0-9]|2[0-3]):[0-5][0-9]$/,
  'DD MM YYYY HH:mm':
    /^(0[1-9]|[12]\d|3[01])\s(0\d|1[012])\s([12]\d\d\d)\s([0-1][0-9]|2[0-3]):[0-5][0-9]$/,
  'DD MMM YYYY HH:mm':
    /^(0[1-9]|[12]\d|3[01])\s[a-zA-Z]{3}\s([12]\d\d\d)\s([0-1][0-9]|2[0-3]):[0-5][0-9]$/,
  'DDMMYYYY HH:mm':
    /^(0[1-9]|[12]\d|3[01])(0\d|1[012])([12]\d\d\d)\s([0-1][0-9]|2[0-3]):[0-5][0-9]$/,
  'DD/MM/YYYY': /^(0[1-9]|[12]\d|3[01])\/(0\d|1[012])\/([12]\d\d\d)$/,
  'DD-MM-YYYY': /^(0[1-9]|[12]\d|3[01])-(0\d|1[012])-([12]\d\d\d)$/,
  'DD MM YYYY': /^(0[1-9]|[12]\d|3[01])\s(0\d|1[012])\s([12]\d\d\d)$/,
  'DD MMM YYYY': /^(0[1-9]|[12]\d|3[01])\s[a-zA-Z]{3}\s([12]\d\d\d)$/,
  DDMMYYYY: /^(0[1-9]|[12]\d|3[01])(0\d|1[012])([12]\d\d\d)$/,
};

export const getMomentDateWithRegex = (dateString: string | null): Moment | null => {
  if (dateString) {
    const validRegex = Object.entries(DATE_REGEX).find(([, value]) => dateString?.match(value));
    if (validRegex) {
      const date = moment.utc(dateString, validRegex[0]);
      return date.isValid() ? date : null;
    }
  }
  return null;
};

export const formatWeeklyToStartOfWeek = (weeklyDate: string) => {
  return formatToExportWeekly(
    moment
      .utc(weeklyDate, getDateParseFormatFromGranularity(Granularity.WEEKS))
      .isoWeekday('Monday'),
  );
};

export const formatWeeklyToEndOfWeek = (weeklyDate: string) => {
  return formatToExportWeekly(
    moment
      .utc(weeklyDate, getDateParseFormatFromGranularity(Granularity.WEEKS))
      .isoWeekday('Sunday'),
  );
};

export const timeAgo = (dateIso: string, dateIsoBase?: string) => {
  const today = dateIsoBase ? new Date(dateIsoBase) : new Date();
  const date = moment.utc(dateIso);
  const yesterday = moment.utc(dateIsoBase).subtract(1, 'day');
  const seconds = moment.utc(dateIsoBase).diff(date, 'seconds');
  const minutes = moment.utc(dateIsoBase).diff(date, 'minutes');
  const isToday = moment.utc(today).isSame(date, 'day');
  const isYesterday = moment.utc(yesterday).isSame(date, 'day');

  if (seconds < 60) {
    return 'now';
  }
  if (seconds < 120) {
    return 'a minute ago';
  }
  if (minutes < 60) {
    return `${minutes} min. ago`;
  }
  if (isToday) {
    return 'today';
  }
  if (isYesterday) {
    return 'yesterday';
  }
  return formatToDaily(date);
};

// @TODO feature: refactor/dates
// this function is temoporary and will be removed
export const acceptDateString = (date: MixedDate): Moment | undefined => {
  if (date === undefined || date === null) {
    return undefined;
  }
  return typeof date === 'object' ? date : deserializeDate(date);
};

export const generateDaysSequence = (
  start: Moment,
  end: Moment,
  momentFormat: string = DateTimeDisplay.EXPORT_WEEKLY as string,
): string[] => {
  if (start.isAfter(end)) {
    throw new RangeError('start date should be before end date');
  }
  const diffInDays = end.diff(start, 'days');

  return generateIntegerSequence(0, diffInDays).map(diffFromStart =>
    start.clone().add(diffFromStart, 'days').format(momentFormat),
  );
};
export type DurationInDaysAndHours = {
  partOfDays: number;
  partOfHours: number;
};
export const durationFromHours = (hours: number): DurationInDaysAndHours | undefined => {
  if (hours === 0) {
    return undefined;
  }
  const duration = moment.duration(hours, 'hours');
  const days = duration.asDays();
  const partOfDays = Math.floor(days);
  const partOfHours = duration.subtract(partOfDays * 24, 'hours').hours();
  return {
    partOfDays,
    partOfHours,
  };
};

export const isToday = (date: string): boolean => {
  return moment.utc(new Date()).isSame(date, 'day');
};
