import {
  AxisTitleAlignValue,
  AlignValue,
  SeriesLineOptions,
  VerticalAlignValue,
  OptionsTickmarkPlacementValue,
} from 'highcharts';

import { AnalyticsView, DateRangePreset } from 'types/analytics';
import { DateRange } from 'types/dates';
import { PlayerFragment } from 'types/graphql';
import { InstallationWithResourceType } from 'types/installation';
import { Granularity } from 'types/legacy-globals';
import { VolumeQuantityObject } from 'types/quantity';
import { RefineryCapacitiesMetric } from 'types/refineries';
import { TimeSeriesPayload } from 'types/series';
import { TemporalUnitName, UnitName, UnmanagedUnitName } from 'types/unit';
import { ZoneWithResourceType } from 'types/zone';

type NumericalID<T extends { id: string }> = Omit<T, 'id'> & { id: number };
export type RefineriesPlayer = NumericalID<PlayerFragment>;
export type RefineriesGranularity =
  | Granularity.DAYS
  | Granularity.EIAS
  | Granularity.WEEKS
  | Granularity.MONTHS
  | Granularity.YEARS;
export type RefineriesUnit =
  | TemporalUnitName.KBD
  | UnitName.CM
  | UnitName.KB
  | UnitName.MMBBL
  | UnitName.KTONS
  | UnitName.MTONS
  | UnmanagedUnitName.PERCENTAGE;
export type RefineriesTopFilterState = {
  areas: ReadonlyArray<InstallationWithResourceType | ZoneWithResourceType>;
  dateRange: DateRangePreset | DateRange;
  players: readonly PlayerFragment[];
};
export enum RefineriesFeedstockDataService {
  Imports = 'Imports',
  Consumption = 'Consumption',
}
export type RefineriesRunsFilterState = {
  runsSplit: SplitsPresets;
  runsGranularity: RefineriesGranularity;
  runsUnit: RefineriesUnit;
  runsView: AnalyticsView;
  runsQualties: readonly Qualities[];
  runsGrades: readonly string[];
};

export type RefineriesFeedstockFilterState = {
  feedstockSplit: SplitsPresets;
  feedstockGranularity: RefineriesGranularity;
  feedstockUnit: RefineriesUnit;
  feedstockView: AnalyticsView;
  feedstockProducts: readonly FeedstockProducts[];
};

export type RefineriesRefinedProductsFilterState = {
  products: readonly Products[];
  refinedProductsGranularity: RefineriesGranularity;
  refinedProductsUnit: RefineriesUnit;
  refinedProductsView: AnalyticsView;
  refinedProductsSplit: SplitsPresets;
};
export type RefineriesUnitFeedInputFilterState = {
  unitFeedInputGranularity: RefineriesGranularity;
  unitFeedInputUnit: RefineriesUnit;
  unitFeedInputView: AnalyticsView;
  unitFeedInputSplit: SplitsPresets;
};

export type RefineriesMarginsFilterState = {
  marginsGranularity: RefineriesGranularity;
  marginsSplit: SplitsPresets;
  marginsSearchByTerm: readonly string[];
  marginsSelection: readonly string[];
};

export type RefineryFilterState = RefineriesTopFilterState &
  RefineriesRunsFilterState &
  RefineriesRefinedProductsFilterState &
  RefineriesMarginsFilterState &
  RefineriesFeedstockFilterState &
  RefineriesUnitFeedInputFilterState;

export type RefineriesState = {
  areas: string[];
  dateRange: DateRangePreset | DateRange;
  players: number[];
};

export type RefineriesStateHydrated = {
  areas: Array<InstallationWithResourceType | ZoneWithResourceType>;
  players: readonly PlayerFragment[];
  dateRange: DateRangePreset | DateRange;
  runsGranularity: RefineriesGranularity;
  runsUnit: RefineriesUnit;
  runsSplit: SplitsPresets;
  runsQualties: readonly Qualities[];
  runsGrades: readonly string[];
  runsView: AnalyticsView;
  products: readonly Products[];
  refinedProductsGranularity: RefineriesGranularity;
  refinedProductsUnit: RefineriesUnit;
  refinedProductsView: AnalyticsView;
  refinedProductsSplit: SplitsPresets;
  unitFeedInputGranularity: RefineriesGranularity;
  unitFeedInputUnit: RefineriesUnit;
  unitFeedInputView: AnalyticsView;
  unitFeedInputSplit: SplitsPresets;
  marginsGranularity: RefineriesGranularity;
  marginsSplit: SplitsPresets;
  marginsSearchByTerm: readonly string[];
  marginsSelection: readonly string[];
  feedstockSplit: SplitsPresets;
  feedstockGranularity: RefineriesGranularity;
  feedstockUnit: RefineriesUnit;
  feedstockView: AnalyticsView;
  feedstockProducts: readonly FeedstockProducts[];
};

export type RefineriesTopFiltersMapping = {
  installationIds: readonly number[];
  zoneIds: readonly number[];
  players: readonly number[];
  dateRange: DateRangePreset | DateRange;
};

export type RefineriesRunsFilterMapping = {
  runsSplit: SplitsPresets;
  runsGranularity: RefineriesGranularity;
  runsUnit: RefineriesUnit;
  runsView: AnalyticsView;
  runsQualties: readonly Qualities[];
  runsGrades: readonly string[];
};

export type RefineriesRefinedProductsMapping = {
  products: readonly Products[];
  refinedProductsGranularity: RefineriesGranularity;
  refinedProductsUnit: RefineriesUnit;
  refinedProductsView: AnalyticsView;
  refinedProductsSplit: SplitsPresets;
};

export type RefineriesUnitFeedInputMapping = {
  unitFeedInputGranularity: RefineriesGranularity;
  unitFeedInputUnit: RefineriesUnit;
  unitFeedInputView: AnalyticsView;
  unitFeedInputSplit: SplitsPresets;
};
export type RefineriesMarginsMapping = {
  marginsGranularity: RefineriesGranularity;
  marginsSplit: SplitsPresets;
  marginsSearchByTerm: readonly string[];
  marginsSelection: readonly string[];
};
export type RefineriesFeedstockMapping = {
  feedstockProducts: readonly FeedstockProducts[];
  feedstockGranularity: RefineriesGranularity;
  feedstockUnit: RefineriesUnit;
  feedstockView: AnalyticsView;
  feedstockSplit: SplitsPresets;
};
export type RefineriesMapping = RefineriesTopFiltersMapping &
  RefineriesRunsFilterMapping &
  RefineriesRefinedProductsMapping &
  RefineriesMarginsMapping &
  RefineriesUnitFeedInputMapping &
  RefineriesFeedstockMapping;

export enum Products {
  TOP_OF_THE_BARREL = 'Top of the Barrel',
  MIDDLE_OF_THE_BARREL = 'Middle of the Barrel',
  BOTTOM_OF_THE_BARREL = 'Bottom of the Barrel',
  LPG = 'LPG',
  NAPHTHA = 'Naphtha',
  GASOLINE = 'Gasoline',
  DIESEL_HEATING_FUEL_OIL = 'Diesel',
  RESIDUAL_FUEL = 'Residual Fuel',
  ASPHALT_BITUMEN = 'Bitumen/Asphalt',
  PETCOKE = 'Petcoke',
  JET = 'Jet',
  VGO_RANGE = 'VGO',
  SLURRY = 'Slurry',
  LUBE_OIL = 'Lubricants',
  OLEFINS = 'olefins',
  LOW_SULFUR_DIESEL = 'Low Sulfur Diesel',
  HIGH_SULFUR_DIESEL = 'High Sulfur Diesel',
  LOW_SULFUR_FUEL_OIL = 'Low Sulfur Fuel Oil',
  HIGH_SULFUR_FUEL_OIL = 'High Sulfur Fuel Oil',
}

export enum FeedstockProducts {
  HSFO = 1602,
  SRFO = 2244,
  VGO = 2358,
}

export enum Qualities {
  Condensate = 'Condensate',
  LightSour = 'Light Sour',
  Light = 'Light',
  LightSweet = 'Light Sweet',
  Medium = 'Medium',
  MediumSweet = 'Medium Sweet',
  MediumSour = 'Medium Sour',
  Heavy = 'Heavy',
  HeavySour = 'Heavy Sour',
  HeavySweet = 'Heavy Sweet',
  Sweet = 'Sweet',
  Sour = 'Sour',
}
export enum CondensateQualities {
  Condensate = 'CONDENSATE',
}

export enum DensityQualities {
  Light = 'LIGHT',
  Medium = 'MEDIUM',
  Heavy = 'HEAVY',
  CONDENSATE = 'CONDENSATE',
}

export enum SulfurContentQualities {
  Sweet = 'SWEET',
  Sour = 'SOUR',
}
export enum ProductPresets {
  HSFO = 1602,
  SRFO = 2244,
  VGO = 2358,
}
export enum SplitsPresets {
  TOTAL = 'total',
  CRUDE_QUALITIES = 'crudeQualities',
  PRODUCT = 'products',
  PLAYER = 'player',
  REFINERY_TYPE = 'refineryType',
  REFINERY = 'refinery',
  COUNTRY = 'country',
  SUBCONTINENT = 'subcontinent',
  CONTINENT = 'continent',
  TRADING_REGIONS = 'tradingRegions',
  CRUDE_PRODUCTION_REGIONS = 'crudeProductionRegions',
  GRADES = 'grades',
}

export enum RefineryStatisticsMetric {
  NEW_BUILD_REFINERIES = 'newBuildRefineries',
  OPERATION_REFINERIES = 'operationRefineries',
  SHUTTERED_REFINERIES = 'shutteredRefineries',
  AVERAGE_NCI = 'averageNCI',
  AVERAGE_AGE = 'averageAge',
  ACTICE_UNITS = 'activeUnits',
  OFFLINE_EVENTS = 'offlineEvents',
  EVENTS = 'events',
  REFINERY_METRICS = 'refineryMetrics',
}

export type RefineryTableMetrics = {
  refineryName: string;
  owner: string;
  age: string;
  nci: string;
  type: string;
  status: string;
  country: string;
  state: string;
  startup: string;
  shutdown: string;
  eventsAvgDuration: string;
  planedOverPeriod: string;
  unplannedOverPeriod: string;
};

export type TableMetrics = {
  [key: string]: any;
};

export type RefineyTableMetricsConfig = { [key: string]: string };

export type RefineryMetrics = {
  [metric in RefineryStatisticsMetric]?: {
    value: number | string;
  };
};

export type RefineryStatistics = {
  [metric in RefineryStatisticsMetric]?: {
    value: number | string;
  };
};
export type EventsMetric = {
  name: string;
  value: number;
};

export type RefineryDisplayBoxStatistics = Array<{
  title: string;
  amount: string;
  color: string;
}>;

export type RefineryEventSeries = Array<{
  name: string;
  y: number | undefined;
  color: string;
}>;

export type RefineryDatesFromFilters = {
  startDate: string;
  endDate: string;
};

// TODO: Use derived type once refineries visualization requirements  are clear
export type RefineryVisualisationData = {
  datasets: RefineryVisualatioDataset;
};

export type RefineryVisualatioDataset = {
  ranges: number[][];
  averages: number[][];
};

export type MetricByRefineryResponse = {
  refineryMetrics: RefineryTableMetrics[];
};

export type RefineryStatisticsResponse = {
  newBuildRefineries: number | string;
  operationRefineries: number | string;
  shutteredRefineries: number | string;
  averageNCI: number | string;
  averageAge: number | string;
  activeUnits: number | string;
  offlineEvents: number | string;
  events: EventsMetric[];
  refineryMetrics: RefineryTableMetrics[];
};

export enum RefineriesVisualisationZIndex {
  SINGLE = 1,
  ZERO = 0,
}

export enum RefineriesVisualizationName {
  REFINERIES = 'Refineries',
  RANGES = 'Ranges',
}

export enum RefineriesVisualizationDataType {
  AVERAGES = 'averages',
  RANGES = 'ranges',
}

export type RefineriesVisualizationSeries = {
  refineriesName: RefineriesVisualizationName.REFINERIES;
  rangesName: RefineriesVisualizationName.RANGES;
  singleZIndex: RefineriesVisualisationZIndex.SINGLE;
  zeroZIndex: RefineriesVisualisationZIndex.ZERO;
  averagesDataType: RefineriesVisualizationDataType.AVERAGES;
  rangesDataType: RefineriesVisualizationDataType.RANGES;
  type: string;
  marker: {
    fillColor: string;
    lineWidth: number;
    enabled: boolean;
    lineColor: string;
  };
  lineWidth: number;
  linkedTo: string;
  color: string;
  fillOpacity: number;
};

export enum TheRefineriesTimeseriesVisualizationHeight {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  XLARGE = 'xlarge',
}
export type WeightedAverageValueOject = {
  date: string;
  weightedAverage: string;
};

export type WeightedAverageDataset = {
  dataset: {
    api: WeightedAverageValueOject[];
    sulfur: WeightedAverageValueOject[];
  };
};
export type CrudeGrades = {
  id: number;
  name: string;
  parent: number | undefined;
};
export enum WeightedAverageSeriesName {
  API = 'API',
  SULFUR = 'Sulfur',
}

export type WeightedAverageSeriesConfig = {
  apiName: WeightedAverageSeriesName.API;
  sulfurName: WeightedAverageSeriesName.SULFUR;
  zoneAxis: string;
  yAxis: number;
};

export type ChartOptions = {
  xAxis?: {
    tickmarkPlacement: OptionsTickmarkPlacementValue;
    labels?: {
      rotation: number;
      overflow: 'allow' | 'justify';
    };
  };
  chart?: {
    width?: number;
  };
  legend: {
    enabled: boolean;
    verticalAlign: VerticalAlignValue;
    align?: AlignValue;
  };
  tooltip?: {
    shared: boolean;
    valueDecimals: number;
  };
  yAxis: {
    // Primary yAxis
    title: {
      text?: string;
      align: AxisTitleAlignValue;
      rotation: number;
      skew3d: boolean;
      offset: number;
    };
    opposite?: boolean;
    gridLineWidth: number;
    lineWidth: number;
    apiLineColor?: string;
    sulfurLinecolor?: string;
    axisColor?: string;
  };
};

export interface SeriesLineWeightedAverageOption extends SeriesLineOptions {
  data: number[][];
}

export type UtilizationRateObject = {
  date: string;
  rate: string | number;
};

export type UtilizationRateDataset = {
  dataset: UtilizationRateObject[];
};
export type CrudeConnectivityInstallations = {
  installationId: number;
  installationName: string;
};
export type CrudeConnevtivityInstallationFields = {
  refinery: string;
  installations: CrudeConnectivityInstallations[];
  fields: string[];
};
export type CrudeConnectivityDataset = {
  dataset: CrudeConnevtivityInstallationFields[];
};
export enum SecondaryUnitFeedInputMetric {
  CATALYTIC_REFORMING = 'catalyticReforming',
  CATALYTIC_CRACKING = 'catalyticCracking',
  CAT_HYDROCRACKING = 'catHydrocracking',
  COKING = 'coking',
}

export enum MarginsSeriesColumns {
  COUNTRY = 'plantCountry',
  STATE = 'state',
  STATUS = 'status',
  NAME = 'refineryName',
  VALUE = 'splitValue',
  NCI = 'nci',
  COUNT = 'count',
  OWNER = 'owner',
  TYPE = 'type',
}
enum VariationGranularity {
  BY_GRANULARITY = 'byGranularity',
  YEAR = 'year',
}

export type SecondaryUnitFeedInputMetricVariation = {
  [metric in SecondaryUnitFeedInputMetric]: {
    value: number;
    max: number;
  };
};

export type SecondaryUnitFeedInputMetricUnitDatasets = {
  [metric in SecondaryUnitFeedInputMetric]: {
    value: number;
  };
};
export type MarginsSeries = {
  day: string;
  min: number | undefined | null;
  max: number | undefined | null;
  average: number | undefined | null;
};

export type GrossMargins = {
  day: string;
  average: number | undefined | null;
};
export type MarginsRefineryDataset = {
  plantCountry: string;
  state: string;
  status: string;
  refineryName: string;
  nci: number;
  type: string;
  owner: string;
  count: number;
  complexRefineries: number;
  mediumRefineries: number;
  simpleRefineries: number;
  otherRefineries: number;
  grossMargins: GrossMargins[];
};
export type MarginsSplitValue = {
  splitValue: string;
};

export type MarginsDataset = MarginsRefineryDataset & MarginsSplitValue;

export type MarginsSeriesDataset = {
  series: MarginsSeries[];
  dataset: MarginsDataset[];
};
export type SecondaryUnitFeedInputMetrics = {
  units: Array<{
    datasets: SecondaryUnitFeedInputMetricUnitDatasets;
    date: string;
  }>;
  variations: {
    [granularity in VariationGranularity]: SecondaryUnitFeedInputMetricVariation;
  };
};

export type SecondaryUnitFeedInputUnitTimeseries = {
  [metric in SecondaryUnitFeedInputMetric]: {
    timeseries: TimeSeriesPayload<VolumeQuantityObject, string>;
  };
};

export type SecondaryUnitFeedInputTimeseriesReponse = {
  units: SecondaryUnitFeedInputUnitTimeseries;
  metrics: SecondaryUnitFeedInputMetrics;
};
export type RegionalQualities = {
  id: number;
  region: string;
  quality: string;
  regionQuality: string;
};
export type FilterValues<T extends string> = {
  [key in T]: {
    isParent: boolean;
    selected: boolean;
    parent: string | undefined;
    groupId?: string | undefined;
    isGroupParent?: boolean;
    isInnerChild?: boolean;
  };
};

export type PopoutKeyLabel = Array<{ key: RUNS_QUALITIES | Products; label: string }>;

export enum RUNS_QUALITIES {
  CONDENSATE = 'Condensate',
  LIGHT = 'Light',
  LIGHT_SWEET = 'Light Sweet',
  LIGHT_SOUR = 'Light Sour',
  MEDIUM = 'Medium',
  MEDIUM_SWEET = 'Medium Sweet',
  MEDIUM_SOUR = 'Medium Sour',
  HEAVY = 'Heavy',
  HEAVY_SWEET = 'Heavy Sweet',
  HEAVY_SOUR = 'Heavy Sour',
  SWEET = 'Sweet',
  SOUR = 'Sour',
  UNSPECIFIED = 'Unspecified',
}

export type RefineriesCapacitiesTooltipProps = {
  unitName: RefineriesUnit;
  granularity: Granularity;
  metric: RefineryCapacitiesMetric;
};
