import {
  COMMERCIAL_PACKAGE_IDS,
  DEVELOPMENT_PACKAGE_IDS,
  UserManagementPermissions,
  PACKAGE_GROUP_ID,
} from 'src/domains/userManagement/constants';
import store from 'src/store';
import { useInjectStore } from 'src/store/useInjectStore';

export const useUserManagementPermissions = (useDirectStore?: boolean) => {
  const currentStore = useDirectStore ? store : useInjectStore();

  const userHasPermission = (permissionName: UserManagementPermissions) => {
    return currentStore.getters.userHasPermission(permissionName);
  };

  const hasEntitlementAccess = userHasPermission(UserManagementPermissions.ENTITLEMENT_MGMT);
  const hasAdvanceAccess = userHasPermission(UserManagementPermissions.ACCESS_ADVANCE);
  const hasAccess = userHasPermission(UserManagementPermissions.ACCESS);
  const canDeletePermission = userHasPermission(UserManagementPermissions.PERMISSION_DELETE);
  const canDeletePackage = userHasPermission(UserManagementPermissions.PACKAGE_DELETE);
  const canDeleteFeature = userHasPermission(UserManagementPermissions.FEATURE_DELETE);
  const canDeleteUserPackages = userHasPermission(UserManagementPermissions.USER_PACKAGES_DELETE);
  const canDeleteUser = userHasPermission(UserManagementPermissions.USER_DELETE);
  const canSyncUser = userHasPermission(UserManagementPermissions.USER_SYNC);
  const canEditCommercialPackage = userHasPermission(
    UserManagementPermissions.COMMERCIAL_PACKAGE_EDIT,
  );
  const canEditDevelopmentPackage = userHasPermission(
    UserManagementPermissions.DEVELOPMENT_PACKAGE_EDIT,
  );
  const canEditEntitlementPackage = userHasPermission(
    UserManagementPermissions.ENTITLEMENT_PACKAGE_EDIT,
  );

  const canEditPackage = (packageGroupId: PACKAGE_GROUP_ID) => {
    const isCommercialPackage = COMMERCIAL_PACKAGE_IDS.includes(packageGroupId);
    if (isCommercialPackage && canEditCommercialPackage) {
      return true;
    }

    const isDevelopmentPackage = DEVELOPMENT_PACKAGE_IDS.includes(packageGroupId);
    if (isDevelopmentPackage && canEditDevelopmentPackage) {
      return true;
    }

    const isEntitlementPackage = packageGroupId === PACKAGE_GROUP_ID.LICENSES;
    return isEntitlementPackage && canEditEntitlementPackage;
  };

  const canCreatePackage =
    canEditCommercialPackage || canEditDevelopmentPackage || canEditEntitlementPackage;

  return {
    hasEntitlementAccess,
    hasAdvanceAccess,
    hasAccess,
    canEditCommercialPackage,
    canEditDevelopmentPackage,
    canEditEntitlementPackage,
    canEditPackage,
    canCreatePackage,
    canDeletePermission,
    canDeletePackage,
    canDeleteFeature,
    canDeleteUserPackages,
    canDeleteUser,
    canSyncUser,
  };
};
