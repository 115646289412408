import { apolloClient } from './apollo.service';

import { axiosApi } from 'src/services/axios.service';

import { myProfile } from 'src/main/graphql/User.gql';

import { MyProfileQuery, MyProfileQueryVariables, UserInfoFragment } from 'types/graphql';
import { User } from 'types/user';

const getUser = (id: string): Promise<User> => {
  return axiosApi.get(`users/${id}`);
};

export const getUserInfo = async (): Promise<UserInfoFragment> => {
  const { data } = await apolloClient.query<MyProfileQuery, MyProfileQueryVariables>({
    query: myProfile,
  });
  return data.userInfo;
};

export default {
  getUser,
};
