import { Market } from '@kpler/web-ui';
import { AxiosRequestHeaders } from 'axios';
import { Moment } from 'moment';
// eslint-disable-next-line no-restricted-imports
import { NavigationGuard } from 'vue-router';

import { ValueWithRowspan } from 'src/components/table/table.types';
import { productIds } from 'src/constants/ids/static';

import { ProductType, SearchOptionsZoneInput } from 'types/graphql';
import {
  Installation,
  InstallationDetailsPayload,
  InstallationNestedInsideZone,
} from 'types/installation';
import { QuantityObject } from 'types/quantity';
import { UnitDimension } from 'types/unit';
import { Zone, ZoneType } from 'types/zone';

// ===============
// GENERAL - UI

export type EmptyObject = {
  [K in any]: never;
};

export type ObjectBase<T = string | number | symbol> = {
  id: T;
  name: string;
};

export type ObjectBaseWithResourceType = ObjectBase & {
  resourceType: ResourceType;
};

export type VesselBaseWithMarkets = {
  id: number;
  name: string;
  commodityTypes: Market[];
};

export type ObjectIcon<T = string> = {
  id: T;
  icon: string;
};

export type ObjectBaseGroupable<T = number, U = T> = ObjectBase<T> & {
  groupId: U;
};

export type DisplayObject<T = number | string> = ObjectBase<T> | ObjectIcon<T>;

export type ObjectBaseGroup<T> = {
  name: string;
  group: T[];
};

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  ALL_LOADED = 'allLoaded',
  ERROR = 'error',
  FORBIDDEN = 'forbidden',
  DISCONNECTED = 'disconnected',
  CREATED = 'created',
}

export enum PaginatedRequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  LOADING_MORE = 'loadingMore',
  IDLE_AND_ALL_LOADED = 'idleAndAllLoaded',
  ERROR = 'error',
}

export type Nullable<T> = { [P in keyof T]: T[P] | null };

// https://github.com/vuejs/vue-router/pull/2497#issuecomment-474010032
export type Next = Parameters<NavigationGuard>[2];

export type PaginationParams = {
  from: number;
  size: number;
};

export type SetContent<T> = T extends Set<infer F> ? F : never;

export type CsvHeaders = AxiosRequestHeaders & {
  Accept: 'text/csv';
};

export type RequestLoading = {
  status: RequestStatus.LOADING;
};

export type RequestError = {
  status: RequestStatus.ERROR;
  error?: Error;
};

export type RequestData<T> = { data: T; status: RequestStatus.IDLE };

export type RequestPayload<T> = RequestData<T> | RequestLoading | RequestError;

export type ResourceFilterParams = {
  locations?: readonly string[] | readonly Location[];
  fromLocations?: readonly string[] | readonly Location[];
  toLocations?: readonly string[] | readonly Location[];
  subLocations?: readonly string[] | readonly Location[];
  vessels?: readonly number[];
  products?: readonly string[];
  players?: readonly number[];
};

export enum MapSearchCategory {
  ALL = 'all',
  LOCATION = 'location',
  PLAYER = 'player',
  PRODUCT = 'product',
  VESSEL = 'vessel',
}

export enum Homepage {
  RESEARCH_NEWS = 'researchNews',
  MAP = 'map',
  DASHBOARD = 'dashboard', // Not currently in use
  FLOWS = 'flows',
  FLEET_METRICS = 'fleetMetrics',
  INVENTORIES = 'inventories',
  BALLAST_CAPACITY = 'ballastCapacity',
  CONGESTION = 'congestion',
  FLEET_DEVELOPMENT = 'fleetDevelopment',
  FLEET_UTILIZATION = 'fleetUtilization',
  FREIGHT_METRICS = 'freightMetrics',
  SUPPLY_DEMAND = 'supply-demand',
}

// ===============
// GENERAL - BUSINESS LOGIC

export enum ResourceType {
  PRODUCT = 'product',
  STATIC_PRODUCT_CREATE = 'static-product-create',
  STATIC_PRODUCT_EDIT = 'static-product-edit',
  ZONE = 'zone',
  STATIC_ZONE_EDIT = 'static-zone-edit',
  STATIC_ZONE_CREATE = 'static-zone-create',
  INSTALLATION = 'installation',
  STATIC_INSTALLATION_EDIT = 'static-installation-edit',
  STATIC_INSTALLATION_CREATE = 'static-installation-create',
  BERTH = 'berth',
  STATIC_BERTH_EDIT = 'static-berth-edit',
  STATIC_BERTH_CREATE = 'static-berth-create',
  PLAYER = 'player',
  STATIC_PLAYER_EDIT = 'static-player-edit',
  STATIC_PLAYER_CREATE = 'static-player-create',
  VESSEL = 'vessel',
  STATIC_VESSEL_EDIT = 'static-vessel-edit',
  CAPACITY_HOLDER = 'capacityholder',
  CHARTER = 'charter',
  CONTRACT = 'contract',
  PROVIDER = 'provider',
  STATIC_PROVIDER_EDIT = 'static-provider-edit',
  STATIC_PROVIDER_CREATE = 'static-provider-create',
  UNLOCODE = 'unlocode',
  STATIC_TANK_EDIT = 'static-tank-edit',
  STATIC_TANK_CREATE = 'static-tank-create',
}

export type Location = {
  id: number;
  resourceType: ResourceType.ZONE | ResourceType.INSTALLATION;
};

export type SearchFilters = {
  locations: readonly Location[];
  fromLocations: readonly Location[];
  toLocations: readonly Location[];
  subLocations: readonly Location[];
  vessels: readonly number[];
  products: readonly string[];
  tradeId: string | undefined;
  voyageId: string | undefined;
  portCallId: string | undefined;
};

export type ExtendedSearchFilters = SearchFilters & {
  players: readonly number[];
};

export type SearchContext = {
  locations: readonly Location[] | { loads: readonly Location[]; discharges: readonly Location[] };
  vessels: ReadonlyArray<{ id: number }>;
  products: ReadonlyArray<{ id: string }>;
  players: ReadonlyArray<{ id: number }>;
};

export type Linkable = ObjectBase & {
  resourceType: ResourceType;
  fullname?: string;
  shortname?: string;
  api?: number;
  sulfur?: number;
};

export type LatLon = {
  lat: number;
  lon: number;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export type LatitudeLongitudeMaybe = {
  latitude?: number;
  longitude?: number;
};

export type LatitudeLongitude = {
  latitude: number;
  longitude: number;
};

export type LatitudeLongitudeNullable = {
  latitude: number | null;
  longitude: number | null;
};

export enum Platform {
  LNG = 'lng',
  LPG = 'lpg',
  COMMODITIES = 'commodities',
  DRY = 'dry',
  MERGE = 'merge',
}

/**
 * @deprecated use `VesselCommon.vesselTypeClass` instead for the Merge platform, which is only a string.
 * It should be removed once the 4 platforms will be decommissioned
 */
export enum VesselTypeClassification {
  LNG = 'lng',
  LPG = 'lpg',
  OIL = 'oil',
  CPP = 'cpp',
  DRY = 'coal',
}

export enum VesselTypeClassificationSplit {
  VESSEL_TYPE_OIL = 'vesseltypeoil',
  VESSEL_TYPE_CPP = 'vesseltypecpp',
}

export enum LngMarket {
  LNG = productIds.LNG,
}

export enum OperationalFilter {
  SHIP_TO_SHIP = 'shipToShip',
  PARTIAL_CARGO = 'partialCargo',
  CANAL_CROSSING = 'canalCrossing',
  REEXPORT = 'reexport',
}

export enum LpgMarket {
  LPG = productIds.LPG,
  AMMONIA = productIds.AMMONIA,
  ETHANE = productIds.ETHANE,
  EX_LPG = productIds.EX_LPG,
  OLEFINS = productIds.OLEFINS,
}

export enum OilMarket {
  CRUDE_CO = productIds.CRUDE_OIL_CONDENSATE,
  DPP = productIds.DPP,
  LIQUIDS = productIds.LIQUIDS,
}

export enum CppMarket {
  CLEAN_PETROLEUM_PRODUCTS = productIds.CLEAN_PRODUCTS,
  LIQUIDS = productIds.LIQUIDS,
  NON_PETROLEUM_CLEAN = productIds.NON_PETROLEUM_CLEAN,
}

export enum CrudeMarket {
  CRUDE_CO = productIds.CRUDE_OIL_CONDENSATE,
  LIQUIDS = productIds.LIQUIDS,
}

export enum CoalMarket {
  IRON_ORE = productIds.IRON_ORE,
  BAUXITE = productIds.BAUXITE,
  COAL = productIds.COAL,
  PETCOKE = productIds.PETCOKE,
  GRAINS_OILSEEDS = productIds.GRAINS_OILSEEDS,
  MINOR_BULKS = productIds.MINOR_BULKS,
}

export enum Granularity {
  DAYS = 'days',
  WEEKS = 'weeks',
  EIAS = 'eia_weeks',
  MONTHS = 'months',
  QUARTERS = 'quarters',
  YEARS = 'years',
  MID_WEEKS = 'midweeks',
}

export enum SplitSide {
  ORIGIN = 'origin',
  DESTINATION = 'destination',
}

export type CapacityObject = {
  gte: number | null;
  lte: number | null;
  dimension: UnitDimension;
};

export type DeadWeightObject = {
  gte: number;
  lte: number;
};

export type SpeedRangeObject = {
  min: number | null;
  max: number | null;
};

// ===============
// SEARCH

export type BaseSearchResult = {
  id: number;
  name: string;
  resourceType: ResourceType;
};

export type ZoneSearchResult = BaseSearchResult & {
  resourceType: ResourceType.ZONE;
  isStorageSelected: boolean;
  type: ZoneType;
  matchedFormerName?: string;
  isInUnitedStates: boolean;
};

export type InstallationSearchResult = BaseSearchResult & {
  resourceType: ResourceType.INSTALLATION;
  country: string;
  fullName: string;
  port: { id: number };
  portCost: number | null;
  status: string; // enum?
  type: string;
  unlocodes: string[];
  isInUnitedStates: boolean;
};

export type Area =
  | ZoneSearchResult
  | InstallationSearchResult
  | Zone
  | InstallationDetailsPayload
  | InstallationNestedInsideZone;

export enum SearchResultsType {
  INSTALLATION = 'installation',
  PLAYER = 'player',
  PRODUCT = 'product',
  VESSEL = 'vessel',
  ZONE = 'zone',
}

// ===============
// PRODUCTS

export type Product = {
  fullname: string;
  id: string;
  name: string;
  isRootProduct?: boolean;
  resourceType: ResourceType.PRODUCT;
  type: ProductType;
};

export type VoyageProduct = {
  id: number;
  name: string;
  ancestors?: Array<{ id: number; name: string }>;
};

export type EstimatedProductConfidence = {
  float: number;
  percentage: number;
};

export type ConfirmedProduct = {
  id: number;
  productId: number;
  name: string;
  shortname: string;
  flowQuantity: QuantityObject;
  ancestors: Product[];
};

export type EstimatedProduct = {
  id: number;
  productId: number;
  name: string;
  shortname: string;
  estimatedConfidence: EstimatedProductConfidence;
  isBestEstimated: boolean;
  flowQuantity: QuantityObject;
  ancestors: Product[];
};

export type FlowQuantity = {
  id: number;
  name: string;
  shortname: string;
  withProductEstimation: boolean[];
  estimatedConfidence?: EstimatedProductConfidence;
  confirmedProduct?: ConfirmedProduct;
  estimatedProducts?: EstimatedProduct[];
  api: number;
  sulfur: number;
  cargoSources: string[];
  flowQuantity: QuantityObject;
  ancestors: Array<{ id: number }>;
};

export type ResourceWithCharters = ResourceType.VESSEL | ResourceType.PLAYER;

export type RawSignal = {
  cleanDestination?: {
    processed: boolean;
    zone: Zone | null;
    installation: Installation | null;
  };
  eta: string | null;
  id: number;
  rawDestination: string;
  setTime: string;
  sourceType: string;
  vesselId: number;
};

export type Tab<T = string> = ObjectBase<T> & {
  disabled?: boolean;
  tooltip?: string;
};

export enum DateIndex {
  LAST = 'last',
}

export type ObjectPayload = { [key: string]: number | string | ObjectPayload };

export type ExportableElement = { [key: string]: string | number | boolean };

export type Triggers = {
  date: string;
  splitId: string | null;
  seriesLength: number;
};

type TableConfigBase<T = string> = {
  key: T;
  headerClass?: string;
  label?: string;
  headerType?: 'slot';
  visible?: boolean;
  sortable?: boolean;
  sticky?: boolean;
  cellClass?: string;
  cellClassAccessor?: (item: unknown) => string;
  allowRowspan?: boolean;
};
export type TableConfigWithSlot<T = string> = TableConfigBase<T> & {
  type: 'slot';
  slot?: string; // slot name; defaults to `key`
};

export type TableConfigWithAccessor<T = string> = TableConfigBase<T> & {
  accessor: (item: unknown) => string | number | ValueWithRowspan;
  tooltipAccessor?: (item: unknown) => string;
};

export type TableConfig<T = string> = TableConfigWithSlot<T> | TableConfigWithAccessor<T>;

export type VisibleColumns<T extends string> = { [key in T]: boolean };

export type ExportConfig<T> = {
  label: string;
  accessor: (item: T) => string | number;
  visible: boolean;
};

export type ResourceFilters = {
  tradeId: string;
  voyageId: string;
  portCallId: string;
  vesselIds: string[];
  zoneIds: string[];
  installationIds: string[];
  productIds: readonly string[];
  playerIds: string[];
};

export type ResourceSearchOptions = {
  zone: SearchOptionsZoneInput;
  excludeInstallations: boolean;
};

export type FormatDateFn = (date: string | Moment, dateParseFormat?: string) => string;

export type ArrayElement<A> = A extends ReadonlyArray<infer T> ? T : never;

export type DropEvent = {
  draggableId: string;
  destinationDropzoneId: string;
  dropIndex: number;
};

export type ToggleForecastTrackingEvent = {
  type: 'flows' | 'ballastCapacity';
  context: 'page' | 'widget';
  value: boolean;
};

export enum BoxDirection {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
}
