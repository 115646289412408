import { Market } from '@kpler/web-ui';

import { defaultMapUnit } from 'src/constants/unitsByCommodity.constants';

import { platform } from 'src/helpers/platform.helper';

import { Homepage } from 'types/legacy-globals';
import { MapBackground } from 'types/map';
import { UserSettings, MapDefaultTab } from 'types/user';

const defaultSettings: UserSettings = {
  homepage: { page: Homepage.MAP },
  data: {
    forecast: true,
    internalOnly: {
      ids: false,
      hasVesselFeeder: false,
      hasVesselFeederStorage: false,
      isDroneDataAtmEnhancementActivated: false,
    },
  },
  trackedCargo: {
    activeUnit: {
      cargo: defaultMapUnit[platform],
    },
  },
  productSwitch: {
    isRootActivated: true,
    products: [],
  },
  map: {
    automaticMapType: true,
    autoZoom: true,
    highlightVesselSelection: true,
    labelVisibility: true,
    priceVisibility: true,
    weatherVisibility: true,
    internalOnly: {
      zoneRangesDisplayed: false,
      displayBadCoverage: false,
    },
    defaultTab: MapDefaultTab.VOYAGES,
    background: MapBackground.DEFAULT,
    markets: Object.values(Market),
  },
};

export default defaultSettings;
