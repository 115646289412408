import { Market } from '@kpler/web-ui';
import cloneDeep from 'lodash.clonedeep';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex-typescript-interface';

import { platformService } from 'src/app/providers/singletons/platformService';
import defaultSettings from 'src/constants/defaultSettings.constants';
import { defaultMapUnit } from 'src/constants/unitsByCommodity.constants';
import { CRUDE_OIL_INVENTORIES_PERMISSIONS } from 'src/domains/onshore-asset-monitoring/constants/permissions';
import { MapView } from 'src/platform-merge/map/enums';
import { RootState } from 'src/store/types';

import { axiosApi } from 'src/services/axios.service';

import { assertNever } from 'src/helpers/assertNever.helper';
import { isLegacyHomepageConfig } from 'src/helpers/homepage.helper';
import { platform } from 'src/helpers/platform.helper';

import { EmptyObject, Platform, VesselTypeClassification, Homepage } from 'types/legacy-globals';
import { MapBackground, MapTheme } from 'types/map';
import { UnitName } from 'types/unit';
import { HomepageConfig, MapDefaultTab, UserSettings } from 'types/user';

type SettingsState = {
  homepage: HomepageConfig;
  debug: boolean;
  hasVesselFeeder: boolean;
  hasVesselFeederStorage: boolean;
  isDroneDataAtmEnhancementActivated: boolean;
  vesselTypeClassification: VesselTypeClassification.OIL | VesselTypeClassification.CPP | undefined;
  map: {
    unitName: UnitName;
    autoZoom: boolean;
    defaultTab: MapDefaultTab;
    forecast: boolean;
    highlightVessel: boolean;
    cycloneVisibility: boolean;
    background: MapBackground;
    portsAndBerths: boolean;
    showLabels: boolean;
    darkMap: boolean;
    markets: Market[];
    view?: MapView;
  };
  /** @deprecated removed in the Merge platform */
  productSwitch: {
    isRootActivated: boolean;
    products: string[];
  };
  freightViewVisibility: boolean;
  pricesWelcomeModalVisibility: boolean;
  isProductEstimationActivated: boolean;
  autoRefresh: boolean;
  vesselSizeCustomRangesByDataType: Record<string, [number | null, number | null]>;
  insight: {
    darkMode: boolean;
  };
};

const TAB_PERMISSION_MAP: { readonly [key in MapDefaultTab]: readonly string[] } = Object.freeze({
  [MapDefaultTab.VOYAGES]: ['voyage:read'],
  [MapDefaultTab.PORT_CALLS]: ['port_call:read'],
  [MapDefaultTab.TRADES]: ['trade:read'],
  [MapDefaultTab.INVENTORIES]: CRUDE_OIL_INVENTORIES_PERMISSIONS,
});

export type SettingsModule = SettingsState & {
  readonly userVesselTypeClassification:
    | VesselTypeClassification.OIL
    | VesselTypeClassification.CPP
    | undefined;
  readonly classificationPlatform: VesselTypeClassification;
  readonly activeProducts: string[];
  readonly mapTheme: MapTheme;

  SET_MAP_UNIT(unitName: UnitName): void;
  SET_MAP_AUTO_ZOOM(autoZoom: boolean): void;
  SET_MAP_BACKGROUND(background: MapBackground): void;
  SET_MAP_DARK_MAP(darkMap: boolean): void;
  SET_MAP_DEFAULT_TAB(defaultTab: MapDefaultTab): void;
  SET_MAP_FORECAST(forecast: boolean): void;
  SET_MAP_HIGHLIGHT_VESSEL(highlightVessel: boolean): void;
  SET_MAP_CYCLONE_VISIBILITY(cycloneVisibility: boolean): void;
  SET_MAP_PORTS_AND_BERTHS_VISIBILITY(visibility: boolean): void;
  SET_MAP_SHOW_LABELS(showLabels: boolean): void;
  SET_MAP_MARKETS(markets: Market[]): void;
  SET_MAP_VIEW(mapView: MapView): void;
  SET_DEBUG(debug: boolean): void;
  SET_HAS_VESSEL_FEEDER(hasVesselFeeder: boolean): void;
  SET_HAS_VESSEL_FEEDER_STORAGE(hasVesselFeederStorage: boolean): void;
  SET_IS_DRONE_DATA_ATM_ENHANCEMENT_ACTIVATED(isDroneDataAtmEnhancementActivated: boolean): void;
  SET_VESSEL_TYPE_CLASSIFICATION(
    classification: VesselTypeClassification.OIL | VesselTypeClassification.CPP,
  ): void;
  SET_HOMEPAGE(homepage: HomepageConfig): void;
  SET_ROOT_PRODUCT_ACTIVATION(status: boolean): void;
  SET_PRODUCTS(products: string[]): void;
  ACTIVATE_PRODUCT(product: string): void;
  DEACTIVATE_PRODUCT(product: string): void;
  SET_FREIGHT_VIEW_VISIBILITY(freightViewVisibility: boolean | undefined): void;
  SET_PRICES_WELCOME_MODAL_VISIBILITY(pricesWelcomeModalVisibility: boolean | undefined): void;
  SET_IS_PRODUCT_ESTIMATION_ACTIVATED(isProductEstimationActivated: boolean | undefined): void;
  SET_AUTO_REFRESH(value: boolean): void;
  SET_VESSEL_SIZE_CUSTOM_RANGE(config: {
    dataType: string;
    range: [number | null, number | null];
  }): void;
  SET_INSIGHT_DARK_MODE(darkMode: boolean): void;

  setMapUnit(unitName: UnitName): Promise<void>;
  setMapAutoZoom(autoZoom: boolean): Promise<void>;
  setMapBackground(background: MapBackground): Promise<void>;
  setMapDarkMap(darkMap: boolean): Promise<void>;
  setMapDefaultTab(defaultTab: MapDefaultTab): Promise<void>;
  setMapForecast(forecast: boolean): Promise<void>;
  setMapHighlightVessel(highlightVessel: boolean): Promise<void>;
  setMapCycloneVisibility(cycloneVisibility: boolean): Promise<void>;
  setMapPortsAndBerthsVisibility(visibility: boolean): Promise<void>;
  setMapShowLabels(showLabels: boolean): Promise<void>;
  setMapMarkets(markets: Market[]): Promise<void>;
  setMapView(mapView: MapView): Promise<void>;
  setDebug(debug: boolean): Promise<void>;
  setHasVesselFeeder(hasVesselFeeder: boolean): Promise<void>;
  setHasVesselFeederStorage(hasVesselFeederStorage: boolean): Promise<void>;
  setIsDroneDataAtmEnhancementActivated(isDroneDataAtmEnhancementActivated: boolean): Promise<void>;
  setVesselTypeClassification(
    classification: VesselTypeClassification.OIL | VesselTypeClassification.CPP,
  ): Promise<void>;
  setProductActivation(payload: { active: boolean; product: string }): Promise<void>;
  setRootProductActivation(status: boolean): Promise<void>;
  setHomepage(homepage: HomepageConfig): Promise<void>;
  setFreightViewVisibility(freightViewVisibility: boolean): Promise<void>;
  setPricesWelcomeModalVisibility(pricesWelcomeModalVisibility: boolean): Promise<void>;
  setIsProductEstimationActivated(isProductEstimationActivated: boolean): Promise<void>;
  setAutoRefresh(value: boolean): Promise<void>;
  setVesselSizeCustomRange(config: {
    dataType: string;
    range: [number | null, number | null];
  }): Promise<void>;
  loadSettings(settings: UserSettings | EmptyObject): Promise<void>;
  saveSettings(): Promise<void>;
  setInsightDarkMode(darkMode: boolean): Promise<void>;
};

const moduleState: SettingsState = {
  homepage: { page: Homepage.MAP },
  debug: false,
  hasVesselFeeder: false,
  hasVesselFeederStorage: false,
  isDroneDataAtmEnhancementActivated: false,
  vesselTypeClassification: undefined,
  map: {
    unitName: defaultMapUnit[platform],
    autoZoom: true,
    defaultTab: MapDefaultTab.VOYAGES,
    forecast: true,
    highlightVessel: true,
    cycloneVisibility: true,
    background: MapBackground.DEFAULT,
    portsAndBerths: true,
    showLabels: false,
    darkMap: false,
    markets: Object.values(Market),
    view: undefined,
  },
  productSwitch: {
    isRootActivated: true,
    products: [],
  },
  freightViewVisibility: false,
  pricesWelcomeModalVisibility: true,
  isProductEstimationActivated: false,
  autoRefresh: true,
  vesselSizeCustomRangesByDataType: {},
  insight: {
    darkMode: true,
  },
};

const moduleGetters: GetterTree<SettingsModule, RootState> = {
  userVesselTypeClassification: (state, getters, rootState, rootGetters) => {
    return rootGetters.userHasOilProducts && rootGetters.userHasCppProducts
      ? state.vesselTypeClassification
      : undefined;
  },
  classificationPlatform: (state, getters, rootState, rootGetters) => {
    if (platform === Platform.LNG) {
      return VesselTypeClassification.LNG;
    }
    if (platform === Platform.LPG) {
      return VesselTypeClassification.LPG;
    }
    if (platform === Platform.DRY) {
      return VesselTypeClassification.DRY;
    }
    if (platform === Platform.COMMODITIES || platform === Platform.MERGE) {
      if (rootGetters.userHasOilProducts && rootGetters.userHasCppProducts) {
        return state.vesselTypeClassification ?? VesselTypeClassification.CPP;
      }
      if (rootGetters.userHasOilProducts) {
        return VesselTypeClassification.OIL;
      }
      return VesselTypeClassification.CPP;
    }
    return assertNever(platform);
  },
  activeProducts: () => {
    return [];
  },
  mapTheme: state => {
    return state.map.darkMap ? MapTheme.DARK : MapTheme.LIGHT;
  },
};

const moduleMutations: MutationTree<SettingsModule> = {
  SET_MAP_UNIT: (state, unitName) => {
    state.map.unitName = unitName;
  },
  SET_MAP_AUTO_ZOOM: (state, autoZoom) => {
    state.map.autoZoom = autoZoom;
  },
  SET_MAP_BACKGROUND: (state, background) => {
    state.map.background = background;
  },
  SET_MAP_DARK_MAP: (state, darkMap) => {
    state.map.darkMap = darkMap;
  },
  SET_MAP_DEFAULT_TAB: (state, defaultTab) => {
    state.map.defaultTab = defaultTab;
  },
  SET_MAP_FORECAST: (state, forecast) => {
    state.map.forecast = forecast;
  },
  SET_MAP_HIGHLIGHT_VESSEL: (state, highlightVessel) => {
    state.map.highlightVessel = highlightVessel;
  },
  SET_MAP_CYCLONE_VISIBILITY: (state, cycloneVisibility) => {
    state.map.cycloneVisibility = cycloneVisibility;
  },
  SET_MAP_PORTS_AND_BERTHS_VISIBILITY: (state, visibility) => {
    state.map.portsAndBerths = visibility;
  },
  SET_MAP_SHOW_LABELS: (state, showLabels) => {
    state.map.showLabels = showLabels;
  },
  SET_MAP_MARKETS: (state, markets) => {
    state.map.markets = markets;
  },
  SET_MAP_VIEW: (state, mapView) => {
    state.map.view = mapView;
  },
  SET_DEBUG: (state, debug) => {
    state.debug = debug;
  },
  SET_HAS_VESSEL_FEEDER: (state, hasVesselFeeder) => {
    state.hasVesselFeeder = hasVesselFeeder;
  },
  SET_HAS_VESSEL_FEEDER_STORAGE: (state, hasVesselFeederStorage) => {
    state.hasVesselFeederStorage = hasVesselFeederStorage;
  },
  SET_IS_DRONE_DATA_ATM_ENHANCEMENT_ACTIVATED: (state, isDroneDataAtmEnhancementActivated) => {
    state.isDroneDataAtmEnhancementActivated = isDroneDataAtmEnhancementActivated;
  },
  SET_VESSEL_TYPE_CLASSIFICATION: (state, classification) => {
    state.vesselTypeClassification = classification;
  },
  SET_HOMEPAGE: (state, homepage) => {
    state.homepage = homepage;
  },
  SET_ROOT_PRODUCT_ACTIVATION: (state, status) => {
    state.productSwitch.isRootActivated = status;
  },
  SET_PRODUCTS: (state, products) => {
    state.productSwitch.products = products.map(id => id.toString());
  },
  ACTIVATE_PRODUCT: (state, product) => {
    state.productSwitch.products = [...state.productSwitch.products, product];
  },
  DEACTIVATE_PRODUCT: (state, product) => {
    state.productSwitch.products = state.productSwitch.products.filter(x => x !== product);
  },
  SET_FREIGHT_VIEW_VISIBILITY: (state, freightViewVisibility) => {
    state.freightViewVisibility = freightViewVisibility ?? false;
  },
  SET_PRICES_WELCOME_MODAL_VISIBILITY: (state, pricesWelcomeModalVisibility) => {
    state.pricesWelcomeModalVisibility = pricesWelcomeModalVisibility ?? true;
  },
  SET_IS_PRODUCT_ESTIMATION_ACTIVATED: (state, isProductEstimationActivated) => {
    state.isProductEstimationActivated = isProductEstimationActivated ?? platformService.isMerge;
  },
  SET_AUTO_REFRESH: (state, value) => {
    state.autoRefresh = value;
  },
  SET_VESSEL_SIZE_CUSTOM_RANGE: (state, config) => {
    state.vesselSizeCustomRangesByDataType[config.dataType] = config.range;
  },
  SET_INSIGHT_DARK_MODE: (state, darkMode) => {
    if (state.insight === undefined) {
      state.insight = { darkMode: false };
    }
    state.insight.darkMode = darkMode;
  },
};

const areUserSettings = (settings: UserSettings | EmptyObject): settings is UserSettings => {
  return Object.keys(settings).length > 0;
};

const moduleActions: ActionTree<SettingsModule, RootState> = {
  setMapUnit({ commit, dispatch }, unitName) {
    commit('SET_MAP_UNIT', unitName);
    return dispatch('saveSettings');
  },
  setMapAutoZoom({ commit, dispatch }, autoZoom) {
    commit('SET_MAP_AUTO_ZOOM', autoZoom);
    return dispatch('saveSettings');
  },
  setMapBackground({ commit, dispatch }, background) {
    commit('SET_MAP_BACKGROUND', background);
    return dispatch('saveSettings');
  },
  setMapDarkMap({ commit, dispatch }, darkMap) {
    commit('SET_MAP_DARK_MAP', darkMap);
    return dispatch('saveSettings');
  },
  setMapDefaultTab({ commit, dispatch }, defaultTab) {
    commit('SET_MAP_DEFAULT_TAB', defaultTab);
    return dispatch('saveSettings');
  },
  setMapForecast({ commit, dispatch }, forecast) {
    commit('SET_MAP_FORECAST', forecast);
    return dispatch('saveSettings');
  },
  setMapHighlightVessel({ commit, dispatch }, highlightVessel) {
    commit('SET_MAP_HIGHLIGHT_VESSEL', highlightVessel);
    return dispatch('saveSettings');
  },
  setMapCycloneVisibility({ commit, dispatch }, cycloneVisibility) {
    commit('SET_MAP_CYCLONE_VISIBILITY', cycloneVisibility);
    return dispatch('saveSettings');
  },
  setMapPortsAndBerthsVisibility({ commit, dispatch }, visibility) {
    commit('SET_MAP_PORTS_AND_BERTHS_VISIBILITY', visibility);
    return dispatch('saveSettings');
  },
  setMapShowLabels({ commit, dispatch }, showLabels) {
    commit('SET_MAP_SHOW_LABELS', showLabels);
    return dispatch('saveSettings');
  },
  setMapMarkets({ commit, dispatch }, markets) {
    commit('SET_MAP_MARKETS', markets);
    return dispatch('saveSettings');
  },
  setMapView({ commit, dispatch }, mapView) {
    commit('SET_MAP_VIEW', mapView);
    return dispatch('saveSettings');
  },
  setDebug({ commit, dispatch }, debug) {
    commit('SET_DEBUG', debug);
    return dispatch('saveSettings');
  },
  setHasVesselFeeder({ commit, dispatch }, hasVesselFeeder) {
    commit('SET_HAS_VESSEL_FEEDER', hasVesselFeeder);
    return dispatch('saveSettings');
  },
  setHasVesselFeederStorage({ commit, dispatch }, hasVesselFeederStorage) {
    commit('SET_HAS_VESSEL_FEEDER_STORAGE', hasVesselFeederStorage);
    return dispatch('saveSettings');
  },
  setIsDroneDataAtmEnhancementActivated({ commit, dispatch }, isDroneDataAtmEnhancementActivated) {
    commit('SET_IS_DRONE_DATA_ATM_ENHANCEMENT_ACTIVATED', isDroneDataAtmEnhancementActivated);
    return dispatch('saveSettings');
  },
  setVesselTypeClassification({ commit, dispatch }, classification) {
    commit('SET_VESSEL_TYPE_CLASSIFICATION', classification);
    return dispatch('saveSettings');
  },
  setProductActivation({ commit, dispatch }, { active, product }) {
    if (active) {
      commit('ACTIVATE_PRODUCT', product);
    } else {
      commit('DEACTIVATE_PRODUCT', product);
    }
    return dispatch('saveSettings');
  },
  setRootProductActivation({ commit, dispatch }, status) {
    commit('SET_ROOT_PRODUCT_ACTIVATION', status);
    return dispatch('saveSettings');
  },
  setHomepage({ commit, dispatch }, homepage) {
    commit('SET_HOMEPAGE', homepage);
    return dispatch('saveSettings');
  },
  setFreightViewVisibility({ commit, dispatch }, freightViewVisibility) {
    commit('SET_FREIGHT_VIEW_VISIBILITY', freightViewVisibility);
    return dispatch('saveSettings');
  },
  setPricesWelcomeModalVisibility({ commit, dispatch }, pricesWelcomeModalVisibility) {
    commit('SET_PRICES_WELCOME_MODAL_VISIBILITY', pricesWelcomeModalVisibility);
    return dispatch('saveSettings');
  },
  setIsProductEstimationActivated({ commit, dispatch }, isProductEstimationActivated) {
    commit('SET_IS_PRODUCT_ESTIMATION_ACTIVATED', isProductEstimationActivated);
    return dispatch('saveSettings');
  },
  setAutoRefresh({ commit, dispatch }, value) {
    commit('SET_AUTO_REFRESH', value);
    return dispatch('saveSettings');
  },
  setVesselSizeCustomRange({ commit, dispatch }, config) {
    commit('SET_VESSEL_SIZE_CUSTOM_RANGE', config);
    return dispatch('saveSettings');
  },
  setInsightDarkMode({ commit, dispatch }, darkMode) {
    commit('SET_INSIGHT_DARK_MODE', darkMode);
    return dispatch('saveSettings');
  },
  async loadSettings({ state, commit, rootGetters }, settings) {
    if (!areUserSettings(settings)) {
      commit(
        'SET_PRODUCTS',
        rootGetters.availableProducts.map(x => x.id),
      );
      return;
    }
    if (isLegacyHomepageConfig(settings.homepage)) {
      commit('SET_HOMEPAGE', { page: settings.homepage });
    } else {
      commit('SET_HOMEPAGE', settings.homepage);
    }
    commit('SET_MAP_UNIT', settings.trackedCargo.activeUnit.cargo);
    commit('SET_MAP_AUTO_ZOOM', settings.map.autoZoom);
    commit('SET_MAP_FORECAST', settings.data.forecast);
    commit('SET_MAP_HIGHLIGHT_VESSEL', settings.map.highlightVesselSelection);
    commit('SET_MAP_CYCLONE_VISIBILITY', settings.map.weatherVisibility);
    commit('SET_DEBUG', settings.data.internalOnly.ids);
    commit('SET_HAS_VESSEL_FEEDER', settings.data.internalOnly.hasVesselFeeder);
    commit('SET_HAS_VESSEL_FEEDER_STORAGE', settings.data.internalOnly.hasVesselFeederStorage);
    commit(
      'SET_IS_DRONE_DATA_ATM_ENHANCEMENT_ACTIVATED',
      settings.data.internalOnly.isDroneDataAtmEnhancementActivated,
    );
    if (rootGetters.userHasOnlyAccessToFreightFeature) {
      commit('SET_FREIGHT_VIEW_VISIBILITY', true);
    } else {
      commit('SET_FREIGHT_VIEW_VISIBILITY', settings.freightViewVisibility);
    }
    commit('SET_PRICES_WELCOME_MODAL_VISIBILITY', settings.pricesWelcomeModalVisibility);
    commit('SET_IS_PRODUCT_ESTIMATION_ACTIVATED', settings.isProductEstimationActivated);
    Object.entries(settings.vesselSizeCustomRangesByDataType ?? {}).forEach(([dataType, range]) => {
      commit('SET_VESSEL_SIZE_CUSTOM_RANGE', { dataType, range });
    });
    if (settings.map.portsAndBerths !== undefined) {
      commit('SET_MAP_PORTS_AND_BERTHS_VISIBILITY', settings.map.portsAndBerths);
    }
    if (settings.map.showLabels !== undefined) {
      commit('SET_MAP_SHOW_LABELS', settings.map.showLabels);
    }
    if (settings.map.darkMap !== undefined) {
      commit('SET_MAP_DARK_MAP', settings.map.darkMap);
    }
    if (settings.map.markets !== undefined) {
      commit('SET_MAP_MARKETS', settings.map.markets);
    }
    if (settings.map.view !== undefined) {
      commit('SET_MAP_VIEW', settings.map.view);
    }
    if (settings.autoRefresh !== undefined) {
      commit('SET_AUTO_REFRESH', settings.autoRefresh);
    }
    if (
      settings.map.defaultTab &&
      rootGetters.userHasOnePermission(TAB_PERMISSION_MAP[settings.map.defaultTab])
    ) {
      commit('SET_MAP_DEFAULT_TAB', settings.map.defaultTab);
    } else {
      const defaultTabs = [
        MapDefaultTab.VOYAGES,
        MapDefaultTab.PORT_CALLS,
        MapDefaultTab.TRADES,
        MapDefaultTab.INVENTORIES,
      ];
      const firstTab = defaultTabs.find(tab =>
        rootGetters.userHasOnePermission(TAB_PERMISSION_MAP[tab]),
      );
      if (firstTab == null) {
        commit('SET_MAP_DEFAULT_TAB', MapDefaultTab.VOYAGES);
      } else {
        commit('SET_MAP_DEFAULT_TAB', firstTab);
      }
    }
    if (settings.map.background) {
      commit('SET_MAP_BACKGROUND', settings.map.background);
    }
    if (settings.vesselTypeClassification) {
      commit('SET_VESSEL_TYPE_CLASSIFICATION', settings.vesselTypeClassification);
    }
    if (settings.productSwitch) {
      commit('SET_ROOT_PRODUCT_ACTIVATION', settings.productSwitch.isRootActivated);
      commit('SET_PRODUCTS', settings.productSwitch.products);
    }
    if (state.productSwitch.products.length === 0) {
      commit(
        'SET_PRODUCTS',
        rootGetters.availableProducts.map(x => x.id),
      );
    }
    if (settings.insight?.darkMode !== undefined) {
      commit('SET_INSIGHT_DARK_MODE', settings.insight.darkMode);
    }
  },
  saveSettings({ state }) {
    const newSettings = cloneDeep(defaultSettings);

    newSettings.trackedCargo.activeUnit.cargo = state.map.unitName;
    newSettings.homepage = state.homepage;
    newSettings.map.autoZoom = state.map.autoZoom;
    newSettings.map.defaultTab = state.map.defaultTab;
    newSettings.map.background = state.map.background;
    newSettings.map.highlightVesselSelection = state.map.highlightVessel;
    newSettings.map.weatherVisibility = state.map.cycloneVisibility;
    newSettings.map.portsAndBerths = state.map.portsAndBerths;
    newSettings.map.showLabels = state.map.showLabels;
    newSettings.map.darkMap = state.map.darkMap;
    newSettings.map.markets = state.map.markets;
    newSettings.map.view = state.map.view;
    newSettings.data.forecast = state.map.forecast;
    newSettings.data.internalOnly.ids = state.debug;
    newSettings.data.internalOnly.hasVesselFeeder = state.hasVesselFeeder;
    newSettings.data.internalOnly.hasVesselFeederStorage = state.hasVesselFeederStorage;
    newSettings.data.internalOnly.isDroneDataAtmEnhancementActivated =
      state.isDroneDataAtmEnhancementActivated;
    newSettings.vesselTypeClassification = state.vesselTypeClassification;
    newSettings.productSwitch = state.productSwitch;
    newSettings.freightViewVisibility = state.freightViewVisibility;
    newSettings.pricesWelcomeModalVisibility = state.pricesWelcomeModalVisibility;
    newSettings.isProductEstimationActivated = state.isProductEstimationActivated;
    newSettings.autoRefresh = state.autoRefresh;
    newSettings.vesselSizeCustomRangesByDataType = state.vesselSizeCustomRangesByDataType;
    if (newSettings.insight === undefined) {
      newSettings.insight = { darkMode: false };
    }
    newSettings.insight.darkMode = state.insight.darkMode;

    return axiosApi.put(`/users/current/settings`, newSettings);
  },
};

const settingsModule: Module<SettingsModule, RootState> = {
  state: moduleState,
  getters: moduleGetters,
  mutations: moduleMutations,
  actions: moduleActions,
};

export default settingsModule;
