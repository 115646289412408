import { QueryOptions } from '@apollo/client';

import { apolloClient } from 'src/services/apollo.service';

import {
  MIN_DATE,
  MAX_DATE,
  eventTypeOptions,
  eventCauseOptions,
  eventStatusOptions,
  nullIfFullOrEmpty,
  nullIfEmpty,
  offlineEventsTimeSeriesPayloadGraphqlToFrontend,
  availableCapacityTimeSeriesPayloadGraphqlToFrontend,
  getRefineryDatesFromFilters,
} from 'src/common/refineryCapacities/refineries.helper';
import { assertDefined } from 'src/helpers/assertDefined.helper';
import { convertPastFuturePresetToDateRange } from 'src/helpers/dateRange.helper';

import {
  offlineEvents,
  rspOfflineEvents,
  unitTypeSubFamilies,
  unitCapacities,
  offlineEventTimeSeries,
  availableCapacityTimeSeries,
  refineriesInstallations,
} from 'src/graphql/Refineries.gql';

import { PastFutureDateRangePreset } from 'types/analytics';
import { DateRange } from 'types/dates';
import {
  Exact,
  OfflineEventInput,
  OfflineEventsQuery,
  OfflineEventsQueryVariables,
  UnitTypeSubFamiliesQuery,
  OfflineEventTimeSeriesQuery,
  OfflineEventTimeSeriesQueryVariables,
  OfflineEventFiltersInput,
  AvailableCapacityTimeSeriesQuery,
  AvailableCapacityTimeSeriesQueryVariables,
  UnitCapacityInput,
  UnitCapacitiesQuery,
  UnitCapacitiesQueryVariables,
  UnitCapacityFiltersInput,
  RefineriesInstallationsQuery,
  RefineriesInstallationsQueryVariables,
} from 'types/graphql';
import {
  RefineryCapacitiesFiltersMapping,
  RefineriesEventsDataFiltersMapping,
  RefineryCapacitiesMetric,
} from 'types/refineries';

const getOfflineEvents = async (
  variables: OfflineEventInput,
  query: QueryOptions<Exact<{ input: OfflineEventInput }>, OfflineEventsQuery>['query'],
) =>
  apolloClient
    .query<OfflineEventsQuery, OfflineEventsQueryVariables>({
      query,
      variables: {
        input: variables,
      },
    })
    .then(({ data }) => {
      assertDefined(data);
      return data.offlineEvents;
    });

const getDateRange = (dateRange: DateRange | PastFutureDateRangePreset) => {
  if (typeof dateRange !== 'string') {
    return dateRange;
  }
  return convertPastFuturePresetToDateRange(dateRange, MIN_DATE, MAX_DATE);
};

const getOfflineEventsFromFiltersMapping = (
  filters: RefineriesEventsDataFiltersMapping,
  page: number,
  size: number,
) => {
  const { startDate, endDate } = getDateRange(filters.eventsDateRange);
  const variables: OfflineEventInput = {
    page,
    size,
    where: {
      periodStart: startDate,
      periodEnd: endDate,
      installationIds: nullIfEmpty(filters.installationIds.map(id => String(id))),
      zoneIds: nullIfEmpty(filters.zoneIds.map(id => String(id))),
      unitTypeSubFamilies: nullIfEmpty(filters.unitTypeSubFamilies),
      eventTypes: nullIfFullOrEmpty(filters.eventTypes, eventTypeOptions.length),
      eventCauses: nullIfFullOrEmpty(filters.eventCauses, eventCauseOptions.length),
      eventStatuses: nullIfFullOrEmpty(filters.eventStatuses, eventStatusOptions.length),
    },
  };
  return getOfflineEvents(variables, offlineEvents);
};

const getRSPOfflineEvents = (where: OfflineEventFiltersInput, page: number, size: number) => {
  const variables: OfflineEventInput = {
    page,
    size,
    where,
  };

  return getOfflineEvents(variables, rspOfflineEvents).then(response => [...response.items]);
};

const getAvailableCapacities = (where: UnitCapacityFiltersInput, page: number, size: number) => {
  const variables: UnitCapacityInput = {
    page,
    size,
    where,
  };

  return apolloClient
    .query<UnitCapacitiesQuery, UnitCapacitiesQueryVariables>({
      query: unitCapacities,
      variables: {
        input: variables,
      },
    })
    .then(({ data }) => {
      assertDefined(data);
      return data.unitCapacities;
    })
    .then(items => [...items]);
};

const getUnitTypeSubFamilies = async () =>
  apolloClient
    .query<UnitTypeSubFamiliesQuery>({
      query: unitTypeSubFamilies,
    })
    .then(({ data }) => {
      assertDefined(data);
      return data.unitTypeSubFamilies;
    });

const getRefineryCapacitiesAnalyticsFromFiltersMapping = (
  filters: RefineryCapacitiesFiltersMapping,
) => {
  const { startDate, endDate } = getRefineryDatesFromFilters(filters);

  if (filters.metric === RefineryCapacitiesMetric.EVENTS) {
    return apolloClient
      .query<OfflineEventTimeSeriesQuery, OfflineEventTimeSeriesQueryVariables>({
        query: offlineEventTimeSeries,
        variables: {
          input: {
            periodStart: startDate,
            periodEnd: endDate,
            unitTypeSubFamilies: filters.unitTypeSubFamilies,
            groupByFamily: filters.groupByFamily,
            granularity: filters.granularity,
            installationIds: nullIfEmpty(filters.installationIds.map(id => String(id))),
            zoneIds: nullIfEmpty(filters.zoneIds.map(id => String(id))),
            splitBy: filters.splitBy,
          },
        },
      })
      .then(({ data }) => {
        assertDefined(data);
        return offlineEventsTimeSeriesPayloadGraphqlToFrontend(data);
      });
  }
  return apolloClient
    .query<AvailableCapacityTimeSeriesQuery, AvailableCapacityTimeSeriesQueryVariables>({
      query: availableCapacityTimeSeries,
      variables: {
        input: {
          periodStart: startDate,
          periodEnd: endDate,
          unitTypeSubFamilies: filters.unitTypeSubFamilies,
          granularity: filters.granularity,
          groupByFamily: filters.groupByFamily,
          includeNewBuilds: filters.includeNewBuilds,
          installationIds: nullIfEmpty(filters.installationIds.map(id => String(id))),
          zoneIds: nullIfEmpty(filters.zoneIds.map(id => String(id))),
        },
      },
    })
    .then(({ data }) => {
      assertDefined(data);
      return availableCapacityTimeSeriesPayloadGraphqlToFrontend(data);
    });
};

const getRefineriesInstallations = () => {
  return apolloClient
    .query<RefineriesInstallationsQuery, RefineriesInstallationsQueryVariables>({
      query: refineriesInstallations,
    })
    .then(({ data }) => data.refineriesInstallations)
    .catch(() => {
      console.error('Failed to get refineries installations.');
      return [];
    });
};

export default {
  getOfflineEvents,
  getOfflineEventsFromFiltersMapping,
  getRSPOfflineEvents,
  getUnitTypeSubFamilies,
  getRefineryCapacitiesAnalyticsFromFiltersMapping,
  getAvailableCapacities,
  getRefineriesInstallations,
};
