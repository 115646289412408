import {
  FUEL,
  ForecastHorizon,
  ForecastModel,
  ForecastRun,
  OutageStatus,
  OutageStatusColor,
  PowerTerminalFuelTypeColor,
  RENEWABLE_FUEL,
  Region,
} from './enums';
import { Granularity } from './filters/types';
import { MapboxExpressionColorsConfig } from './types';

import { toMoment } from 'src/helpers/date.helper';

export const TIMEZONES = {
  CET: 'Europe/Berlin',
} as const;

export const POWER_PERMISSIONS = {
  OUTAGES: 'power:outages',
  FORECASTS_EXPLORER: 'power:forecasts-explorer',
  NOTIFICATIONS: 'power:notifications',
  NOTIFICATION_RULES: 'power:notifications-rules',
};

export const DEFAULT_STATUS_COLOR = OutageStatusColor.NO_OUTAGES;
export const STATUS_COLORS = new Map([
  [OutageStatus.SCHEDULED, OutageStatusColor.SCHEDULED],
  [OutageStatus.ONGOING, OutageStatusColor.ONGOING],
  [OutageStatus.NO_OUTAGES, OutageStatusColor.NO_OUTAGES],
  [OutageStatus.DECOMMISSIONED, OutageStatusColor.DECOMMISSIONED],
  [OutageStatus.PLANNED, OutageStatusColor.PLANNED],
]);

export const DEFAULT_FUEL_TYPE_COLOR = PowerTerminalFuelTypeColor.OTHERS;
export const FUEL_TYPE_COLORS = new Map([
  [FUEL.BIOMASS, PowerTerminalFuelTypeColor.BIOMASS],
  [FUEL.FOSSIL_BROWN_COAL_LIGNITE, PowerTerminalFuelTypeColor.HARD_COAL],
  [FUEL.FOSSIL_COAL_DERIVED_GAS, PowerTerminalFuelTypeColor.FOSSIL_GAS],
  [FUEL.FOSSIL_GAS, PowerTerminalFuelTypeColor.FOSSIL_GAS],
  [FUEL.FOSSIL_HARD_COAL, PowerTerminalFuelTypeColor.HARD_COAL],
  [FUEL.FOSSIL_OIL, PowerTerminalFuelTypeColor.FOSSIL_OIL],
  [FUEL.FOSSIL_OIL_SHALE, PowerTerminalFuelTypeColor.FOSSIL_OIL],
  [FUEL.FOSSIL_PEAT, PowerTerminalFuelTypeColor.BIOMASS],
  [FUEL.GEOTHERMAL, PowerTerminalFuelTypeColor.OTHERS],
  [FUEL.HYDRO_PUMPED_STORAGE, PowerTerminalFuelTypeColor.HYDRO],
  [FUEL.HYDRO_RUN_OF_RIVER_AND_POUNDAGE, PowerTerminalFuelTypeColor.HYDRO],
  [FUEL.HYDRO_WATER_RESERVOIR, PowerTerminalFuelTypeColor.HYDRO],
  [FUEL.HYDRO_PUMP, PowerTerminalFuelTypeColor.HYDRO],
  [FUEL.MARINE, PowerTerminalFuelTypeColor.HYDRO],
  [FUEL.NUCLEAR, PowerTerminalFuelTypeColor.NUCLEAR],
  [FUEL.OTHER, PowerTerminalFuelTypeColor.OTHERS],
  [FUEL.OTHER_RENEWABLE, PowerTerminalFuelTypeColor.OTHERS],
  [FUEL.SOLAR, PowerTerminalFuelTypeColor.SOLAR],
  [FUEL.WASTE, PowerTerminalFuelTypeColor.OTHERS],
  [FUEL.WIND_OFFSHORE, PowerTerminalFuelTypeColor.WIND],
  [FUEL.WIND_ONSHORE, PowerTerminalFuelTypeColor.WIND],
]);

export const RENEWABLE_FUEL_TYPE_COLORS = new Map([
  [RENEWABLE_FUEL.SOLAR, PowerTerminalFuelTypeColor.SOLAR],
  [RENEWABLE_FUEL.WIND, PowerTerminalFuelTypeColor.WIND],
]);

export enum PowerLegend {
  BIDDING_ZONES = 'Bidding zones',
  INSTALLATIONS = 'Installations',
}

export enum PowerInstallationLegend {
  STATUS = 'Status',
  FUEL_TYPE = 'Fuel',
}

export const POWER_LEGEND_OPTIONS = [
  { id: PowerLegend.INSTALLATIONS, name: PowerLegend.INSTALLATIONS },
  { id: PowerLegend.BIDDING_ZONES, name: PowerLegend.BIDDING_ZONES },
];

export const INSTALLATION_LEGEND_OPTIONS = [
  { id: PowerInstallationLegend.STATUS, name: PowerInstallationLegend.STATUS },
  { id: PowerInstallationLegend.FUEL_TYPE, name: PowerInstallationLegend.FUEL_TYPE },
];

export const BIDDING_ZONES_PRICE_DATE_OPTIONS = [
  { id: Granularity.HOURS, name: 'H' },
  { id: Granularity.DAYS, name: 'D' },
];

export const LEGEND: Record<PowerInstallationLegend, Map<string, string>> = {
  [PowerInstallationLegend.STATUS]: new Map([
    [OutageStatusColor.SCHEDULED, OutageStatus.SCHEDULED],
    [OutageStatusColor.ONGOING, OutageStatus.ONGOING],
    [OutageStatusColor.NO_OUTAGES, OutageStatus.NO_OUTAGES],
    [OutageStatusColor.DECOMMISSIONED, OutageStatus.DECOMMISSIONED],
    [OutageStatusColor.PLANNED, OutageStatus.PLANNED],
  ]),
  [PowerInstallationLegend.FUEL_TYPE]: new Map([
    [PowerTerminalFuelTypeColor.NUCLEAR, FUEL.NUCLEAR],
    [PowerTerminalFuelTypeColor.FOSSIL_GAS, FUEL.FOSSIL_GAS],
    [PowerTerminalFuelTypeColor.FOSSIL_OIL, FUEL.FOSSIL_OIL],
    [PowerTerminalFuelTypeColor.HARD_COAL, 'fossil coal'],
    [PowerTerminalFuelTypeColor.HYDRO, 'hydro'],
    [PowerTerminalFuelTypeColor.SOLAR, FUEL.SOLAR],
    [PowerTerminalFuelTypeColor.WIND, 'wind'],
    [PowerTerminalFuelTypeColor.BIOMASS, FUEL.BIOMASS],
    [PowerTerminalFuelTypeColor.OTHERS, 'others'],
  ]),
};

export const POWER_INSTALLATIONS_COLORS_CONFIG: Record<
  PowerInstallationLegend,
  MapboxExpressionColorsConfig
> = {
  [PowerInstallationLegend.STATUS]: {
    defaultColor: DEFAULT_STATUS_COLOR,
    colors: STATUS_COLORS,
    property: 'outageStatus',
  },
  [PowerInstallationLegend.FUEL_TYPE]: {
    defaultColor: DEFAULT_FUEL_TYPE_COLOR,
    colors: FUEL_TYPE_COLORS,
    property: 'fuel',
  },
};
export const REGIONS_COUNTRIES = new Map<Region, string[]>([
  [Region.EUROPE, ['All countries']],
  [
    Region.EU_27,
    [
      'Austria',
      'Belgium',
      'Bulgaria',
      'Czech Republic',
      'Denmark',
      'Estonia',
      'Finland',
      'France',
      'Germany',
      'Greece',
      'Hungary',
      'Ireland',
      'Italy',
      'Latvia',
      'Lithuania',
      'Netherlands',
      'Poland',
      'Portugal',
      'Romania',
      'Slovakia',
      'Slovenia',
      'Spain',
      'Sweden',
    ],
  ],
  [
    Region.CENTRAL_WESTERN_EUROPE,
    ['France', 'Germany', 'Belgium', 'Netherlands', 'Switzerland', 'Austria'],
  ],
  [
    Region.CENTRAL_EASTERN_EUROPE,
    ['Poland', 'Czech Republic', 'Slovakia', 'Hungary', 'Romania', 'Slovenia'],
  ],
  [
    Region.NORTHERN_EUROPE,
    ['Denmark', 'Estonia', 'Finland', 'Latvia', 'Lithuania', 'Sweden', 'Norway'],
  ],
  [Region.SOUTHERN_EASTERN_EUROPE, ['Bulgaria', 'Greece', 'Serbia']],
]);

export const OUTAGES_MIN_DATE = toMoment('2015-01-01');
export const OUTAGES_MAX_DATE = toMoment().add(3, 'year');

export const FORECAST_MODEL_RUN_HORIZONS: Record<
  ForecastModel,
  Map<ForecastRun, ForecastHorizon[]>
> = {
  [ForecastModel.GFS_OP]: new Map([
    [
      ForecastRun.MIDNIGHT,
      [...Object.values(ForecastHorizon).filter(h => h !== ForecastHorizon.D_0)],
    ],
    [
      ForecastRun.SIX_AM,
      [...Object.values(ForecastHorizon).filter(h => h !== ForecastHorizon.D_0)],
    ],
    [ForecastRun.NOON, [...Object.values(ForecastHorizon).filter(h => h !== ForecastHorizon.D_0)]],
    [
      ForecastRun.SIX_PM,
      [...Object.values(ForecastHorizon).filter(h => h !== ForecastHorizon.D_0)],
    ],
  ]),
  [ForecastModel.GFS_ENS]: new Map([
    [
      ForecastRun.MIDNIGHT,
      [...Object.values(ForecastHorizon).filter(h => h !== ForecastHorizon.D_0)],
    ],
    [
      ForecastRun.SIX_AM,
      [...Object.values(ForecastHorizon).filter(h => h !== ForecastHorizon.D_0)],
    ],
    [ForecastRun.NOON, [...Object.values(ForecastHorizon).filter(h => h !== ForecastHorizon.D_0)]],
    [
      ForecastRun.SIX_PM,
      [...Object.values(ForecastHorizon).filter(h => h !== ForecastHorizon.D_0)],
    ],
  ]),
  [ForecastModel.EC_OP]: new Map([
    [
      ForecastRun.MIDNIGHT,
      [
        ForecastHorizon.D_1,
        ForecastHorizon.D_2,
        ForecastHorizon.D_3,
        ForecastHorizon.D_4,
        ForecastHorizon.D_5,
        ForecastHorizon.D_6,
        ForecastHorizon.D_7,
        ForecastHorizon.D_8,
        ForecastHorizon.D_9,
      ],
    ],
    [ForecastRun.SIX_AM, [ForecastHorizon.D_1, ForecastHorizon.D_2, ForecastHorizon.D_3]],
    [
      ForecastRun.NOON,
      [
        ForecastHorizon.D_1,
        ForecastHorizon.D_2,
        ForecastHorizon.D_3,
        ForecastHorizon.D_4,
        ForecastHorizon.D_5,
        ForecastHorizon.D_6,
        ForecastHorizon.D_7,
        ForecastHorizon.D_8,
        ForecastHorizon.D_9,
      ],
    ],
    [ForecastRun.SIX_PM, [ForecastHorizon.D_1, ForecastHorizon.D_2, ForecastHorizon.D_3]],
  ]),
  [ForecastModel.EC_ENS]: new Map([
    [
      ForecastRun.MIDNIGHT,
      [
        ForecastHorizon.D_1,
        ForecastHorizon.D_2,
        ForecastHorizon.D_3,
        ForecastHorizon.D_4,
        ForecastHorizon.D_5,
        ForecastHorizon.D_6,
        ForecastHorizon.D_7,
        ForecastHorizon.D_8,
        ForecastHorizon.D_9,
        ForecastHorizon.D_10,
        ForecastHorizon.D_11,
        ForecastHorizon.D_12,
        ForecastHorizon.D_13,
        ForecastHorizon.D_14,
      ],
    ],
    [ForecastRun.SIX_AM, [ForecastHorizon.D_1, ForecastHorizon.D_2, ForecastHorizon.D_3]],

    [
      ForecastRun.NOON,
      [
        ForecastHorizon.D_1,
        ForecastHorizon.D_2,
        ForecastHorizon.D_3,
        ForecastHorizon.D_4,
        ForecastHorizon.D_5,
        ForecastHorizon.D_6,
        ForecastHorizon.D_7,
        ForecastHorizon.D_8,
        ForecastHorizon.D_9,
        ForecastHorizon.D_10,
        ForecastHorizon.D_11,
        ForecastHorizon.D_12,
        ForecastHorizon.D_13,
        ForecastHorizon.D_14,
      ],
    ],
    [ForecastRun.SIX_PM, [ForecastHorizon.D_1, ForecastHorizon.D_2, ForecastHorizon.D_3]],
  ]),
  [ForecastModel.EC_46]: new Map([
    [
      ForecastRun.MIDNIGHT,
      [...Object.values(ForecastHorizon).filter(h => h !== ForecastHorizon.D_0)],
    ],
  ]),
};
